import ContentHeader from "../components/content-header/ContentHeader";
import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { addOEM } from "../services/data-service/oem-service";
import {
  getAllStates,
  getDistrictByState,
} from "../services/data-service/master-service";
import { getRandom3 } from "../services/common/utils";
import { getHsrpVehicleDetails } from "../services/data-service/vahan-service"

const QuickVahanCheck = () => {
    const navigate = useNavigate();

    const [vahanResponse, setVahanResponse] = useState()
  
    function handleSubmit(event) {
      event.preventDefault();
      const data = event.target.elements;
      const payload = {
        regnNO: data.regnNO.value,
        chasisNo: data.chasisNo.value,
        engineNo: data.engineNo.value,
      };
      console.log(payload);
  
      getHsrpVehicleDetails(payload).then((res) => {
        // toastMessage("success", "OEM Added Sucessfully!");
        // window.location.href = "/#/oem";
        // navigate("/oem");
        console.log(JSON.parse(res.data))
        setVahanResponse(JSON.parse(res.data))
      });
    }
  return (
    <div>
      <ContentHeader title="Quick Vahan Check" />
      <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-body">
                    <div className="row">
                      {/* Left */}
                      <div className="col-md-4">
                        <form
                          id="quickForm"
                          noValidate="novalidate"
                          onSubmit={handleSubmit}
                        >
                          <div className="form-group">
                            <label htmlFor="regNo">Reg No</label>
                            <input
                              type="text"
                              name="text"
                              className="form-control"
                              id="regnNO"
                            />
                            <label htmlFor="regNo">Chassis</label>
                            <input
                              type="text"
                              name="text"
                              className="form-control"
                              id="chasisNo"
                            />
                            <label htmlFor="regNo">EngineNo</label>
                            <input
                              type="text"
                              name="text"
                              className="form-control"
                              id="engineNo"
                            />
                            <label htmlFor="check">&nbsp;</label>
                            <button
                              type="submit"
                              className="form-control btn btn-primary float-right"
                            >
                              Check
                            </button>
                          </div>
                        </form>
                      </div>
                      {/* Right */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Vahan API Response</label>
                          <textarea
                            className="form-control"
                            rows={15}
                            // placeholder={vahanResponse}
                            value={JSON.stringify(vahanResponse, null, 4)}
                            disabled
                            defaultValue={""}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="card-footer"></div> */}
                </div>
              </div>
            </div>
          </div>
      </section>
    </div>
  );
};

export default QuickVahanCheck;
