import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#000'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#000',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    description: {
        width: '60%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
    orderid:{
        width: '20%',
        borderRightColor: '#000',
        borderRightWidth: 1,
      	paddingLeft: 8,
    },
    regno:{
        width: '20%',
        borderRightColor: '#000',
        borderRightWidth: 1,
      paddingLeft: 8,
    },
    basicamt:{
        width: '15%',
        borderRightColor: '#000',
        borderRightWidth: 1,
      paddingLeft: 8,
      textAlign: 'center',
    },
    sgst:{
        width: '15%',
        borderRightColor: '#000',
        borderRightWidth: 1,
      paddingLeft: 8,
      textAlign: 'center',
    },
    cgst:{
        width: '15%',
        borderRightColor: '#000',
        borderRightWidth: 1,
      paddingLeft: 8,
      textAlign: 'center',
    },
    netamt:{
        width: '15%',
        // borderRightColor: '#000',
        // borderRightWidth: 1,
      paddingRight: 8,
      textAlign: 'right',
    },
  });

const InvoiceTableRow = ({items}) => {
    const rows = items.map( item => 
        <View style={styles.row}>
            <Text style={styles.orderid}>{item.orderid}</Text>
            <Text style={styles.regno}>{item.reg_no}</Text>
            <Text style={styles.basicamt}>{item.basic}</Text>
            <Text style={styles.cgst}>{item.sgst_amount}</Text>
            <Text style={styles.sgst}>{item.cgst_amount}</Text>
            <Text style={styles.netamt}>{item.total}</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};
  
  export default InvoiceTableRow