import { React, Component } from "react";

export class ViewActionButton extends Component {
  constructor(props) {
    super(props);
    this.btnClickedHandler = this.btnClickedHandler.bind(this);
  }
  btnClickedHandler() {
    this.props.clicked(this.props.value);
  }
  render() {
    return (
      <div className="btn-group btn-group-sm">
        <button className="btn btn-info" onClick={this.btnClickedHandler}>
          <i className="fas fa-eye" />
        </button>
        {/* <a href="#" className="btn btn-danger">
          <i className="fas fa-trash" />
        </a> */}
      </div>
      // <button onClick={this.btnClickedHandler}>Click Me!</button>
    );
  }
}
