import axios from "axios";
import authHeader from "../common/auth-header";
import { API_URL } from "../common/globalvars";

export function getHsrpVehicleDetails(data) {
  return axios.post(`${API_URL}/b2b/vahan/getHsrpVehicleDetails`, data, {
    headers: authHeader(),
  });
}

export async function hsrpEntry(data) {
  return await axios.post(`${API_URL}/b2b/vahan/hsrpEntry`, data, {
    headers: authHeader(),
  });
}
