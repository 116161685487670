import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  getDashboardOrders,
  getDashboardOrdersStat,
} from "../services/data-service/dashboard-services";
import { useAuth } from "../services/common/utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "",
    },
  },
};

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default function AdminDashboard(props) {
  const [barData, setBarData] = useState(null);
  const [b2bPieData, setB2BPieData] = useState(null);
  const [b2cPieData, setB2CPieData] = useState(null);

  useEffect(() => {
    getDashboardOrders().then((response) => {
      // console.log(response.data.b2b)
      let datasets = [];
      response.data["b2b"].map((item) => {
        // console.log(item["value"])
        datasets.push({
          label: item["year"],
          data: item["value"],
          backgroundColor: `rgba(${Math.random() * (255 - 0) + 0}, ${
            Math.random() * (255 - 0) + 0
          }, ${Math.random() * (255 - 0) + 0}, 0.5)`,
        });
      });
      console.log(datasets);
      setBarData({
        labels,
        datasets: datasets,
        // datasets: [
        //   {
        //     label: "B2B",
        //     data: Object.values(response.data["b2b"]),
        //     backgroundColor: "rgba(255, 99, 132, 0.5)",
        //   },
        //   {
        //     label: "B2C",
        //     data: Object.values(response.data["b2c"]),
        //     backgroundColor: "rgba(53, 162, 235, 0.5)",
        //   },
        // ],
      });
    });
    getDashboardOrdersStat().then((response) => {
      setB2BPieData({
        labels: Object.keys(response.data["b2b"]),
        datasets: [
          {
            label: "# of Orders",
            data: Object.values(response.data["b2b"]),
            backgroundColor: [
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 99, 132, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(54, 162, 235, 1)",
              "rgba(255, 99, 132, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      });

      // setB2CPieData({
      //   labels: Object.keys(response.data["b2c"]),
      //   datasets: [
      //     {
      //       label: "# of Orders",
      //       data: Object.values(response.data["b2c"]),
      //       backgroundColor: [
      //         "rgba(255, 159, 64, 0.2)",
      //         "rgba(255, 99, 132, 0.2)",
      //         "rgba(54, 162, 235, 0.2)",
      //         "rgba(255, 206, 86, 0.2)",
      //         "rgba(75, 192, 192, 0.2)",
      //         "rgba(153, 102, 255, 0.2)",
      //       ],
      //       borderColor: [
      //         "rgba(255, 159, 64, 1)",
      //         "rgba(255, 99, 132, 1)",
      //         "rgba(54, 162, 235, 1)",
      //         "rgba(255, 206, 86, 1)",
      //         "rgba(75, 192, 192, 1)",
      //         "rgba(153, 102, 255, 1)",
      //       ],
      //       borderWidth: 1,
      //     },
      //   ],
      // });
    });
  }, []);

  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <center><p>Welcome to YaryaSekur - HSRP System</p></center>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Orders - Trend</h3>
              </div>
              <div className="card-body">
                <div
                  className="chart"
                  style={{
                    height: "250px",
                    position: "relative",
                    marginBottom: "1%",
                    padding: "1%",
                  }}
                >
                  {!barData ? (
                    <div className="overlay">
                      <i className="fas fa-2x fa-sync fa-spin" />
                    </div>
                  ) : (
                    <Bar options={options} data={barData} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Orders - Stastics</h3>
              </div>
              <div className="card-body">
                <div
                  className="chart"
                  style={{
                    height: "250px",
                    position: "relative",
                    marginBottom: "1%",
                    padding: "1%",
                  }}
                >
                  {!b2bPieData ? (
                    <div className="overlay">
                      <i className="fas fa-2x fa-sync fa-spin" />
                    </div>
                  ) : (
                    <Doughnut data={b2bPieData} options={options} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row">
          <div className="col-6">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">B2C - Stastics</h3>
              </div>
              <div className="card-body">
                <div
                  className="chart"
                  style={{
                    height: "250px",
                    position: "relative",
                    marginBottom: "1%",
                    padding: "1%",
                  }}
                >
                  {!b2cPieData ? (
                    <div className="overlay">
                      <i className="fas fa-2x fa-sync fa-spin" />
                    </div>
                  ) : (
                    <Doughnut data={b2cPieData} options={options} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
}

// export default class AdminDashboard extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       dash_data: [],
//       b2b_dash_data_stat: {},
//       b2c_dash_data_stat: {},
//     };
//   }
//   async componentDidMount() {
//     await getDashboardOrders().then((response) => {
//       this.setState({ dash_data: response.data });
//     });
//     await getDashboardOrdersStat().then((response) => {
//       this.setState({
//         b2b_dash_data_stat: response.data["b2b"],
//         b2c_dash_data_stat: response.data["b2c"],
//       });
//     });
//   }

//   render() {
//     const countdata = {
//       labels,
//       datasets: [
//         {
//           label: "B2B",
//           data: this.state.dash_data["b2b"],
//           backgroundColor: "rgba(255, 99, 132, 0.5)",
//         },
//         {
//           label: "B2C",
//           data: this.state.dash_data["b2c"],
//           backgroundColor: "rgba(53, 162, 235, 0.5)",
//         },
//       ],
//     };

//     const b2b_piedata = {
//       labels: Object.keys(this.state.b2b_dash_data_stat),
//       datasets: [
//         {
//           label: "# of Orders",
//           data: Object.values(this.state.b2b_dash_data_stat),
//           backgroundColor: [
//             "rgba(54, 162, 235, 0.2)",
//             "rgba(255, 99, 132, 0.2)",
//             "rgba(255, 206, 86, 0.2)",
//             "rgba(75, 192, 192, 0.2)",
//             "rgba(153, 102, 255, 0.2)",
//             "rgba(255, 159, 64, 0.2)",
//           ],
//           borderColor: [
//             "rgba(54, 162, 235, 1)",
//             "rgba(255, 99, 132, 1)",
//             "rgba(255, 206, 86, 1)",
//             "rgba(75, 192, 192, 1)",
//             "rgba(153, 102, 255, 1)",
//             "rgba(255, 159, 64, 1)",
//           ],
//           borderWidth: 1,
//         },
//       ],
//     };

//     const b2c_piedata = {
//       labels: Object.keys(this.state.b2c_dash_data_stat),
//       datasets: [
//         {
//           label: "# of Orders",
//           data: Object.values(this.state.b2c_dash_data_stat),
//           backgroundColor: [
//             "rgba(255, 159, 64, 0.2)",
//             "rgba(255, 99, 132, 0.2)",
//             "rgba(54, 162, 235, 0.2)",
//             "rgba(255, 206, 86, 0.2)",
//             "rgba(75, 192, 192, 0.2)",
//             "rgba(153, 102, 255, 0.2)",
//           ],
//           borderColor: [
//             "rgba(255, 159, 64, 1)",
//             "rgba(255, 99, 132, 1)",
//             "rgba(54, 162, 235, 1)",
//             "rgba(255, 206, 86, 1)",
//             "rgba(75, 192, 192, 1)",
//             "rgba(153, 102, 255, 1)",
//           ],
//           borderWidth: 1,
//         },
//       ],
//     };

//     return (
//       <section className="content">
//         <div className="container-fluid">
//           <div className="row">
//             <div className="col-12">
//               <p>Welcome to HSRP System</p>
//             </div>
//           </div>
//           <div className="row">
//             <div className="col-6">
//               <div className="card">
//                 <div className="card-header">
//                   <h3 className="card-title">Orders</h3>
//                 </div>
//                 <div className="card-body">
//                   <div
//                     className="chart"
//                     style={{
//                       height: "250px",
//                       position: "relative",
//                       marginBottom: "1%",
//                       padding: "1%",
//                     }}
//                   >
//                     <Bar options={options} data={countdata} />
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-6">
//               <div className="card">
//                 <div className="card-header">
//                   <h3 className="card-title">B2B - Stastics</h3>
//                 </div>
//                 <div className="card-body">
//                   <div
//                     className="chart"
//                     style={{
//                       height: "250px",
//                       position: "relative",
//                       marginBottom: "1%",
//                       padding: "1%",
//                     }}
//                   >
//                     <Doughnut data={b2b_piedata} options={options} />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="row">
//             <div className="col-6">
//               <div className="card">
//                 <div className="card-header">
//                   <h3 className="card-title">B2C - Stastics</h3>
//                 </div>
//                 <div className="card-body">
//                   <div
//                     className="chart"
//                     style={{
//                       height: "250px",
//                       position: "relative",
//                       marginBottom: "1%",
//                       padding: "1%",
//                     }}
//                   >
//                     <Doughnut data={b2c_piedata} options={options} />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     );
//   }
// }
