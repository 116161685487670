import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Main from "./modules/main/Main";
import { useWindowSize } from "./hooks/useWindowSize";
import { calculateWindowSize } from "./utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { setWindowSize } from "./store/reducers/ui";

import Dashboard from "./pages/Dashboard";

import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";
import Login from "./modules/login/Login";
import { setAuthentication } from "./store/reducers/auth";
import { getAuthStatus } from "./utils/oidc-providers";
import Orders from "./pages/orders/Orders";
import RegisterNewOrder from "./pages/orders/RegisterNewOrder";
import Support from "./pages/Support";
import Dealers from "./pages/dealers/Dealers";
import OEMs from "./pages/oems/OEMs";
import DealerDetails from "./pages/dealers/DealerDetails";
import EmbosingStations from "./pages/ec/EmbosingStations";
import AddDealer from "./pages/dealers/AddDealer";
import AddOEM from "./pages/oems/AddOEM";
import AddEmposer from "./pages/ec/AddEmposer";
import QuickVahanCheck from "./pages/QuickVahanCheck";
import OfflinePaymentApproval from "./pages/reports/OfflinePaymentApproval";
import OrdersReport from "./pages/reports/OrdersReport";
import ChangePassword from "./components/modals/ChangePassword";
import OrderDetail from "./pages/orders/OrderDetail";
import OEMDetails from "./pages/oems/OEMDetails";
import EmbossingStationDetails from "./pages/ec/EmbossingStationDetails";

// master
import FuelType from "./pages/master/FuelType";
import OemRate from "./pages/master/OemRate";
import OrderStatus from "./pages/master/OrderStatus";
import PlateClass from "./pages/master/PlateClass";
import VechicleCategory from "./pages/master/VechicleCategory";
import VechicleClass from "./pages/master/VechicleClass";
import VechicleType from "./pages/master/VechicleType";
import { doAuthorize } from "./services/data-service/auth-service";

import TransactionDetails from "./pages/TransactionDetails";


const App = () => {
  const windowSize = useWindowSize();
  const screenSize = useSelector((state) => state.ui.screenSize);
  const dispatch = useDispatch();

  const [isAppLoading, setIsAppLoading] = useState(false);

  const checkSession = async () => {
    console.log("Checking Session");
    const token = localStorage.getItem("token");
    if (token) {
      await doAuthorize()
        .then((res) => {
          if (res.status !== 200) {
            localStorage.removeItem("token");
            localStorage.removeItem("authentication");
            window.location = "/";
          }
        })
        .catch((err) => {
          localStorage.removeItem("token");
          localStorage.removeItem("authentication");
        });
    }
    // try {
    //   let responses = await Promise.all([
    //     getAuthStatus(),
    //   ]);

    //   responses = responses.filter((r) => Boolean(r));

    //   if (responses && responses.length > 0) {
    //     dispatch(setAuthentication(responses[0]));
    //   }
    // } catch (error) {
    //   console.log('error', error);
    // }
    setIsAppLoading(false);
  };

  useEffect(() => {
    checkSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const size = calculateWindowSize(windowSize.width);
    if (screenSize !== size) {
      dispatch(setWindowSize(size));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  if (isAppLoading) {
    return <p>Loading</p>;
  }

  return (
    <>
      <Routes>
        <Route path="/login" element={<PublicRoute />}>
          <Route path="/login" element={<Login />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Main />}>
            <Route path="/change-password" element={<ChangePassword />} />

            <Route path="/orders" element={<Orders />} />
            <Route path="/register-order" element={<RegisterNewOrder />} />
            <Route path="/order-detail/:orderId" element={<OrderDetail />} />

            <Route path="/dealers" element={<Dealers />} />
            <Route path="/dealer-details/:id" element={<DealerDetails />} />
            <Route path="/new-dealer" element={<AddDealer />} />

            <Route path="/oems" element={<OEMs />} />
            <Route path="/new-oem" element={<AddOEM />} />
            <Route path="/oem-details" element={<OEMDetails />} />

            <Route path="/emc" element={<EmbosingStations />} />
            <Route path="/new-emposer" element={<AddEmposer />} />
            <Route path="/emc-details" element={<EmbossingStationDetails />} />

            <Route path="/support" element={<Support />} />
            <Route path="/transaction_details" element={<TransactionDetails />} />


            {/* master */}
            <Route path="/master-fuel-type" element={<FuelType />} />
            <Route path="/master-oem-rate" element={<OemRate />} />
            <Route path="/master-order-status" element={<OrderStatus />} />
            <Route path="/master-plate-class" element={<PlateClass />} />
            <Route
              path="/master-vehicle-category"
              element={<VechicleCategory />}
            />
            <Route path="/master-vehicle-class" element={<VechicleClass />} />
            <Route path="/master-vehicle-type" element={<VechicleType />} />

            <Route path="/vahan-check" element={<QuickVahanCheck />} />

            <Route path="/orders-report" element={<OrdersReport />} />
            <Route
              path="/Offline-transaction"
              element={<OfflinePaymentApproval />}
            />

            <Route path="/" element={<Dashboard />} />
          </Route>
        </Route>
      </Routes>
      <ToastContainer
        autoClose={3000}
        draggable={false}
        position="top-right"
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnHover
      />
    </>
  );
};

export default App;
