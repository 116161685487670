import React, { useState } from "react";
import ReactPlayer from "react-player";
import Select from "react-select";
import ContentHeader from "../components/content-header/ContentHeader";

export default function Support(props) {
  const [videoName, setVideoName] = useState("how-to-update-fixaction-date");
  const [title, setTitle] = useState("How to update fixaction date");
  const [isLoading, setIsLoading] = useState(false);

  function changeFAQ(name, title) {
    // setIsLoading(true);
    setVideoName(name);
    setTitle(title);
  }

  return (
    <div>
      <ContentHeader title="FAQs" />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              FAQ
              <Select
                options={[
                  {
                    label: "How to update fixaction date",
                    value: "how-to-update-fixaction-date",
                  },
                  {
                    label: "How to upload image",
                    value: "how-to-upload-image",
                  },
                  {
                    label: "How to change password",
                    value: "how-to-change-password",
                  },
                  {
                    label: "How to message",
                    value: "how-to-message-and-view-events",
                  },
                  {
                    label: "How to view events",
                    value: "how-to-message-and-view-events",
                  },
                ]}
                onChange={(e) => changeFAQ(e.value, e.label)}
              />
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">{title}</h3>
            </div>
            <div className="card-body">
              <center>
                <ReactPlayer url={`./video/${videoName}.mp4`} controls={true} />
              </center>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
