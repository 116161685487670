import http from "../common/http-common";

// Embosing Process
export async function getEmbossingDetails(orderId){
  return await http.get(`/embosingDetails/${orderId}`);
}

export async function setEmbossingDetailsAPI(id, data){
  return await http.post(`/embosingDetails/${id}`, data);
}

export function updateEmbossingDetailsAPI(id, data){
  return http.post(`/embosingDetails/${id}`, data);
}

export async function updateHsrpAPI(id, data) {
  return await http.put(`/b2b/orders/${id}/hsrp`, data);
}

// Embosing Stations
export async function listAllEmbossingStations(){
  return await http.get("/b2b/emc");
}

export async function getEmbossingStationDetails(id){
  return await http.get(`/b2b/emc/${id}`);
}

export async function setEmbossingStationAPI(data){
  return await http.post("/embosing-stations", data);
}

export function getEmbossingStationOrders(id){
  return http.get(`/embosing-stations/${id}/orders`);
}
