import ContentHeader from "../../components/content-header/ContentHeader";
import React, { Component, useRef, useCallback, useMemo } from "react";
import { useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";

import { useLocation } from "react-router-dom";
import { getOEMDetails } from "../../services/data-service/oem-service";
import { ViewActionButton } from "../../components/ViewActionButton";
import { useParams, useNavigate } from "react-router-dom";
import "./ag-grid-style.css";

import {
  getDistrictName,
  getStateName,
} from "../../services/data-service/master-service";
import { getDealersByOem } from "../../services/data-service/dealer-service";
import MaterialTable, { Column } from "@material-table/core";

async function fetchOEMData(oemId) {
  return await getOEMDetails(oemId);
}

async function fetchOEMDealers(oemId) {
  return await getDealersByOem(oemId);
}

const OEMDetails = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    let { id } = useParams();
    const oemId = location.state?.oemId | id | props.oemId;
    const [oemData, setOEMData] = React.useState({});
    const [oemDealers, setOEMDealers] = React.useState([]);
  
    const [stateName, setStateName] = React.useState(null);
    const [districtName, setDistrictName] = React.useState(null);
  
    const [dealersLoading, setDealersLoading] = React.useState(true);
  
    const lookup = { true: "Available", false: "Unavailable" };
  
    const columns = [
      {
        title: "User Id",
        field: "code",
        width: 150,
        render: (rowData) => {
          return (
            <Link to={`/dealer-details/${rowData.id}`} className="nav-link">
              <i className="fas fa-user nav-icon"></i> {rowData.code}
            </Link>
          );
        },
      },
      { title: "Dealer Name", field: "dealer_name" },
      { title: "Dealer mobile", field: "dealer_mobile" },
      { title: "Dealer Email", field: "dealer_email" },
    ];
  
    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const [columnDefs, setColumnDefs] = React.useState([
      {
        field: "id",
        headerName: "Action",
        cellRenderer: ViewActionButton,
        width: "80%",
        cellRendererParams: {
          clicked: function (field) {
            navigate("/dealer-details", { state: { dealerId: field } });
          },
        },
      },
      { field: "id", width: 80 },
      { field: "dealer_name", width: 300 },
      { field: "dealer_mobile", width: 200 },
      { field: "dealer_email", width: 300 },
    ]);
  
    // const onGridReady = useCallback((params) => {
    //   fetchOEMDealers(oemId).then((resp) => setOEMDealers(resp.data));
    // }, []);
  
    // const onColumnResized = useCallback((params) => {
    //   console.log(params);
    // }, []);
    // const autoSizeStrategy = useMemo(() => {
    //   return {
    //     type: "fitCellContents",
    //   };
    // }, []);
  
    // const sizeToFit = useCallback(() => {
    //   gridRef.current.api.sizeColumnsToFit({
    //     defaultMinWidth: 100,
    //   });
    // }, []);
  
    // const autoSizeAll = useCallback((skipHeader) => {
    //   const allColumnIds = [];
    //   gridRef.current.columnApi.getColumns().forEach((column) => {
    //     allColumnIds.push(column.getId());
    //   });
    //   gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    // }, []);
  
    useEffect(() => {
      fetchOEMData(oemId)
        .then(async (response) => {
          setOEMData(response.data);
          // getStateName(response.data.state).then((response) => {
          //   setStateName(response.data.stateName)
          // })
          // getDistrictName(response.data.district).then((response) => {
          //   setDistrictName(response.data.districtName)
          // })
        })
        .catch((error) => {
          console.log(error);
        });
      fetchOEMDealers(oemId).then((response) => {
        setOEMDealers(response.data);
        setDealersLoading(false)
        // const script = document.createElement("script");
        // script.src = "dist/js/content.js";
        // script.async = true;
        // document.body.appendChild(script);
      });
      // .catch((error) => {
      //   console.log(error);
      // });
      // .then((results) => results.json())
      // .then(
      //   (data) => {
      //     console.log(data);
      //     setOEMData(data);
      //   },
      //   (error) => {
      //     console.log(error);
      //   }
      // );
    }, [oemId]);
  
    // useEffect(() => {
    //   const script = document.createElement("script");
    //   script.src = "dist/js/content.js";
    //   script.async = true;
    //   document.body.appendChild(script);
  
    //   return () => {
    //     document.body.removeChild(script);
    //   };
    // }, []);

  return (
    <div>
      <ContentHeader title="OEM Detail" />
      <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-4">
                <div>
                  {/* Profile Image */}
                  <div className="card card-outline">
                    <div className="card-body box-profile">
                      <div className="text-center">
                        <img
                          className="profile-user-img img-fluid img-circle"
                          src="/img/avatar.png"
                          alt="User profile picture"
                        />
                      </div>
                      <h3 className="profile-username text-center">
                        {oemData.oem_name}
                      </h3>

                      <p className="text-muted text-center">
                        {oemData.oem_address}
                      </p>
                      <ul className="list-group list-group-unbordered mb-3">
                        <li className="list-group-item">
                          <b>Login Id</b>
                          <Link className="float-right">{oemData.code}</Link>
                        </li>
                        <li className="list-group-item">
                          <b>Mobile</b>
                          <Link className="float-right">
                            {oemData.oem_mobile}
                          </Link>
                        </li>
                        <li className="list-group-item">
                          <b>Email</b>
                          <Link className="float-right">
                            {oemData.oem_email}
                          </Link>
                        </li>
                        <li className="list-group-item">
                          <b>city</b>
                          <Link className="float-right">{oemData.city}</Link>
                        </li>
                        <li className="list-group-item">
                          <b>district</b>
                          <Link className="float-right">
                            {oemData.district}
                          </Link>
                        </li>
                        <li className="list-group-item">
                          <b>State</b>
                          <Link className="float-right">{oemData.state}</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-8">
                <div className="card">
                  <MaterialTable columns={columns} data={oemDealers} isLoading={dealersLoading} title="Dealers"/>
                </div>                
              </div>
            </div>
          </div>
        </section>
    </div>
  );
};

export default OEMDetails;
