import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { getAllOrderStatus, updateOrderDetails } from "../../services/data-service/order-service";
import { toast } from "react-toastify";

export default function OrderUpdateStatus(props) {
  // render() {
  //   const { show, onClose, id, status, onSave, onStatusChange, statusOption } = this.props;
  //   // const { statusOption } = this.state;
  //   // const options = [
  //   //   { value: "new", label: "new" },
  //   //   { value: "inprogress", label: "inprogress" },
  //   //   { value: "delivered", label: "delivered" },
  //   //   { value: "aborted", label: "aborted" },
  //   // ];
  //   let index = -1;
  //   const filteredOptions = statusOption.find(function (item, i) {
  //     // console.log(item.value, status)
  //     if (item.label === status) {
  //       index = i;
  //       return i;
  //     }
  //   });

  const [isLoading, setIsLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState(null);
  const [statusOptions, setStatusOptions] = useState(null);
  const [orderType, setOrderType] = useState(null);

  const orderTypeOptions = [
    { value: 1, label: "NB" },
    { value: 2, label: "OB" },
  ];

  useEffect(() => {
    getAllOrderStatus()
      .then((response) => {
        let optionData = [];
        response.data.map((item) =>
          optionData.push({ value: item.id, label: item.status })
        );
        setStatusOptions(optionData);
      })
      .catch((error) => {
        toast.error("Something went wrong, try again later!!");
      });
  }, []);

  function onStatusChange(v) {
    setOrderStatus(v);
  }

  function onOrderTypeChange(vclass) {
    setOrderType(vclass);
  }

  function submitOrderDetails() {
    // setIsLoading(true);

    const payload = {
      order_type: orderType,
      order_status: orderStatus,
    };

    updateOrderDetails(props.orderId, payload).then((response) => {
      if (response.status === 200) {
        toast.success("success", "Order Details Updated!");

        props.refreshOrderDetail();
        // setIsLoading(false);
        props.onClose();
      }
    });
  }

  return (
    <div
      className={`modal fade ${props.show ? "show" : "hide"}`}
      style={{ display: props.show ? "block" : "none" }}
      id="modal-lg"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          {isLoading && (
            <div className="overlay">
              <i className="fas fa-2x fa-sync fa-spin"></i>
            </div>
          )}
          <div className="modal-header">
            <h4 className="modal-title">Update Status</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => props.onClose()}>
                ×
              </span>
            </button>
          </div>
          <div className="modal-body">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Status</label>
                {statusOptions && (
                  <Select
                    defaultValue={
                      statusOptions[
                        statusOptions.findIndex(
                          (obj) => obj.label === props.orderDetail.status
                        )
                      ]
                    }
                    options={statusOptions}
                    onChange={(e) => onStatusChange(e.value)}
                  />
                )}
              </div>
              <div className="form-group">
                <label htmlFor="">Order Type</label>
                {orderTypeOptions && (
                  <Select
                    options={orderTypeOptions}
                    name="order_type"
                    onChange={(e) => onOrderTypeChange(e.value)}
                    defaultValue={
                      orderTypeOptions[
                        orderTypeOptions.findIndex(
                          (obj) => obj.label === props.orderDetail.order_type
                        )
                      ]
                    }
                  />
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-between">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => submitOrderDetails()}
            >
              Save changes
            </button>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}
