import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllOEM } from "../../services/data-service/oem-service";
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import ContentHeader from "../../components/content-header/ContentHeader";

async function fetchAllOem() {
  return await getAllOEM();
}

export default function OEMs(props) {
  const [oemList, setOemList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    { title: "#", render: (rowData) => rowData.tableData.id, width: 80 },
    {
      title: "User Id",
      field: "code",
      width: 150,
      render: (rowData) => {
        return (
          <Link
            to="/oem-details"
            state={{ oemId: rowData.id }}
            className="nav-link"
          >
            <i className="fas fa-user nav-icon"></i> {rowData.code}
          </Link>
        );
      },
    },
    { title: "Name", field: "oem_name" },
    { title: "Mobile", field: "oem_mobile" },
    { title: "Email", field: "oem_email" },
  ];

  useEffect(() => {
    fetchAllOem().then((response) => {
      setOemList(response.data);
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      <ContentHeader title="OEMs" />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <MaterialTable
              columns={columns}
              data={oemList}
              title=""
              isLoading={isLoading}
              options={{
                exportAllData: true,
                exportMenu: [
                  {
                    label: "Export PDF",
                    exportFunc: (cols, datas) => ExportPdf(cols, datas, "oems"),
                  },
                  {
                    label: "Export CSV",
                    exportFunc: (cols, datas) => ExportCsv(cols, datas, "oems"),
                  },
                ],
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
}
