import ContentHeader from "../../components/content-header/ContentHeader";
import React,{useState, useEffect} from "react";
import { Link } from "react-router-dom";
import VehicleClassModal from "../../components/modals/master/VehicleClassModal";
import {
  getAllVehicleClass,
  addNewVehicleClass,
  updateVehicleClass,
  deleteVehicleClass,
} from "../../services/data-service/master-service";
import { toast } from "react-toastify";
import MaterialTable from "@material-table/core";
import { getAuth } from "../../services/common/utils";



const VechicleClass = () => {

    const[isLoading, setIsLoading] = useState(true)
    const [showVehicleClassModal, setShowVehicleClassModal] = useState(false)
    const[vehicleClass, setVehicleClass] = useState({
      types: [],
      vehicleClass: "",
      showVehicleClassModal: false,
      currentValueFlag: false,
      currentId: "",
      currentValue: "",
    })    
    const { 
      types, 
      currentValue, 
      currentValueFlag } = vehicleClass

   

    function onShowVehicleClassModal() {
      setShowVehicleClassModal(true)
    }
  
    function onVehicleClassChange(vClass) {      
      setVehicleClass({...vehicleClass, vehicleClass: vClass });
    
    }
  
    const handleEdit = (id, vClass) => {
      setVehicleClass({
        ...vehicleClass,
        currentId: id,
        currentValue: vClass,
        currentValueFlag: true,        
      });
      setShowVehicleClassModal(true)
    };
  
    async function handleSave() {    
      const payload = {
        class: vehicleClass.vehicleClass,
      };
  
      await addNewVehicleClass(payload).then((res) => {
        toast.success("New vehicle type added sucessfully!");
      });
      handleClose();
      await init();
    }
  
    async function handleDelete(id) {
      await deleteVehicleClass(id).then((res) => {
        toast.info("Vehicle type deleted!");
      });
      init();
    }
  
    async function handleUpdate() {
      const payload = {
        class: vehicleClass.vehicleClass,
      };
  
      await updateVehicleClass(vehicleClass.currentId, payload).then((res) => {
        toast.success("New vehicle type added sucessfully!");
      });
      handleClose();
      init();
    }
  
    function handleClose() {
      setVehicleClass({
        ...vehicleClass,        
        currentValueFlag: false,
        currentValue: "",
      });
      setShowVehicleClassModal(false)
    }
  
    async function init() {
      await getAllVehicleClass().then((response) => {
        setVehicleClass({...vehicleClass, types: response.data });
  
      });
    }
    
    useEffect(()=>{
      init()
      .then((res)=>{
        console.log(res.data)
      })
      .catch((err)=>{
        console.log(err)
      })
      setIsLoading(false)
    },[])

    const columns = [
      {title:'No',field:'id'},
      {title:'Type',field:'vehicle_class'},
      {
        title:"Action",
        render: (rowData)=>{
          return(
            <div className="btn-group btn-group-sm">
              <button
                className="btn btn-info"
                onClick={() =>
                  handleEdit(rowData.id, rowData.vehicle_class)
                }
              >
                <i className="fas fa-edit"></i>
              </button>
              {/* <button
                className="btn btn-danger"
                onClick={() => handleDelete(rowData.id)}
              >
                <i className="fas fa-trash"></i>
              </button> */}
            </div>
          )
        },
      },
     
    ]
   const{role} = getAuth()
    return (
      <div>
        <ContentHeader title="Vehicle Class" />
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Vehicle Class</h3>
                    <div className="card-tools">
                      { role === "admin" && (
                        <button
                          className="btn btn-outline-primary"
                          onClick={onShowVehicleClassModal}
                        >
                          <i className="fas fa-user-plus"></i> Add
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <MaterialTable
                      title={''}
                      data={vehicleClass.types}
                      columns={columns}                      
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>          
          </section>
          {showVehicleClassModal && (
          <VehicleClassModal
            onClassChange={onVehicleClassChange}
            currentValue={currentValueFlag ? currentValue : ""}
            show={showVehicleClassModal}
            currentValueFlag={currentValueFlag}
            onClose={handleClose}
            onSave={handleSave}
            onUpdate={handleUpdate}
          />
        )}
      </div>
    );
  };
  
  export default VechicleClass;
  