import React, { useState } from "react";
import ContentHeader from "../../components/content-header/ContentHeader";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";
import {
  generateInvoice,
  getDealerDetails,
  getDealerInvoice,
  getDealerWallet,
  getDealerWalletHistory,
  getDealerOrderStastics,
} from "../../services/data-service/dealer-service";
import { useAuth, zeroPad } from "../../services/common/utils";
import { displayRazorpay } from "./razorPay";
import SubmitOfflinePayment from "./SubmitOfflinePayment";
import { doAuthorize } from "../../services/data-service/auth-service";

export default function DealerDashboard(props) {
  const location = useLocation();
  const { userid, user, role, refid } = useAuth();

  const dealerId = refid;

  const [isLoading, setIsLoading] = useState(true);

  const [rechargeTxnId, setRechargeTxnId] = useState("");
  const [orderData, setOrderData] = React.useState(null);
  const [invoiceData, setInvoiceData] = React.useState(null);
  const [oemId, setOemId] = React.useState(null);
  const [oemData, setOemData] = React.useState(null);
  const [dealerData, setDealerData] = React.useState(null);
  const [walletData, setWalletData] = React.useState("NA");
  const [postPaidData, setPostPaidData] = React.useState({});

  const [stateName, setStateName] = React.useState(null);
  const [districtName, setDistrictName] = React.useState(null);

  const [payAmount, setPayAmount] = React.useState(0);
  const [transId, setTransId] = useState("");
  const [invoices, setInvoices] = useState(null);
  const [isWalletHistoryLoading, setIsWalletHistoryLoading] = useState(true);
  const [walletHistory, setWalletHistory] = useState(null);

  //Dealer - Offline Payment Details
  const [dealerOfflinepayment, setDealerOfflinePayment] = React.useState(false);
  const [paymentType, setPaymentType] = React.useState("");
  const [paymentFile, setPaymentFile] = React.useState("");
  const [paymentAmount, setPaymentAmount] = React.useState("");
  const [paymentDate, setPaymentDate] = React.useState("");
  const [branchName, setBranchName] = React.useState("");
  const [chequeNo, setChequeNo] = React.useState("");
  const [chequeIssuedBank, setChequeIssuedBank] = React.useState("");
  const [checkAcceptedBank, setCheckAcceptedBank] = React.useState("");
  const [onlineTransactionType, setOnlineTransactionType] = React.useState("");
  const [onlineTransactionId, setOnlineTransactionId] = React.useState("");
  const [paymentMessage, setPaymentMessage] = React.useState("");
  const [dealerStastics, setDealerStastics] = React.useState({});
  const [loading, setLoading] = useState(true);
  // const {AFFIXATION,INVOICED, NEW ORDER} = dealerStastics

  useEffect(() => {
    console.log("Loading...");
    doAuthorize().then((res) => {
      console.log(res);
      if (res.status !== 200) {
        localStorage.removeItem("token");
        localStorage.removeItem("authentication");
        window.location = "/";
      }
      console.log("Auth...");
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    console.log(isLoading);
    if (!isLoading) {
      getDealerWalletHistory(dealerId).then((response) => {
        setWalletHistory(response.data);
        setIsWalletHistoryLoading(false);
      });
      getDealerOrderStastics().then((response) => {
        setLoading(false);
        setDealerStastics(response.data);
      });
    }
  }, [isLoading, dealerId]);

  const columns = [
    { title: "#", render: (rowData) => rowData.tableData.id, width: 80 },
    {
      title: "Id",
      field: "id",
    },
    {
      title: "Credit",
      field: "amount",
      render: (rowData) => {
        if (rowData.trans_type === 1)
          return (
            <span className="badge bg-success" style={{ "font-size": "100%" }}>
              <i className="fas fa-rupee-sign"></i> {rowData.amount}
            </span>
          );
      },
    },
    {
      title: "Debit",
      field: "amount",
      render: (rowData) => {
        if (rowData.trans_type === 2)
          return (
            <span className="badge bg-danger" style={{ "font-size": "100%" }}>
              <i className="fas fa-rupee-sign"></i> {rowData.amount}
            </span>
          );
      },
    },
    {
      field: "order_id",
      title: "ORDER ID",
      width: 200,
      render: (rowData) => {
        if (rowData.order_id !== null)
          return "ORD-" + zeroPad(rowData.order_id, 5);
      },
    },
    { field: "datetime", width: 300 },
  ];

  const column_invoices = [
    {
      title: "Id",
      field: "id",
    },
    {
      title: "Invoice No",
      field: "invoice_no",
    },
    {
      title: "Basic",
      field: "basic_amount",
      render: (rowData) => {
        return (
          <>
            <i className="fas fa-rupee-sign"></i> {rowData.basic_amount}
          </>
        );
      },
    },
    {
      title: "GST",
      render: (rowData) => {
        return (
          <>
            <i className="fas fa-rupee-sign"></i>{" "}
            {rowData.cgst_amount + rowData.sgst_amount}
          </>
        );
      },
    },
    {
      title: "Total",
      field: "roundoff_amount",
      render: (rowData) => {
        return (
          <>
            <i className="fas fa-rupee-sign"></i> {rowData.roundoff_amount}
          </>
        );
      },
    },
  ];

  // Recharge Modal

  const [rechargeModal, setRechargeModalFlag] = React.useState(false);

  useEffect(() => {
    getDealerDetails(dealerId)
      .then((response) => {
        setDealerData(response.data);
        setOemId(response.data.oem_id);
      })
      .catch((error) => {
        console.log(error);
      });
    getDealerInvoice(dealerId)
      .then((response) => {
        setInvoices(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    getDealerWallet(dealerId).then((response) => {
      setWalletData(response.data.amount);
    });
  }, [dealerId]);

  function generateDealerInvoice() {
    generateInvoice(dealerId).then((response) => {
      if (response.data["message"] === "no orders available") {
        toast.warn(response.data["message"]);
      } else {
        toast.success("invoice generated!");
      }
    });
  }

  function showRechargeModal() {
    setRechargeModalFlag(true);
  }

  function handleClose() {
    setRechargeModalFlag(false);
  }

  function handleAmountChange(data) {
    setPayAmount(data);
  }

  // Delear offline Payment Details
  function DealerOfflinepayment() {
    setDealerOfflinePayment(true);
    console.log("Dealer Payment Details");
  }
  function handledClosed() {
    setDealerOfflinePayment(false);
  }

  return (
    <>
      <ContentHeader title="Dealer Details" />
      {isLoading ? (
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">Loading...</div>
          </div>
        </section>
      ) : (
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3 col-sm-6 col-12">
                <div>
                  {/* Profile Image */}
                  <div className="card card-outline">
                    <div className="card-body box-profile">
                      {!dealerData ? (
                        <div className="overlay">
                          <i className="fas fa-2x fa-sync fa-spin" />
                        </div>
                      ) : (
                        <React.Fragment>
                          <div className="text-center">
                            <img
                              className="profile-user-img img-fluid img-circle"
                              src="/img/avatar.png"
                              alt="User"
                            />
                          </div>
                          <h3 className="profile-username text-center">
                            {dealerData.dealer_name}
                          </h3>
                          <p className="text-muted text-center">
                            {dealerData && dealerData.address}
                          </p>
                          <ul className="list-group list-group-unbordered">
                            <li className="list-group-item ">
                              <b>Login Id</b>
                              <a className="float-right">{dealerData.code}</a>
                            </li>
                            <li className="list-group-item">
                              <b>GST</b>
                              <a className="float-right">{dealerData.gst_no}</a>
                            </li>
                            <li className="list-group-item">
                              <b>OEM</b>
                              <a className="float-right">{dealerData.oem}</a>
                            </li>
                            <li className="list-group-item">
                              <b>Mobile</b>
                              <a className="float-right">
                                {dealerData.dealer_mobile}
                              </a>
                            </li>
                            <li className="list-group-item">
                              <b>Email</b>
                              <a className="float-right">
                                {dealerData.dealer_email}
                              </a>
                            </li>
                            <li className="list-group-item">
                              <b>City</b>
                              <a className="float-right">{dealerData.city}</a>
                            </li>
                            <li className="list-group-item">
                              <b>District</b>
                              <a className="float-right">
                                {dealerData.district}
                              </a>
                            </li>
                            <li className="list-group-item">
                              <b>State</b>
                              <a className="float-right">{dealerData.state}</a>
                            </li>
                            <li className="list-group-item">
                              <b>Pincode</b>
                              <a className="float-right">
                                {dealerData.pincode}
                              </a>
                            </li>
                          </ul>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9 col-sm-6 col-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-outline">
                      <div className="card-header">
                        Payment
                        <div className="card-tools">
                          {role === "admin" && (
                            <button
                              type="button"
                              className="btn btn-primary btn-block"
                              onClick={(e) => generateDealerInvoice()}
                            >
                              <i className="fa fa-file-invoice" /> Generate
                              Invoice
                            </button>
                          )}
                          {/* {role === "dealer" && (
                            <button
                              type="button"
                              className="btn btn-primary btn-block"
                              onClick={(e) => DealerOfflinepayment()}
                            >
                              Submit Offline Payment
                            </button>
                          )} */}
                        </div>
                      </div>
                      {!dealerData ? (
                        <div className="overlay">
                          <i className="fas fa-2x fa-sync fa-spin" />
                        </div>
                      ) : (
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6 col-12">
                              <div className="info-box">
                                <span className="info-box-icon bg-info">
                                  <i className="far fa-flag" />
                                </span>
                                <div className="info-box-content">
                                  <span className="info-box-text">
                                    Payment Type
                                  </span>
                                  <span className="info-box-number">
                                    {dealerData.payment_type}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-12">
                              {dealerData.payment_type === "PREPAID" && (
                                <React.Fragment>
                                  <div className="info-box">
                                    <span className="info-box-icon bg-info">
                                      <i className="fas fa-rupee-sign" />
                                    </span>

                                    <div className="info-box-content">
                                      <span className="info-box-text">
                                        Wallet Balance
                                      </span>
                                      <span className="info-box-number">
                                        <i className="fas fa-rupee-sign"></i>
                                        &nbsp; {walletData}
                                      </span>
                                    </div>
                                  </div>
                                </React.Fragment>
                              )}
                              {dealerData.payment_type === "POSTPAID" && (
                                <React.Fragment>
                                  <div className="info-box">
                                    <span className="info-box-icon bg-info">
                                      <i className="fas fa-rupee-sign" />
                                    </span>

                                    <div className="info-box-content">
                                      <span className="info-box-text">
                                        Wallet Due
                                      </span>
                                      <span className="info-box-number">
                                        <i className="fas fa-rupee-sign"></i>
                                        &nbsp; {walletData}
                                      </span>
                                    </div>
                                  </div>
                                </React.Fragment>
                              )}
                            </div>

                            {role == "dealer" &&
                              dealerData.payment_type === "PREPAID" && (
                                <div className="col-md-6">
                                  <div className="card card-success">
                                    <div className="card-body">
                                      <div className="form-group">
                                        <label htmlFor="inputEstimatedBudget">
                                          Amount to Recharge your Wallet
                                        </label>
                                        <input
                                          type="number"
                                          id="inputEstimatedBudget"
                                          className="form-control"
                                          defaultValue={0}
                                          onChange={(e) =>
                                            handleAmountChange(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="card-footer">
                                      <button
                                        type="button"
                                        className="btn btn-success float-right"
                                        onClick={(e) =>
                                          displayRazorpay(payAmount)
                                        }
                                        disabled={payAmount == 0 ? true : false}
                                      >
                                        Recharge
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            <div className="col-md-6">
                              <div className="card">
                                <div className="card-body">
                                  {role === "dealer" && (
                                    <div className="form-group">
                                      <label htmlFor="offlinepayment">
                                        Offline Payment
                                      </label>
                                      <button
                                        // id='offlinepayment'
                                        type="button"
                                        className="form-control btn btn-primary  btn-sm"
                                        onClick={(e) => DealerOfflinepayment()}
                                      >
                                        Submit Offline Payment
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              {loading ? (
                                <div className="overlay">
                                  <i className="fas fa-2x fa-sync fa-spin" />
                                </div>
                              ) : (
                                <div className="row">
                                  <div className="col-md-3 col-12">
                                    <div className="small-box bg-info">
                                      <div className="inner">
                                        {dealerStastics.b2b["NEW ORDER"] ? (
                                          <h3>
                                            {dealerStastics.b2b["NEW ORDER"]}
                                          </h3>
                                        ) : (
                                          <h3>0</h3>
                                        )}
                                        <p>New Orders</p>
                                      </div>
                                      <div className="icon">
                                        <i className="fas fa-shopping-cart"></i>
                                      </div>
                                      <a href="#" className="small-box-footer">
                                        More info{" "}
                                        <i className="fas fa-arrow-circle-right"></i>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="col-md-3 col-12">
                                    <div
                                      className="small-box"
                                      style={{ backgroundColor: "#f77a05" }}
                                    >
                                      <div className="inner">
                                        <h3>0</h3>
                                        <p>IN Process</p>
                                      </div>
                                      <div className="icon">
                                        <i className="fas fa-shopping-cart"></i>
                                      </div>
                                      <a href="#" className="small-box-footer">
                                        More info{" "}
                                        <i className="fas fa-arrow-circle-right"></i>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="col-md-3 col-12">
                                    <div
                                      className="small-box"
                                      style={{ backgroundColor: "#edd209" }}
                                    >
                                      <div className="inner">
                                        <h3>0</h3>
                                        <p>Dispatched</p>
                                      </div>
                                      <div className="icon">
                                        <i className="fas fa-shopping-cart"></i>
                                      </div>
                                      <a href="#" className="small-box-footer">
                                        More info{" "}
                                        <i className="fas fa-arrow-circle-right"></i>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="col-md-3 col-12">
                                    <div
                                      className="small-box"
                                      style={{ backgroundColor: "#05f772" }}
                                    >
                                      <div className="inner">
                                        {dealerStastics.b2b.AFFIXATION ? (
                                          <h3>
                                            {dealerStastics.b2b.AFFIXATION}
                                          </h3>
                                        ) : (
                                          <h3>0</h3>
                                        )}
                                        <p>Affixed</p>
                                      </div>
                                      <div className="icon">
                                        <i className="fas fa-shopping-cart"></i>
                                      </div>
                                      <a href="#" className="small-box-footer">
                                        More info{" "}
                                        <i className="fas fa-arrow-circle-right"></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      {/* <div className="card-body"> */}
                      {/* {walletHistory && (
                        <MaterialTable
                          columns={columns}
                          isLoading={isWalletHistoryLoading}
                          data={walletHistory}
                          title="Wallet History"
                          options={{
                            exportAllData: true,
                            exportMenu: [
                              {
                                label: "Export PDF",
                                exportFunc: (cols, datas) =>
                                  ExportPdf(cols, datas, "oems"),
                              },
                              {
                                label: "Export CSV",
                                exportFunc: (cols, datas) =>
                                  ExportCsv(cols, datas, "oems"),
                              },
                            ],
                          }}
                        />
                      )} */}
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="card">
                      {/* {invoices && (
                        <MaterialTable
                          title="Invoices"
                          columns={column_invoices}
                          data={invoices}
                        />
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {/* Modals */}
      {/* {rechargeModal && (
          <RechargeModal
            show={rechargeModal}
            onClose={handleClose}
            onSave={call_payment_api}
            onChangeAmount={handleAmountChange}
          />
        )} */}

      {/*Modals - Submit Offline Payment*/}
      <SubmitOfflinePayment
        show={dealerOfflinepayment}
        onClose={handledClosed}
      />
    </>
  );
}
