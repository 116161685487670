import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class HSRPResponseModal extends Component {
  render() {
    const { message, show, onClose } = this.props;

    return (
      <div
        className={`modal fade ${show ? "show" : "hide"}`}
        style={{ display: show ? "block" : "none" }}
        id="modal-md"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header bg-danger">
              <h4 className="modal-title">HSRP Response</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={() => onClose()}>
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body">
              {/* <div className="card card-primary"> */}
                <div className="card-body">
                <p>{message}</p>
                </div>
              {/* </div> */}
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={() => onClose()}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
