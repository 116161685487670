import ContentHeader from "../../components/content-header/ContentHeader";
import React, { Component, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import OrderUpdateStatus from "../../components/modals/OrderUpdateStatus";
import OrderAssignmentModal from "../../components/modals/OrderAssignmentModal";
import UpdateOrderTimeline from "../../components/modals/UpdateOrderTimeline";
import LaserCodeModal from "../../components/modals/LaserCodeModal";
import EventsModal from "../../components/modals/EventsModal";
import UploadImageModal from "../../components/modals/UploadImageModal";
import FixactionDateModal from "../../components/modals/FixactionDateModal";
import VehicleDetailsModal from "../../components/modals/VehicleDetailsModal";

import { MdDeleteForever } from "react-icons/md";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";

import {
  getAllOrderStatus,
  getOrderDetailsApi,
  setOrderStatusAPI,
  postOrderTimelineAPI,
  updateOrderEmbossingAPI,
  hsrpSubmitAPI,
  updateOrderPayment,
  getVehicleDetailsByOrder,
  getVehicleHSRPDetailsByOrder,
  getOrderPaymentDetailsByOrder,
  getOrderTimelineByOrder,
  getOrderImagesApi,
} from "../../services/data-service/order-service";
import {
  listAllEmbossingStations,
  setEmbossingStationAPI,
  getEmbossingDetails,
  getEmbossingStationDetails,
  updateEmbossingDetailsAPI,
  setEmbossingDetailsAPI,
  updateHsrpAPI,
} from "../../services/data-service/embossing-service";
import { useAuth, zeroPad, timestampToDate } from "../../services/common/utils";
import HSRPConfirmation from "../../components/modals/HSRPConfirmation";
import { getDealerDetails } from "../../services/data-service/dealer-service";
import { hsrpEntry } from "../../services/data-service/vahan-service";
import HSRPResponseModal from "../../components/modals/HSRPResponseModal";
import OrderUpdatePayment from "../../components/modals/OrderUpdatePayment";
import { getCategoryDimension } from "../../services/data-service/master-service";
import DownloadInvoice from "../../components/payment/DownloadInvoice";
import { getInvoiceDetails } from "../../services/data-service/invoice-service";
import DeleteModal from "../../components/modals/DeleteModal";

const OrderDetail = () => {
  const location = useLocation();
  const { orderId } = useParams();
  // const orderId = location.state?.orderId;

  const { user, role, userid } = useAuth();

  const [orderStatus, setOrderStatus] = React.useState("NA");

  const [statusData, setStatusData] = React.useState({});
  const [statusOption, setStatusOption] = React.useState([]);
  // orderAssignmentOption
  const [orderAssignmentOption, setOrderAssignmentOption] = React.useState([]);
  const [orderAssignmentId, setOrderAssignmentId] = React.useState("");

  // Get Details from API
  const [orderDetail, setOrderDetail] = React.useState(null);
  const [vehicleDetail, setVehicleDetail] = React.useState(null);
  const [vehicleHSRPDetail, setVehicleHSRPDetail] = React.useState(null);
  const [vahanMessage, setVahanMessage] = React.useState(null);
  const [orderPaymentDetail, setOrderPaymentDetail] = React.useState(null);
  const [invoiceId, setInvoiceId] = React.useState(null);
  const [invoiceDetails, setInvoiceDetails] = React.useState(null);
  const [orderTimelineDetail, setOrderTimelineDetail] = React.useState(null);
  const [dealerDetail, setDealerDetail] = React.useState(null);
  // Order Ack
  const [orderACK, setOrderACK] = React.useState(false);
  const [showOrderPayment, setShowOrderPayment] = React.useState(false);
  const [paymentStatus, setPaymentStatus] = React.useState();

  // Plate
  const [fbPlateDimension, setFbPlateDimension] = React.useState();
  const [bbPlateDimension, setBbPlateDimension] = React.useState();

  // Laser Code
  const [fLaserCode, setFLaserCode] = React.useState("NA");
  const [rLaserCode, setRLaserCode] = React.useState("NA");
  const [laserCodeFlag, setLaserCodeFlag] = React.useState(false);
  const [showLaserCode, setShowLaserCode] = React.useState(false);

  const [dealerId, setDealerId] = React.useState(null);
  const [status, setStatus] = React.useState("");
  const [showUpdateStatus, setshowUpdateStatus] = React.useState(false);
  const [showUpdateTimeline, setShowUpdateTimeline] = React.useState(false);
  const [showEventModal, setShowEventModal] = React.useState(false);
  const [showUploadImages, setShowUploadImages] = React.useState(false);
  const [showFixactionDate, setShowFixactionDate] = React.useState(false);
  const [showEditVehcileDetails, setEditVehcileDetails] = React.useState(false);
  const [timelineType, setTimelineType] = React.useState("");
  const [timelineMessage, setTimelineMessage] = React.useState("");

  // Embossing Station
  const [embossingStation, setEmbossingStation] = React.useState(null);
  const [embossingStationName, setEmbossingStationName] = React.useState(null);
  const [showEmbossingStation, setShowEmbossingStation] = React.useState(false);

  // HSRP
  const [showHDSPConfirmation, setShowHDSPConfirmation] = React.useState(false);
  const [hsrpPayload, setHsrpPayload] = React.useState({});
  const [hsrpResponseModal, setHsrpResponseModal] = React.useState(false);
  const [hsrpResponseMessage, setHsrpResponseMessage] = React.useState(false);

  // Images
  const [fImage, setFImage] = useState();
  const [rImage, setRImage] = useState();
  const [cImage, setCImage] = useState();

  // delete
  const [showdeleteConfirm, setdeleteConfirm] = React.useState(false);

  useEffect(() => {
    if (orderId == null || orderId === "") {
      console.log("order id empty");
      return;
    }

    getOrderDetails();
    getVehicleDetails();
    getVehicleHsrpDetails();

    getOrderPaymentDetailsByOrder(orderId).then(
      (response) => {
        setOrderPaymentDetail(response.data);
        console.log(response.data);
        if (response.data["invoice_id"])
          setInvoiceId(response.data["invoice_id"]);
      },
      (error) => {
        console.log(error);
      }
    );

    getOrderImages();
    getTimelineHistory();
  }, [orderId]);

  const getOrderDetails = () => {
    getOrderDetailsApi(orderId)
      .then((response) => {
        setOrderDetail(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getOrderImages = () => {
    getOrderImagesApi(orderId, "f")
      .then(async (response) => {
        console.log(response);
        const url = URL.createObjectURL(response.data);
        setFImage(url);
      })
      .catch((error) => {
        console.log(error);
      });
    getOrderImagesApi(orderId, "r")
      .then(async (response) => {
        const url = URL.createObjectURL(response.data);
        setRImage(url);
      })
      .catch((error) => {
        console.log(error);
      });
    getOrderImagesApi(orderId, "c")
      .then(async (response) => {
        const url = URL.createObjectURL(response.data);
        setCImage(url);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getVehicleHsrpDetails = () => {
    getVehicleHSRPDetailsByOrder(orderId).then(
      (response) => {
        console.log(response.data.vahan_message);
        setVehicleHSRPDetail(response.data);
        setFLaserCode(response.data.front_laser_code);
        setRLaserCode(response.data.rear_laser_code);

        if (
          response.data.vahan_message === undefined ||
          response.data.vahan_message === null
        ) {
          setVahanMessage("null");
        } else if (
          response.data.vahan_message.includes("HSRP Data Inserted") ||
          response.data.vahan_message.includes("Vehicle is already HSRP Fitted")
        ) {
          setVahanMessage("success");
        } else {
          setVahanMessage("error");
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getVehicleDetails = () => {
    getVehicleDetailsByOrder(orderId).then(
      (response) => {
        setVehicleDetail(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getTimelineHistory = () => {
    getOrderTimelineByOrder(orderId).then(
      (response) => {
        setOrderTimelineDetail(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    if (invoiceId) {
      getInvoiceDetails(orderPaymentDetail.invoice_id).then((response) => {
        // console.log(response.data);
        setInvoiceDetails(response.data);
      });
    }
  }, [invoiceId]);

  // useEffect(() => {
  //   getAllOrderStatus()
  //     .then((response) => {
  //       let optionData = [];
  //       response.data.map((item) =>
  //         optionData.push({ value: item.id, label: item.status })
  //       );
  //       setStatusOption(optionData);
  //     })
  //     .catch((error) => {
  //       toastMessage("error", "Something went wrong, try again later!!");
  //     });
  // }, []);

  // React.useEffect(() => {
  //   listAllEmbossingStations().then((response) => {
  //     let optionData = [];
  //     response.data.map((item) =>
  //       optionData.push({ value: item.id, label: item.name })
  //     );
  //     setOrderAssignmentOption(optionData);
  //   });
  // }, []);

  function handleUpdateStatus() {
    setshowUpdateStatus(true);
  }

  function showTimelineModal() {
    setShowUpdateTimeline(true);
  }

  function onClickEventsModal() {
    setShowEventModal(true);
  }

  function onClickUploadImage() {
    setShowUploadImages(true);
  }

  function onClickEditVehicleDetails() {
    setEditVehcileDetails(true);
  }

  function onClickFixactionDate() {
    setShowFixactionDate(true);
  }

  function showLaserCodeModal() {
    setShowLaserCode(true);
  }

  function timeLineTypeChange(type) {
    setTimelineType(type);
  }

  function timeLineMessageChange(message) {
    setTimelineMessage(message);
  }

  function updateFLaserCode(code) {
    setFLaserCode(code);
  }

  function updateRLaserCode(code) {
    setRLaserCode(code);
  }
  function onClickDelete() {
    setdeleteConfirm(true);
  }
  async function doOrderACK() {
    setOrderACK(true);
    const statusPayload = {
      status: "In-Progress",
    };

    await setOrderStatusAPI(orderId, statusPayload).then((response) => {
      setOrderStatus("In-Progress");
    });

    const timelinePayload = {
      type: "email",
      message: "Order Acknowledged/Received!!",
      updatedBy: localStorage.getItem("user"),
    };
    toast.success("Order Acknowledged/Received!!");

    await postOrderTimeline(timelinePayload);

    // await postOrderTimelineAPI(orderId, timelinePayload).then((response) => {
    //   setOrderACK(false);
    //   getOrderTimelineByOrder(orderId).then(
    //     (response) => {
    //       toastMessage("success", "Order Acknowledged/Received!!");
    //       setOrderTimelineDetail(response.data);
    //     },
    //     (error) => {
    //       toastMessage("error", "Something went wrong, try again later!!");
    //       console.log(error);
    //     }
    //   );
    // });
  }

  async function postOrderTimeline(timelinePayload) {
    await postOrderTimelineAPI(orderId, timelinePayload).then((response) => {
      setOrderACK(false);
      getOrderTimelineByOrder(orderId).then(
        (response) => {
          setOrderTimelineDetail(response.data);
        },
        (error) => {
          toast.error("Something went wrong, try again later!!");
          console.log(error);
        }
      );
    });
  }

  async function saveLaserCode() {
    const payload = {
      r_laser_code: rLaserCode,
      f_laser_code: fLaserCode,
    };

    await updateHsrpAPI(orderId, payload);

    const payloadTimeline = {
      type: "note",
      message: "Laser Code Updated!!",
      updatedBy: user,
    };
    await postOrderTimelineAPI(orderId, payloadTimeline).then((response) => {
      toast.success("Laser Code Updated!!");
    });

    getVehicleHSRPDetailsByOrder(orderId).then(
      (response) => {
        setVehicleHSRPDetail(response.data);
      },
      (error) => {
        console.log(error);
      }
    );

    getTimelineHistory();

    // if (laserCodeFlag) {
    //   await updateEmbossingDetailsAPI(orderId, payload);
    //   toastMessage("success", "Laser Code Updated!!");

    //   const timelinePayload = {
    //     type: "note",
    //     message: "Laser code updated",
    //     updatedBy: localStorage.getItem("user"),
    //   };
    //   await postOrderTimeline(timelinePayload);
    // } else {
    //   await setEmbossingDetailsAPI(orderId, payload);
    //   toastMessage("success", "Laser Code Added!!");

    //   const timelinePayload = {
    //     type: "note",
    //     message: "Laser code added",
    //     updatedBy: localStorage.getItem("user"),
    //   };
    //   await postOrderTimeline(timelinePayload);
    // }
    setShowLaserCode(false);
  }

  async function saveTimeLineChanges() {
    handleClose();

    setOrderTimelineDetail(null);

    const payload = {
      type: timelineType,
      message: timelineMessage,
      updatedBy: localStorage.getItem("user"),
    };

    await postOrderTimelineAPI(orderId, payload).then((response) => {
      toast.success("New Message Added!!");
    });

    getTimelineHistory();
  }

  function handleUpdatePaymentChanged(status) {
    setPaymentStatus(status);
  }

  function handleOrderAssignmentChanged(assignmentId) {
    setOrderAssignmentId(assignmentId);
  }

  function getTimelineIcon(type) {
    switch (type) {
      case "email":
        return "fas fa-envelope bg-blue";
      case "call":
        return "fas fa-phone bg-warning";
      case "note":
        return "fas fa-sticky-note bg-info";
      case "check":
        return "fas fa-check bg-success";
      default:
        return "fas fa-comment bg-warning";
    }
  }

  // async function handleUpdateStatusSave() {
  //   handleClose();
  //   setOrderTimelineDetail(null);
  //   const payload = {
  //     status_id: status,
  //   };

  //   await setOrderStatusAPI(orderId, payload).then((response) => {
  //     setOrderStatus(statusOption[status - 1].label);
  //     toastMessage("success", "Order Status Updated!!");
  //   });

  //   getOrderDetails();
  //   getTimelineHistory();
  // }

  async function handleUpdatePaymentStatusSave() {
    const payload = {
      paymentStatus: paymentStatus,
    };

    await updateOrderPayment(orderId, payload).then((response) => {
      handleClose();
      toast.success("Order Payment Status Updated!!");
    });
    console.log(paymentStatus);
    const type = paymentStatus === "1" ? "check" : "note";
    const message =
      paymentStatus === "1" ? "Payment Received" : "Payment Not Received";

    const timelinePayload = {
      type: type,
      message: message,
      updatedBy: localStorage.getItem("user"),
    };
    console.log(timelinePayload);

    await postOrderTimelineAPI(orderId, timelinePayload).then((response) => {
      getOrderTimelineByOrder(orderId).then(
        (response) => {
          setOrderTimelineDetail(response.data);
        },
        (error) => {
          toast.error("Something went wrong, try again later!!");
          console.log(error);
        }
      );
    });
  }

  async function handleOrderAssignmentSave() {
    const payload = {
      assignedTo: orderAssignmentId,
    };
    await updateOrderEmbossingAPI(orderId, payload);
    toast.success("Order Updated!!");
    getEmbossingStationDetails(orderAssignmentId)
      .then(async (response) => {
        setEmbossingStationName(response.data.name);
        const timelinePayload = {
          type: "note",
          message: `Order assigned to Embossing Station: ${response.data.name}`,
          updatedBy: localStorage.getItem("user"),
        };
        await postOrderTimeline(timelinePayload);
      })
      .catch((e) => {
        setEmbossingStationName("NA");
      });
    handleClose();
  }

  function OnShowEmbossingStation() {
    setShowEmbossingStation(true);
  }

  function onShowHDSPConfirmation() {
    const HsrpPayload = {
      regnNo: vehicleDetail.reg_no,
      frontLaserCode: vehicleHSRPDetail.front_laser_code,
      rearLaserCode: vehicleHSRPDetail.rear_laser_code,
      fixationDate: vehicleHSRPDetail.fixation_date ?? "",
      flag: orderDetail.order_type,
      chasisNo: vehicleDetail.chassis_no,
    };
    setHsrpPayload(HsrpPayload);
    setShowHDSPConfirmation(true);
  }
  function onFixationDateChanged(fixationDate) {
    console.log(fixationDate);
    const HsrpPayload = {
      regnNo: orderDetail.regNo,
      frontLaserCode: vehicleHSRPDetail.front_laser_code,
      rearLaserCode: vehicleHSRPDetail.rear_laser_code,
      fixationDate: fixationDate,
      flag: orderDetail.order_type,
      chasisNo: orderDetail.chassisNo,
    };
    setHsrpPayload(HsrpPayload);
  }

  function onShowOrderPaymentClick() {
    setShowOrderPayment(true);
  }

  function submitToHSRP(event) {
    event.preventDefault();
    const data = event.target.elements;
    console.log(data.fixationdate.value);
    hsrpPayload.fixationDate = data.fixationdate.value;
    console.log(hsrpPayload, orderId);
    // Call Vahan API
    hsrpEntry(hsrpPayload)
      .then((response) => {
        console.log(JSON.parse(response.data));
        const hsrpMessage = response.data.toString();
        if (
          (hsrpMessage != null && hsrpMessage.includes("HSRP Data Inserted")) ||
          hsrpMessage.includes("Vehicle is already HSRP Fitted")
        ) {
          const payload = {
            vahanStatus: "Fitted",
            fixationDate: hsrpPayload.fixationDate,
            vahanMessage: JSON.parse(response.data),
          };
          console.log(payload);
          hsrpSubmitAPI(orderId, payload)
            .then((response) => {
              toast.success(hsrpMessage);
            })
            .catch((error) => {
              toast.error("HSRP Data Inserted but DB update failed!!");
            });
          toast.success("HSRP Data Inserted");
          // } else if (hsrpMessage.includes("Vehicle is already HSRP Fitted")) {
          //   toastMessage("info", "Vehicle is already HSRP Fitted");
        } else {
          // toastMessage("error", "Something went wrong!!");
          setHsrpResponseMessage(hsrpMessage);
          setHsrpResponseModal(true);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error("Something went wrong!!");
        // setHsrpResponseMessage(e.response.data);
        // setHsrpResponseModal(true);
      });
    handleClose();
  }

  function handleClose() {
    setshowUpdateStatus(false);
    setShowFixactionDate(false);
    setShowUpdateTimeline(false);
    setShowEventModal(false);
    setShowUploadImages(false);
    setShowLaserCode(false);
    setShowEmbossingStation(false);
    setShowHDSPConfirmation(false);
    setHsrpResponseModal(false);
    setShowOrderPayment(false);
    setEditVehcileDetails(false);
    setdeleteConfirm(false);
  }

  return (
    <div>
      <ContentHeader title="Order Detail" />
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">
              <strong>Order Detail</strong>
            </h3>
            {orderDetail && !orderDetail.hsrpStatus && (
              <div className="card-tools">
                <div className="btn-group no-print">
                  {role === "admin" && (
                    <>
                      {/* <button
                            type="button"
                            className="btn btn-outline-info"
                            onClick={doOrderACK}
                            // disabled={orderACK}
                            >
                            <i className="fas fa-check"></i> Acknowledge Order
                            </button>                         */}
                      {/* {orderDetail && orderDetail.status !== "Delivered" && (
                            <button
                            type="button"
                            className="btn btn-outline-info"
                            onClick={OnShowEmbossingStation}
                            >
                            <i className="fas fa-window-restore"></i> Assign To
                            </button>
                        )} */}

                      <button
                        type="button"
                        className="btn btn-outline-info"
                        onClick={handleUpdateStatus}
                      >
                        <i className="fas fa-pen"></i> Status
                      </button>
                      {/* <button
                        type="button"
                        className="btn btn-outline-info"
                        onClick={onShowOrderPaymentClick}
                        >
                        <i className="fas fa-rupee-sign"></i> Payment
                        </button> */}
                      {vehicleHSRPDetail && vehicleHSRPDetail && (
                        // vehicleHSRPDetail.vahan_status !== "Fitted" &&
                        <button
                          type="button"
                          className="btn btn-outline-info"
                          onClick={onShowHDSPConfirmation}
                          // disabled={orderACK}
                        >
                          <i className="fas fa-file-import"></i> Submit HSRP
                        </button>
                      )}
                    </>
                  )}
                  {(role === "emc" || role === "admin") &&
                    vehicleHSRPDetail &&
                    vehicleHSRPDetail.vahan_status !== "Fitted" && (
                      <button
                        type="button"
                        className="btn btn-outline-info"
                        onClick={showLaserCodeModal}
                      >
                        <i className="fas fa-barcode"></i> Laser Code
                      </button>
                    )}
                  {(role === "emc" ||
                    role === "admin" ||
                    role === "dealer") && (
                    <button
                      type="button"
                      className="btn btn-outline-info"
                      onClick={(e) => onClickUploadImage()}
                    >
                      <i className="fas fa-images"></i> Upload Image
                    </button>
                  )}

                  {(role === "admin" || role === "dealer") && (
                    <button
                      type="button"
                      className="btn btn-outline-info"
                      onClick={(e) => onClickFixactionDate()}
                    >
                      <i className="fas fa-edit"></i> Fixation Date
                    </button>
                  )}

                  <button
                    type="button"
                    className="btn btn-outline-info"
                    onClick={onClickEventsModal}
                  >
                    <i className="fas fa-calendar-alt"></i> Events
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-info"
                    onClick={showTimelineModal}
                  >
                    <i className="fas fa-comment-alt"></i> Message
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-info"
                    onClick={onClickDelete}
                  >
                    <MdDeleteForever size={20} /> Delete
                  </button>
                </div>
              </div>
            )}
          </div>
          {/* {orderDetail ? ( */}
          <div className="card-body">
            <div className="row">
              <div className="col-md-8 col-sm-8 col-12 order-2 order-md-2">
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <div className="info-box">
                      <span className="info-box-icon">
                        <i className="fas fa-user-tie"></i>
                      </span>
                      <div className="info-box-content">
                        <span className="info-box-text">Dealer</span>
                        <span className="info-box-number">
                          {orderDetail ? (
                            orderDetail.dealer_name
                          ) : (
                            <i className="fas fa-1x fa-sync fa-spin"></i>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-6">
                    <div className="info-box">
                      <span className="info-box-icon">
                        <i className="fas fa-car" />
                      </span>
                      <div className="info-box-content">
                        <span className="info-box-text">OEM</span>
                        <span className="info-box-number">
                          {vehicleDetail ? (
                            vehicleDetail.oem
                          ) : (
                            <i className="fas fa-1x fa-sync fa-spin"></i>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-sm-6">
                    <div className="info-box">
                      <span className="info-box-icon">
                        <i className="fas fa-wrench" />
                      </span>
                      <div className="info-box-content">
                        <span className="info-box-text">Embossing Station</span>
                        <span className="info-box-number">
                          {orderDetail ? (
                            orderDetail.emc_name
                          ) : (
                            <i className="fas fa-1x fa-sync fa-spin"></i>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="info-box">
                      <span className="info-box-icon">
                        <i className="fas fa-user" />
                      </span>
                      <div className="info-box-content">
                        <span className="info-box-text">Owner</span>
                        <span className="info-box-number">
                          {vehicleDetail ? (
                            vehicleDetail.owner_name
                          ) : (
                            <i className="fas fa-1x fa-sync fa-spin"></i>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4 col-sm-12">
                    <div className="card">
                      {/* <div className="ribbon-wrapper ribbon-lg no-print">
                            {vehicleHSRPDetail &&
                                vehicleHSRPDetail.vahan_status === 1 && (
                                <div className="ribbon bg-success text-lg">
                                    Fitted
                                </div>
                                )}
                            </div> */}

                      <div className="card-header">
                        <h3 className="card-title">
                          <strong>
                            <i className="fas fa-university"></i> Vahan (HSRP)
                          </strong>
                        </h3>

                        <div className="card-tools">
                          {vahanMessage && vahanMessage === "error" && (
                            <button
                              type="button"
                              className="btn btn-tool"
                              onClick={() => {
                                toast.error(
                                  vehicleHSRPDetail.vahan_message ?? "Error"
                                );
                              }}
                            >
                              <i
                                className="fas fa-exclamation-triangle"
                                style={{ color: "#f91515" }}
                              ></i>
                            </button>
                          )}
                          {vahanMessage && vahanMessage === "success" && (
                            <button type="button" className="btn btn-tool">
                              <i
                                className="fas fa-check-circle"
                                style={{ color: "#0eaa2d" }}
                              ></i>
                            </button>
                          )}
                          {vahanMessage && vahanMessage === "null" && (
                            <button type="button" className="btn btn-tool">
                              <i
                                className="far fa-question-circle"
                                style={{ color: "#737882" }}
                              ></i>
                            </button>
                          )}
                        </div>
                      </div>

                      <div className="card-body">
                        {!vehicleHSRPDetail ? (
                          <div className="overlay">
                            <i className="fas fa-2x fa-sync fa-spin"></i>
                          </div>
                        ) : (
                          <>
                            <p className="text-sm">
                              F Laser Code
                              <b className="d-block">
                                {vehicleHSRPDetail.front_laser_code
                                  ? vehicleHSRPDetail.front_laser_code
                                  : "NA"}
                              </b>
                            </p>
                            <p className="text-sm">
                              R Laser Code
                              <b className="d-block">
                                {vehicleHSRPDetail.rear_laser_code
                                  ? vehicleHSRPDetail.rear_laser_code
                                  : "NA"}
                              </b>
                            </p>
                            <hr />
                            <p className="text-sm">
                              Fixation Status
                              <b className="d-block">
                                {vehicleHSRPDetail.vahan_status === "Fitted" ? (
                                  <span className="badge bg-success">
                                    {vehicleHSRPDetail.vahan_status}
                                  </span>
                                ) : (
                                  <span className="badge bg-danger">
                                    {vehicleHSRPDetail.vahan_status}
                                  </span>
                                )}
                              </b>
                            </p>
                            <p className="text-sm">
                              Fixation Date
                              <b className="d-block">
                                {vehicleHSRPDetail.fixation_date
                                  ? vehicleHSRPDetail.fixation_date
                                  : "NA"}
                              </b>
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-8 col-sm-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">
                          <strong>
                            <i className="fas fa-rupee-sign"></i> Payment
                            Details
                          </strong>
                        </h3>
                        {orderPaymentDetail &&
                          orderPaymentDetail.invoice_id && (
                            <div className="card-tools">
                              {invoiceDetails && (
                                <PDFDownloadLink
                                  document={
                                    <DownloadInvoice invoice={invoiceDetails} />
                                  }
                                  fileName={invoiceDetails.invoice_no + ".pdf"}
                                >
                                  <button
                                    className="btn btn-sm btn-tool"
                                    title="Download Invoice"
                                  >
                                    <i
                                      className="fas fa-download"
                                      style={{ color: "#0e56b4" }}
                                    ></i>
                                  </button>
                                </PDFDownloadLink>
                              )}
                            </div>
                          )}
                      </div>
                      <div className="card-body">
                        {!orderPaymentDetail ? (
                          <div className="overlay">
                            <i className="fas fa-2x fa-sync fa-spin"></i>
                          </div>
                        ) : (
                          <>
                            <p>
                              Challan No #
                              <strong>
                                &nbsp;
                                {orderPaymentDetail.invoice_no
                                  ? orderPaymentDetail.invoice_no
                                  : "NA"}
                              </strong>
                            </p>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th>Sub Amount</th>
                                  <th>CGST({orderPaymentDetail.cgst}%)</th>
                                  <th>SGST({orderPaymentDetail.sgst}%)</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <i className="fas fa-rupee-sign"></i>{" "}
                                    {orderPaymentDetail.basic_amount}
                                  </td>
                                  <td>
                                    <i className="fas fa-rupee-sign"></i>{" "}
                                    {orderPaymentDetail.cgst_amount}
                                  </td>
                                  <td>
                                    <i className="fas fa-rupee-sign"></i>{" "}
                                    {orderPaymentDetail.sgst_amount}
                                  </td>
                                  <td>
                                    <i className="fas fa-rupee-sign"></i>{" "}
                                    {orderPaymentDetail.net_amount}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">
                          <strong>
                            <i className="fas fa-images"></i> Picture
                          </strong>
                        </h3>
                        <div className="card-tools">
                          {(role === "emc" ||
                            role === "admin" ||
                            role === "dealer") && (
                            <button
                              className="btn btn-sm btn-tool"
                              title="Upload Image"
                              onClick={(e) => onClickUploadImage()}
                            >
                              <i
                                className="fas fa-upload"
                                style={{ color: "#347c04" }}
                              ></i>
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="card-body">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>F Image</th>
                              <th>R Image</th>
                              <th>C Image</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Zoom
                                  zoomImg={{
                                    src: fImage ?? "img/noimage.jpg",
                                  }}
                                >
                                  <img
                                    src={fImage ?? "img/noimage.jpg"}
                                    width={"100%"}
                                    height={"100%"}
                                  ></img>
                                </Zoom>
                              </td>
                              <td>
                                <Zoom
                                  zoomImg={{
                                    src: rImage ?? "img/noimage.jpg",
                                  }}
                                >
                                  <img
                                    src={rImage ?? "img/noimage.jpg"}
                                    width={"100%"}
                                    height={"100%"}
                                  ></img>
                                </Zoom>
                              </td>
                              <td>
                                <Zoom
                                  zoomImg={{
                                    src: cImage ?? "img/noimage.jpg",
                                  }}
                                >
                                  <img
                                    src={cImage ?? "img/noimage.jpg"}
                                    width={"100%"}
                                    height={"100%"}
                                  ></img>
                                </Zoom>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Right Side Content */}
              <div className="col-md-4 order-1 order-md-1">
                <div className="card">
                  <div className="card-header">
                    {/* <Link
                            className="btn-link text-secondary float-right no-print"
                            onClick={(e) => window.print()}
                        >
                            <i className="fas fa-fw fa-print" /> Print
                        </Link> */}
                    {!orderId ? (
                      <div className="overlay">
                        <i className="fas fa-2x fa-sync fa-spin"></i>
                      </div>
                    ) : (
                      <>
                        <h3 className="card-title text-primary">
                          <strong>
                            <i className="fas fa-hashtag" /> ORD-
                            {zeroPad(orderId, 5)}
                          </strong>
                        </h3>
                        <div className="card-tools">
                          {role === "admin" && (
                            <button
                              className="btn btn-sm btn-tool"
                              title="Edit"
                              onClick={handleUpdateStatus}
                            >
                              <i
                                className="fas fa-edit"
                                style={{ color: "#001e52" }}
                              ></i>
                            </button>
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  <div className="card-body">
                    {!orderDetail ? (
                      <div className="overlay">
                        <i className="fas fa-2x fa-sync fa-spin"></i>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-6">
                          <div className="text-sm">
                            <p className="text-sm">
                              Status
                              <b className="d-block">{orderDetail.status}</b>
                            </p>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="text-sm">
                            <p className="text-sm">
                              Order Type
                              <b className="d-block">
                                {orderDetail.order_type
                                  ? orderDetail.order_type
                                  : "NULL"}
                              </b>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* <div className="float-right">
                            <h5 className="mt-5 text-muted no-print">Misc</h5>
                            <ul className="list-unstyled no-print">
                            <li>
                            <Link className="btn-link text-secondary">
                                <i className="far fa-fw fa-file-pdf" /> Download
                                PDF
                            </Link>
                            </li>
                            <li>
                                <Link
                                className="btn-link text-secondary"
                                onClick={(e) => window.print()}
                                >
                                <i className="fas fa-fw fa-print" /> Print
                                </Link>
                            </li>
                            </ul>
                        </div> */}
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">
                      <strong>
                        <i className="fas fa-car"></i> Vehicle Details
                      </strong>
                    </h3>

                    <div className="card-tools">
                      {role === "admin" && (
                        <button
                          className="btn btn-sm btn-tool"
                          title="Edit"
                          onClick={(e) => onClickEditVehicleDetails()}
                        >
                          <i
                            className="fas fa-edit"
                            style={{ color: "#001e52" }}
                          ></i>
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="card-body">
                    {!vehicleDetail ? (
                      <div className="overlay">
                        <i className="fas fa-2x fa-sync fa-spin"></i>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-6">
                          <div className="text-sm">
                            <p className="text-sm">
                              Reg No
                              <b className="d-block">{vehicleDetail.reg_no}</b>
                            </p>

                            {/* <p className="text-sm">
                                Order Type
                                <b className="d-block">
                                    {orderDetail.orderType}
                                </b>
                                </p> */}

                            <p className="text-sm">
                              Chassis No
                              <b className="d-block">
                                {vehicleDetail.chassis_no}
                              </b>
                            </p>

                            <p className="text-sm">
                              Engine No
                              <b className="d-block">
                                {vehicleDetail.engine_no}
                              </b>
                            </p>

                            <p className="text-sm">
                              Vehicle Class
                              <b className="d-block">
                                {vehicleDetail.vehicle_class}
                              </b>
                            </p>
                            <p className="text-sm">
                              Vehicle Type
                              <b className="d-block">
                                {vehicleDetail.vehicle_type}
                              </b>
                            </p>
                            <p className="text-sm">
                              Plate Classification
                              <b className="d-block">
                                {vehicleDetail.plate_color}
                              </b>
                            </p>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="text-sm">
                            <p className="text-sm">
                              Vehicle Model
                              <b className="d-block">
                                {vehicleDetail.vehicle_model}
                              </b>
                            </p>
                            <p className="text-sm">
                              Fuel Type
                              <b className="d-block">
                                {vehicleDetail.fuel_type}
                              </b>
                            </p>

                            <p className="text-sm">
                              Front Plate Dimension(mm)
                              <b className="d-block">
                                {vehicleDetail.frontboard_dimension}
                              </b>
                            </p>
                            <p className="text-sm">
                              Black Plate Dimension(mm)
                              <b className="d-block">
                                {vehicleDetail.backboard_dimension}
                              </b>
                            </p>
                            <p className="text-sm">
                              Registration Date
                              <b className="d-block">
                                {timestampToDate(vehicleDetail.reg_date)}
                              </b>
                            </p>
                            <p className="text-sm">
                              Manufacturing Date
                              <b className="d-block">
                                {timestampToDate(vehicleDetail.man_date)}
                              </b>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ) : (
                <div className="overlay">
                <i className="fas fa-2x fa-sync fa-spin"></i>
                </div>
            )} */}
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {orderDetail && showUpdateStatus && (
        <OrderUpdateStatus
          orderId={orderId}
          orderDetail={orderDetail}
          statusOption={statusOption}
          show={showUpdateStatus}
          refreshOrderDetail={getOrderDetails}
          onClose={handleClose}
        />
      )}
      {orderDetail && (
        <LaserCodeModal
          // status={orderDetail.status}
          show={showLaserCode}
          onClose={handleClose}
          fLaserCode={fLaserCode}
          rLaserCode={rLaserCode}
          onFLaserCodeChange={updateFLaserCode}
          onRLaserCodeChange={updateRLaserCode}
          onSave={saveLaserCode}
        />
      )}
      {orderTimelineDetail && (
        <UpdateOrderTimeline
          status={"status"}
          show={showUpdateTimeline}
          onClose={handleClose}
          onTypeChange={timeLineTypeChange}
          onMessageChange={timeLineMessageChange}
          onSave={saveTimeLineChanges}
        />
      )}
      {showEmbossingStation && (
        <OrderAssignmentModal
          orderAssignmentOption={orderAssignmentOption}
          show={showEmbossingStation}
          onClose={handleClose}
          embossingStationId={embossingStation}
          onAssignmentChange={handleOrderAssignmentChanged}
          onSave={handleOrderAssignmentSave}
        />
      )}
      {showHDSPConfirmation && (
        <HSRPConfirmation
          show={showHDSPConfirmation}
          onClose={handleClose}
          onFixationDateChanged={onFixationDateChanged}
          payload={hsrpPayload}
          onHsrpSubmit={submitToHSRP}
        />
      )}
      {hsrpResponseModal && (
        <HSRPResponseModal
          show={hsrpResponseModal}
          onClose={handleClose}
          message={hsrpResponseMessage}
        />
      )}
      {showOrderPayment && (
        <OrderUpdatePayment
          show={showOrderPayment}
          onClose={handleClose}
          onStatusChange={handleUpdatePaymentChanged}
          onSave={handleUpdatePaymentStatusSave}
        />
      )}
      {showEventModal && (
        <EventsModal
          show={showEventModal}
          onClose={handleClose}
          orderTimelineDetail={orderTimelineDetail}
        />
      )}

      {showUploadImages && (
        <UploadImageModal
          show={showUploadImages}
          onClose={handleClose}
          orderId={orderId}
          refreshImage={getOrderImages}
        />
      )}

      {showEditVehcileDetails && (
        <VehicleDetailsModal
          show={showEditVehcileDetails}
          orderId={orderId}
          onClose={handleClose}
          vehicleDetail={vehicleDetail}
          orderDetail={orderDetail}
          refreshVehicleDetails={getVehicleDetails}
        />
      )}

      {showFixactionDate && (
        <FixactionDateModal
          show={showFixactionDate}
          onClose={handleClose}
          orderId={orderId}
          refreshHSRP={getVehicleHsrpDetails}
        />
      )}
      {showdeleteConfirm && (
        <DeleteModal
          show={showdeleteConfirm}
          onClose={handleClose}
          orderId={orderId}
          
        />
      )}
    </div>
  );
};

export default OrderDetail;
