import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

export default class LaserCodeModal extends Component {
  render() {
    const { show, onClose, fLaserCode, rLaserCode, onSave, onFLaserCodeChange, onRLaserCodeChange } = this.props;

    return (
      <div
        className={`modal fade ${show ? "show" : "hide"}`}
        style={{ display: show ? "block" : "none" }}
        id="modal-lg"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Laser Code</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={() => onClose()}>
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Front Laser Code</label>
                  <input
                    type="text"
                    name="text"
                    className="form-control"
                    onChange={e=>onFLaserCodeChange(e.target.value)}
                    placeholder="F Laser Code"
                    value={fLaserCode}
                  />
                </div>
                <div className="form-group">
                  <label>Rear Laser Code</label>
                  <input
                    type="text"
                    name="text"
                    className="form-control"
                    onChange={e=>onRLaserCodeChange(e.target.value)}
                    placeholder="R Laser Code"
                    value={rLaserCode}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={() => onClose()}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => onSave()}
              >
                Save changes
              </button>
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
    );
  }
}
