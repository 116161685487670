import React, { Component } from "react";
import { Link } from "react-router-dom";
import { listAllEmbossingStations } from "../../services/data-service/embossing-service";
import ContentHeader from "../../components/content-header/ContentHeader";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import { useEffect } from "react";
import { useState } from "react";
import MaterialTable from "@material-table/core";

export default function EmbosingStations(props) {
  const [ecData, setEcData] = useState([]);
  const [isEmcLoading, setIsEmcLoading] = useState(true)

  const columns = [
    { title: "#", render: (rowData) => rowData.tableData.id, width: 80 },
    {
      title: "UserName",
      field: "emc_name",
      width: 150,
      render: (rowData) => {
        return (
          <Link to="/emc-details" state={{ embossingStationId: rowData.id }} className="nav-link">
            <i className="fas fa-user nav-icon"></i> {rowData.emc_name}
          </Link>
        );
      },
    },
  ];

  useEffect(() => {
    listAllEmbossingStations()
      .then((response) => {
        setEcData(response.data);
        setIsEmcLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });
  },[]);
  return (
    <>
      <ContentHeader title="Embosing Stations" />

      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              {/* <h3 className="card-title">Embosing Stations</h3> */}
              <div className="card-tools">
                {localStorage.getItem("role") === "admin" && (
                  <Link
                    to="/add-embosing-station"
                    className="btn btn-outline-primary"
                  >
                    <i className="fas fa-user-plus"></i> Add
                  </Link>
                )}
              </div>
            </div>
            {/* <div className="card-body"> */}
              <MaterialTable columns={columns} data={ecData} isLoading={isEmcLoading} />
            {/* </div> */}
          </div>
        </div>
      </section>
    </>
  );
}
