import { API_URL, RAZOR_KEY } from "../../services/common/globalvars";
import axios from "axios";
import authHeader from "../../services/common/auth-header";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export async function displayRazorpay(payAmount) {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }
  console.log(payAmount);
  const result = await axios.post(
    `${API_URL}/payment/init`,
    { amount: payAmount },
    { headers: authHeader() }
  );

  if (!result) {
    alert("Server error. Are you online?");
    return;
  }

  const { amount, order_id, currency } = result.data;

  const options = {
    key: RAZOR_KEY,
    amount: amount,
    currency: "INR",
    name: "YaryaSekur",
    description: "Test Transaction",
    image: "/img/logo.png",
    order_id: order_id,
    handler: async function (response) {
      const data = {
        orderCreationId: order_id,
        razorpay_payment_id: response.razorpay_payment_id,
        razorpay_order_id: response.razorpay_order_id,
        razorpay_signature: response.razorpay_signature,
      };

      const result = await axios.post(`${API_URL}/payment/success`, data);
      if (result){
        window.location.reload();
      }
      // alert(result.data.msg);
    },
    prefill: {
      name: "Dealer Name",
      email: "Dealer Email",
      contact: "9999999999",
    },
    theme: {
      color: "#61dafb",
    },
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
}
