import http from "../common/http-common";

export async function getAllDealers() {
  return await http.get("/b2b/dealers");
}

export async function getDealersByOem(id) {
  return await http.get(`/b2b/oem/${id}/dealers`);
}

export async function addNewDealer(data) {
  return await http.post("/b2b/dealers/create", data);
}

export async function getDealerDetails(id) {
  return await http.get(`/b2b/dealers/${id}`);
}

export function getDealerOrders(id) {
  return http.get(`/dealers/${id}/orders`);
}

export async function getDealerWallet(id) {
  return await http.get(`/b2b/dealers/${id}/wallet`);
}

export function updateDealerWalletBalance(id, data) {
  return http.put(`/dealers/${id}/wallet/balance`, data);
}

export async function getDealerPostPaid(id) {
  return await http.get(`/dealers/${id}/postpaid/due`);
}

export async function generateInvoice(id) {
  return await http.get(`b2b/dealers/${id}/invoice/generate`);
}

export async function getDealerInvoice(id) {
  return await http.get(`/b2b/dealers/${id}/invoices`);
}

export async function getDealerWalletHistory(id) {
  return await http.get(`/payment/transaction/${id}/history`)
}


// Dealer Offline Payment
export async function PostDealerOfflinePayment(data) {
  return await http.post("/payment/transaction/offline", data);
}

export async function approveDelaerOfflinePayment(id){
  return await http.put(`/payment/transaction/offline/${id}/approve`);
}
export async function rejectDelaerOfflinePayment(id){
  return await http.put(`/payment/transaction/offline/${id}/reject`);
}

//dealers order stastics
export async function getDealerOrderStastics(){
  return await http.get("/b2b/dashboard/orders/stastics")
}

//download offlinepayment
export async function downloadOfflinePayment(id){  
  return await http.get(`/payment/transaction/offline/${id}/reference `,{
    responseType: "blob",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
}
// /payment/transaction/offline/<int:trans_id>/reference 


// export const PostDealerOfflinePayment = async (url="", optionsObj=null, errMsg = null)=>{
//   try{
//     const response = await fetch(url,optionsObj)
//     if(! response.ok) throw Error("Please Reload the app")
//   }catch(err){
//     errMsg = err.Message
//   }finally{
//     return errMsg
//   }
// }