import { jwtDecode } from "jwt-decode";
import { v4 as uuid } from "uuid";
// import {sha512} from 'crypto-hash';

export const isLogin = () => {
  // console.log("isLogin");
  const token = localStorage.getItem("token");
  if (token) {
    const { userid, user, role } = jwtDecode(token);
    if (user) {
      return true;
    }
    return false;
  }
  // if (localStorage.getItem("user")) {
  //   //   const user = JSON.parse(localStorage.getItem("user"));
  //   const user = localStorage.getItem("user");
  //   console.log(user);
  //   return true;
  // }
  return false;
};

export const zeroPad = (num, places) => String(num).padStart(places, "0");
export const spacePad = (item, count) => String(item).padStart(count, " ");

export const timestampToDate = (timestamp) => {
  return new Date(timestamp).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

export const getRandom3 = () => Math.floor(Math.random() * (999 - 10) + 10);

export const useAuth = () => {
  const token = localStorage.getItem("token");

  if (token) {
    try {
      const { userid, user, role, refid, name } = jwtDecode(token);
      // const { userid, user, role } = decoded
      return { userid, user, role, refid, name };
    } catch (err) {
      localStorage.removeItem('token')
      localStorage.removeItem('authentication')
      window.location = "/"
      // return { userid: "", user: "", role: "" };
    }
  }

  return { userid: "", user: "", role: "" };
};

export const getAuth = () => {
  const token = localStorage.getItem("token");

  if (token) {
    const { userid, user, role, refid, name } = jwtDecode(token);
    // const { userid, user, role } = decoded
    return { userid, user, role, refid, name };
  }

  return { userid: "", user: "", role: "" };
};

export const getUniqTxnId = () => {
  const unique_id = uuid();
  const small_id = unique_id.slice(0, 10);
  return unique_id;
};

// export const convertToSHA512 = (str) => {
//   return sha512(str)
// }
