import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { listAllEmbossingStations } from "../../services/data-service/embossing-service";

export default class OrderAssignmentModal extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     embosingStationsOption: [],
  //   };
  // }
  // async componentDidMount() {
  //   let optionData = [];
  //   await listAllEmbossingStations().then((response) => {
  //     console.log(response.data)
  //     response.data.map((item) =>
  //       optionData.push({ value: item.id, label: item.name })
  //     );
  //     this.setState({ embosingStationsOption: optionData });

  //   });

  // }
  render() {
    const { show, onClose, embossingStationId, status, onSave, onAssignmentChange, orderAssignmentOption } =
      this.props;
    // const { embosingStationsOption } = this.state;
    let index = -1;
      const filteredOptions = orderAssignmentOption.find(function (item, i) {
        console.log(item.value, embossingStationId)
        if (item.value === embossingStationId) {
          index = i;
          return i;
        }
      });
      console.log(orderAssignmentOption[index])

    return (
      <div
        className={`modal fade ${show ? "show" : "hide"}`}
        style={{ display: show ? "block" : "none" }}
        id="modal-lg"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Assigned To Embossing Station</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={() => onClose()}>
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-sm-6">
                {/* select */}
                <div className="form-group">
                  <label>Embossing Station</label>
                  {orderAssignmentOption && (
                    <Select
                    defaultValue={orderAssignmentOption[index]}
                    options={orderAssignmentOption}
                    onChange={(e) => onAssignmentChange(e.value)}
                  />
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => onSave()}
              >
                Save changes
              </button>
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
    );
  }
}
