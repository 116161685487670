import ContentHeader from "../../components/content-header/ContentHeader";
import { toast } from "react-toastify";
import MaterialTable from "@material-table/core";
import { getAuth } from "../../services/common/utils";
import React,{useState, useEffect} from "react";
import OrderStatusModal from "../../components/modals/master/OrderStatusModal";
import {
  getAllOrderStatus,
  addNewOrderStatus,
  updateOrderStatus,
  deleteOrderStatus,
} from "../../services/data-service/order-service";

const OrderStatus = () => {
  const[isLoading, setIsLoading] = useState(true)
  const[showOrderStatusModal, setShowOrderStatusModal] = useState(false)
  const[orderStatus, setOrderStatus] = useState({
    status: [],
    orderStatus: "",
    showOrderStatusModal: false,
    currentValueFlag: false,
    currentId: "",
    currentValue: "",
  })
 
  const { 
    status, 
    currentValue, 
    currentValueFlag 
  } =  orderStatus;
 

  function onShowOrderStatusModal() {
    setShowOrderStatusModal(true)
  }

  function onOrderStatusChange(vClass) {
    setOrderStatus({...orderStatus, orderStatus: vClass });
  }

  const handleEdit = (id, vClass) => {
    setOrderStatus({
      ...orderStatus,
      currentId: id,
      currentValue: vClass,
      currentValueFlag: true,      
    });
    setShowOrderStatusModal(true)
  };

  async function handleSave() {
    console.log(orderStatus.orderStatus);
    const payload = {
      status: orderStatus.orderStatus,
    };

    await addNewOrderStatus(payload).then((res) => {
      toast.success("New Order Status added sucessfully!");
    });
    handleClose();
    await init();
  }

  async function handleDelete(id) {
    await deleteOrderStatus(id).then((res) => {
      toast.info( "Order Status deleted!");
    });
    init();
  }

  async function handleUpdate() {
    const payload = {
      status: orderStatus.orderStatus,
    };

    await updateOrderStatus(orderStatus.currentId, payload).then((res) => {
      toast.success("Order Status Updated!");
    });
    handleClose();
    init();
  }

  function handleClose() {
    setOrderStatus({
      ...orderStatus,
      currentValueFlag: false,
      currentValue: "",
    });
    setShowOrderStatusModal(false)
  }

  async function init() {
    await getAllOrderStatus().then((response) => {
      setOrderStatus({ status: response.data });
    });
  }

  useEffect(()=>{
    init()
    .then((res)=>{
      console.log(res.data)
    })
    .catch((err)=>{
      console.log(err)
    })
    setIsLoading(false)
  },[])

  const columns = [
    {title:'No',field:'id'},
    {title:'Type',field:'status'},
    {
      title:'Action',
      render: (rowData)=>{
        return(
          <div className="btn-group btn-group-sm">
            <button
              className="btn btn-info"
              onClick={() =>
                handleEdit(rowData.id, rowData.status)
              }
            >
              <i className="fas fa-edit"></i>
            </button>
            {/* <button
              className="btn btn-danger"
              onClick={() => handleDelete(rowData.id)}
            >
              <i className="fas fa-trash"></i>
            </button> */}
          </div>
        )
      }
    }
   
  ]

  const { role } = getAuth()

    return (
      <div>
        <ContentHeader title="Blank Page" />
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Order Status</h3>
                    <div className="card-tools">
                      {role === "admin" && (
                        <button
                          className="btn btn-outline-primary"
                          onClick={onShowOrderStatusModal}
                        >
                          <i className="fas fa-user-plus"></i> Add
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="card-body">
                    <MaterialTable
                      title={''}
                      data={orderStatus.status}
                      columns={columns}                      
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {showOrderStatusModal && (
          <OrderStatusModal
            onStatusChange={onOrderStatusChange}
            currentValue={currentValueFlag ? currentValue : ""}
            show={showOrderStatusModal}
            currentValueFlag={currentValueFlag}
            onClose={handleClose}
            onSave={handleSave}
            onUpdate={handleUpdate}
          />
        )}
      </div>
    );
  };
  
  export default OrderStatus;
  