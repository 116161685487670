// export const API_URL = "http://localhost:5000";

// Prod
// export const API_URL = "https://dakpq46dbi.execute-api.ap-south-1.amazonaws.com/prod";
// export const API_URL = "https://prod.hsrp.szvp2024.com/";
// export const RAZOR_KEY = "rzp_live_MRkwmUCztyXFkG"

// Dev
// export const API_URL = "https://svu3grwyrl.execute-api.ap-south-1.amazonaws.com/dev";
export const API_URL = "https://dev.hsrp.szvp2024.com";
export const RAZOR_KEY = "rzp_test_4UJo7VX37nj3si"

// export const API_URL = "https://api-portal.hsrp.fit";
// export const VAHAN_API_URL = "https://208.109.14.243:8800";
