import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAllDealers } from "../../services/data-service/dealer-service";
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import ContentHeader from "../../components/content-header/ContentHeader";

export default function Dealers(props) {
  const [dealerList, setDealerList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    { title: "#", render: (rowData) => rowData.tableData.id, width: 80 },
    {
      title: "User Id",
      field: "code",
      width: 150,
      render: (rowData) => {
        return (
          <Link
            to={`/dealer-details/${rowData.id}`}            
            className="nav-link"
          >
            <i className="fas fa-user nav-icon"></i> {rowData.code}
          </Link>
        );
      },
    },
    { title: "Name", field: "dealer_name" },
    { title: "Mobile", field: "dealer_mobile" },
    { title: "Email", field: "dealer_email" },
    { title: "GST No.", field: "gst_no" },
    {
      title: "OEM",
      field: "oem",
      width: 150,
      render: (rowData) => {
        return (
          <Link
            to="/oem-details"
            state={{ oemId: rowData.id }}
            className="nav-link"
          >
            <i className="fas fa-user nav-icon"></i> {rowData.oem}
          </Link>
        );
      },
    },
  ];

  useEffect(() => {
    getAllDealers().then((response) => {
      setDealerList(response.data);
      setIsLoading(false);
    });
  }, []);
  return (
    <>
      <ContentHeader title="Dealers" />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            {/* <div className="card-header">
                <h3 className="card-title">Orders</h3>
              </div> */}
            <MaterialTable
              columns={columns}
              isLoading={isLoading}
              data={dealerList}
              title=""
              options={{
                exportAllData: true,
                exportMenu: [
                  {
                    label: "Export PDF",
                    exportFunc: (cols, datas) => ExportPdf(cols, datas, "oems"),
                  },
                  {
                    label: "Export CSV",
                    exportFunc: (cols, datas) => ExportCsv(cols, datas, "oems"),
                  },
                ],
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
}
