import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { setAuthentication } from "../../store/reducers/auth";
import { setWindowClass } from "../../utils/helpers";
import * as Yup from "yup";

import { authLogin } from "../../utils/oidc-providers";
import { Form, InputGroup } from "react-bootstrap";
import { doLogin } from "../../services/data/auth";
import { jwtDecode } from "jwt-decode";

import login_img from "../../image/Trux_login_img.png";
import "../../styles/Login.css";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";

const Login = () => {  
  const backgroundStyle = {  
    backgroundColor:'white', 
    backgroundImage: "url('img/Yarya Sekur-02.svg')",
    height:"100%",
    width:"100%",       
    backgroundRepeat: "no-repeat",    
  };
  const [isAuthLoading, setAuthLoading] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [isLoading, setIsLoading] =useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function onChangeCaptcha(e) {
    setCaptcha(e.target.value);    
  }
  
  const login = async (email, password) => {
    console.log("Logging in...");
    localStorage.removeItem("token");
    localStorage.removeItem("authentication");
    const payload = {
      username: email,
      password: password,
    };
    await doLogin(payload)
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        const { user, role, userid, refid } = jwtDecode(response.data.token);
        localStorage.setItem(
          "authentication",
          JSON.stringify({ email: user, role, userid, refid })
        );
        toast.success("Login is succeed!");
        navigate(0);
      })
      .catch((error) => {
        toast.error("Login failed!");
        setAuthLoading(false);
      });

    // try {
    //   setAuthLoading(true);
    //   await doLogin(email, password).then((response) => {
    //     // dispatch(setAuthentication(response));
    //     if (response) {
    //       toast.success("Login is succeed!");
    //       setAuthLoading(false);
    //       // dispatch(loginUser(token));
    //       navigate("/");
    //     } else {
    //       toast.error("Login failed!");
    //       setAuthLoading(false);
    //       navigate("/");
    //     }
    //   });
    // } catch (error) {
    //   setAuthLoading(false);
    //   toast.error(error.message || "Failed");
    // }
  };

  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      // email: Yup.string().email("Invalid email address").required("Required"),
      // password: Yup.string()
      //   .min(5, "Must be 5 characters or more")
      //   .max(30, "Must be 30 characters or less")
      //   .required("Required"),
    }),
    onSubmit: (values) => {      
      if (validateCaptcha(captcha) === true) {
        setIsLoading(true);
        login(values.email, values.password);
      }      
      else {               
        setIsLoading(false);
        values.password = ''      
        setCaptcha("");
        toast.error("Captcha not matched!!!");
        loadCaptchaEnginge(6, "#EFEFEF", "#676767");
      }
    },
  });
  setWindowClass("hold-transition login-page");
  useEffect(() => {
    loadCaptchaEnginge(6, "#EFEFEF", "#676767");
  },[]);

  return (
    <>
    <div style={backgroundStyle} className="d-flex flex-column justify-content-center maincontainer"> 
      <div className="container-fluid">
        <div className="row">
          {/* <div className="col-6 d-none d-md-block">
            <img src={login_img} className="userLogin-img" />
          </div> */}
          <div className="col-8">            
            <div className="login-box login-page-container">
              <div className="card card-outline" style={{borderRadius:"15px",boxShadow: "8px 8px 4px rgba(0, 0, 0, 0.1)"}}>
                <div className="card-header text-center">
                  <p style={{ marginBottom: "0px", fontWeight:"bold", color:"#40403a", fontSize:"30px",fontFamily:"Roboto"}}>
                    User Login
                  </p>
                  {/* <Link to="/" className="h1">
                    <b>User Login</b>
                  </Link> */}
                </div>
                <div className="card-body">
                  <p className="login-box-msg">Sign In</p>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <InputGroup className="mb-3">
                        <Form.Control
                          id="username"
                          name="email"
                          type="text"
                          placeholder="username"
                          onChange={handleChange}
                          value={values.email}
                          isValid={touched.email && !errors.email}
                          isInvalid={touched.email && !!errors.email}
                        />
                        {touched.email && errors.email ? (
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        ) : (
                          <InputGroup.Append>
                            <InputGroup.Text>
                              <i className="fas fa-envelope" />
                            </InputGroup.Text>
                          </InputGroup.Append>
                        )}
                      </InputGroup>
                    </div>
                    <div className="mb-3">
                      <InputGroup className="mb-3">
                        <Form.Control
                          id="password"
                          name="password"
                          type="password"
                          placeholder="Password"
                          onChange={handleChange}
                          value={values.password}
                          isValid={touched.password && !errors.password}
                          isInvalid={touched.password && !!errors.password}
                        />
                        {touched.password && errors.password ? (
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        ) : (
                          <InputGroup.Append>
                            <InputGroup.Text>
                              <i className="fas fa-lock" />
                            </InputGroup.Text>
                          </InputGroup.Append>
                        )}
                      </InputGroup>
                    </div>
                    <div className="input-group mb-3">
                      <LoadCanvasTemplate />                   
                    </div>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Captcha"
                        value={captcha}
                        onChange={onChangeCaptcha}
                      />
                    </div>

                    <div className="row">                    
                      <div className="col-8">
                        <p>
                          <div style={{cursor: "pointer", color: "#0000EE"}} onClick={(e) => toast.info("Contact support info@yaryasekur.com")}>I forgot my password</div>
                        </p>                       
                      </div>
                      <div className="col-4">
                          <button
                            type="submit"
                            loading={isAuthLoading}
                            className="btn btn-danger btn-block btn-sm"
                            onClick={handleSubmit}
                          >                                                        
                            Sign In
                          </button>
                      </div>                   
                    </div>
                  </form>                                   
                </div>
              </div>
            </div>                        
          </div>
          <div className="col-4 d-none d-xl-block">
            <p className="mr-auto image-container"style={{position:"relative",right:"100px", top:'32%' }} >
              <img src="img/Yarya Sekur V3-05.svg" height="459px" />
            </p>
          </div>
        </div>
      </div>      
    </div>
      
    </>
  );
};

export default Login;
