export default function authHeader() {
  const jwtToken = localStorage.getItem('token');
  if (jwtToken) {
    return {
      "Content-type": "application/json",
      Authorization: "Bearer " + localStorage.getItem('token'),
    };
  } else {
    return {
      "Content-type": "application/json"
    };
  }
}
