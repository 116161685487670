import ContentHeader from "../../components/content-header/ContentHeader";
import { toast } from "react-toastify";
import MaterialTable from "@material-table/core";
import { getAuth } from "../../services/common/utils";
import React, {useState, useEffect} from "react";
import PlateClassModal from "../../components/modals/master/PlateClassModal";
import {
  getAllPlateClass,
  addNewPlateClass,
  updatePlateClass,
  deletePlateClass,
} from "../../services/data-service/master-service";


const PlateClass = () => {
  const[isLoading, setIsLoading] = useState(true)
  const[showPlateClassModal, setShowPlateClassModal] = useState(false)
  const[plateClass, setPlateClass] = useState({
    status: [],
    plateClass: "",
    showPlateClassModal: false,
    currentValueFlag: false,
    currentId: "",
    currentValue: "",
  })
  
  const { 
    status, 
    currentValue, 
    currentValueFlag } = plateClass  

  function onShowPlateClassModal() {
    setShowPlateClassModal(true)
  }

  function onPlateClassChange(pClass) {
    setPlateClass({...plateClass, plateClass: pClass });
  }

  const handleEdit = (id, pClass) => {
    setPlateClass({...plateClass,
      currentId: id,
      currentValue: pClass,
      currentValueFlag: true,
    });
    setShowPlateClassModal(true)
  };

  async function handleSave() {
    console.log(plateClass.plateClass);
    const payload = {
      class: plateClass.plateClass,
    };

    await addNewPlateClass(payload).then((res) => {
      toast.success("New Order Status added sucessfully!");
    });
    handleClose();
    await init();
  }

  async function handleDelete(id) {
    await deletePlateClass(id).then((res) => {
      toast.info("Order Status deleted!");
    });
    init();
  }

  async function handleUpdate() {
    const payload = {
      class: plateClass.plateClass,
    };

    await updatePlateClass(plateClass.currentId, payload).then((res) => {
      toast.success("Order Status Updated!");
    });
    handleClose();
    init();
  }

  function handleClose() {
    setPlateClass({...plateClass,
      currentValueFlag: false,
      currentValue: "",
    });
    setShowPlateClassModal(false)
  }

  async function init() {
    await getAllPlateClass().then((response) => {
      setPlateClass({...plateClass, status: response.data });
    });
  }

  useEffect(()=>{
    init()
    .then((res)=>{
      console.log(res.data)
    })
    .catch((err)=>{
      console.log(err)
    })
    setIsLoading(false)
  },[])
  
  const columns = [
    {title:'No',field:'id'},
    {title:'Type',field:'code'},
    {
      title:'Action',
      render: (rowData)=>{
        return(
          <div className="btn-group btn-group-sm">
            <button
              className="btn btn-info"
              onClick={() =>
                handleEdit(rowData.id, rowData.code)
              }
            >
              <i className="fas fa-edit"></i>
            </button>
            {/* <button
              className="btn btn-danger"
              onClick={() => handleDelete(rowData.id)}
            >
              <i className="fas fa-trash"></i>
            </button> */}
          </div>
        )
      }
    },
  ]
  const { role } = getAuth()

    return (
      <div>
        <ContentHeader title="Plate Status" />
        <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Plate Status</h3>
                      <div className="card-tools">
                        {role === "admin" && (
                          <button
                            className="btn btn-outline-primary"
                            onClick={onShowPlateClassModal}
                          >
                            <i className="fas fa-user-plus"></i> Add
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="card-body p-0">
                      <MaterialTable
                        title={''}
                        data={plateClass.status}
                        columns={columns}                      
                        isLoading={isLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {showPlateClassModal && (
          <PlateClassModal
            onPlateClassChange={onPlateClassChange}
            currentValue={currentValueFlag ? currentValue : ""}
            show={showPlateClassModal}
            currentValueFlag={currentValueFlag}
            onClose={handleClose}
            onSave={handleSave}
            onUpdate={handleUpdate}
          />
        )}
      </div>
    );
  };
  
  export default PlateClass;
  