import React, {  } from "react";
import AdminDashboard from "./AdminDashboard";
import { useAuth } from "../services/common/utils";
import DealerDashboard from "./dealers/DealerDashboard";

const Dashboard = () => {
  const { userid, user, role, refid } = useAuth();
  return (
    <div>
      {/* <ContentHeader title="Dashboard" /> */}
      {/* {isPageLoading ? (
        <section className="content preloader justify-content-center align-items-center">
          <Spinner animation="grow" className="bg-info" />
          <Spinner animation="grow" className="bg-primary" />
          <Spinner animation="grow" className="bg-success" />
        </section>
      ) : ( */}
      <section className="content">
         {role === "admin" && <AdminDashboard />}
         {role !== "admin" && <DealerDashboard />}
      </section>
      {/* )} */}
    </div>
  );
};

export default Dashboard;
