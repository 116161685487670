import React from 'react';
import {Text, View, StyleSheet  } from '@react-pdf/renderer';

const borderColor = '#000'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        // borderBottomColor: '#bff0fd',
        // borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        // fontSize: 12,
        fontStyle: 'bold',
    },
    description: {
        width: '85%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    total: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
  });


const InvoiceTableFooter = ({invoice_bill}) => {
    return(    
        <View>
            <View style={styles.row}>
                <Text style={styles.description}>Basic</Text>
                <Text style={styles.total}>{invoice_bill.basic}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description}>CGST({invoice_bill.cgst}%)</Text>
                <Text style={styles.total}>{invoice_bill.cgst_amount}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description}>SGST({invoice_bill.sgst}%)</Text>
                <Text style={styles.total}>{invoice_bill.sgst_amount}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description}>Net</Text>
                <Text style={styles.total}>{invoice_bill.net_amount}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description}>Round Off</Text>
                <Text style={styles.total}>{invoice_bill.rountoff}</Text>
            </View>
            
        </View>
        
        
    )
};
  
  export default InvoiceTableFooter