import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

export default function UpdateOrderTimeline(props) {
    const { show, onClose, onSave, onTypeChange, onMessageChange } = props;

    const[templateMessage, setTemplateMessage] = useState("")
    const options = [
      { value: 'phone', label: 'Call' },
      { value: 'email', label: 'Email' },
      { value: 'note', label: 'Note' },
      { value: 'check', label: 'Check' },
    ]

    const onTemplateChanged = (message) => {
      setTemplateMessage(message)
    }    
    const templateOptions = [
      { value: 'Order Dispatch to \nName: \nMobile: ', label: 'Order Dispatched' },      
      { value: 'Order Received at \nName: \nMobile: ', label: 'Order Received' },
    ]

    return (
      <div
        className={`modal fade ${show ? "show" : "hide"}`}
        style={{ display: show ? "block" : "none" }}
        id="modal-lg"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Message</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={() => onClose()}>
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-sm-12">
                {/* select */}
                <div className="form-group">
                  <label>Type</label>
                  <Select options={options} onChange={e=>onTypeChange(e.value)}/>
                </div>
                <div className="form-group">
                  <label>Template</label>
                  <Select options={templateOptions} onChange={e=>onTemplateChanged(e.value)}/>
                </div>
                <div className="form-group">
                  <label>Message</label>
                  <textarea className="form-control" rows="6" placeholder="Enter ..." defaultValue={templateMessage} onChange={e=>onMessageChange(e.target.value)}></textarea>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={() => onClose()}
              >
                Close
              </button>
              <button type="button" className="btn btn-primary" onClick={() => onSave()}>
                Save changes
              </button>
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
  )
}
