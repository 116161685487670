import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

function getTimelineIcon(type) {
  switch (type) {
    case "email":
      return "fas fa-envelope bg-blue";
    case "call":
      return "fas fa-phone bg-warning";
    case "note":
      return "fas fa-sticky-note bg-info";
    case "check":
      return "fas fa-check bg-success";
    default:
      return "fas fa-comment bg-warning";
  }
}

export default function EventsModal(props) {
  return (
    <div
      className={`modal fade ${props.show ? "show" : "hide"}`}
      style={{ display: props.show ? "block" : "none" }}
      // id="modal-xl"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              <i className="fas fa-calendar-alt"></i> Events
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => props.onClose()}>
                ×
              </span>
            </button>
          </div>
          <div className="modal-body">
            <div className="col-sm-12">
              {!props.orderTimelineDetail ? (
                <div className="overlay">
                  <i className="fas fa-2x fa-sync fa-spin"></i>
                </div>
              ) : (
                <div className="timeline " style={{ width: "100%" }}>
                  {/* timeline time label */}
                  {props.orderTimelineDetail &&
                    props.orderTimelineDetail.map((timeline) => (
                      <>
                        <div className="time-label">
                          <span className="bg-info">
                            {new Date(timeline.createdAt).toLocaleDateString(
                              "en-US"
                            )}
                          </span>
                        </div>
                        <div>
                          <i className={getTimelineIcon(timeline.type)} />
                          <div className="timeline-item">
                            <span className="time">
                              <i className="fas fa-clock" />
                              {new Date(timeline.createdAt).toLocaleTimeString(
                                "en-US"
                              )}
                            </span>
                            <h3 className="timeline-header">
                              <a href="#">{timeline.updatedBy}</a>
                            </h3>
                            <div className="timeline-body">
                              {timeline.message}
                            </div>
                          </div>
                        </div>
                      </>
                    ))}

                  {/* Start */}
                  <div>
                    <i className="fas fa-clock bg-gray" />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              onClick={() => props.onClose()}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
