export function OrderConfirmation(props) {
    const { show, onClose, onConfirm } = props
    return (
        <div
          className={`modal fade ${show ? "show" : "hide"}`}
          style={{ display: show ? "block" : "none" }}
          id="modal-sm"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Confirm Order!</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" onClick={(e) => onClose()}>
                    ×
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="col-12">
                <button
                  type="button"
                  className="btn btn-app bg-danger"
                  data-dismiss="modal"
                  onClick={(e) => onClose()}
                >
                  <i className="fas fa-window-close"></i> Cancel
                
                </button>
                <button
                  type="button"
                  className="btn btn-app bg-success float-right"
                  onClick={(e) => onConfirm()}
                >
                  <i className="fas fa-check"></i> Confirm
                
                </button>
                </div>
              </div>
              {/* <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                  onClick={() => onClose()}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => onSave()}
                >
                  Save changes
                </button>
              </div> */}
            </div>
          </div>
        </div>
      );
}