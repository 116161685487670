import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { OrderConfirmation } from "./OrderConfirmation";
import {
  getAllFuelTypes,
  getAllPlateDimension,
  getAllVehicleClass,
  getAllPlateClass,
} from "../../services/data-service/master-service";
import {
  getOemVehicleTypes,
  getOemVehicleTypeDetail,
} from "../../services/data-service/oem-service";
import {
  addNewOrder,
} from "../../services/data-service/order-service";
// import { getHsrpVehicleDetails } from "../../services/data-service/vahan-service";
import {
  getDealerDetails,
  getDealerWallet,
} from "../../services/data-service/dealer-service";
import { useAuth } from "../../services/common/utils";

import ContentHeader from "../../components/content-header/ContentHeader";
import { toast } from "react-toastify";


const orderTypeOptions = [
  { value: 1, label: "New Board" },
  { value: 2, label: "Old Board" },
];

export default function RegisterNewOrder(props) {

  const navigate = useNavigate();

  const { user, role, userid, refid } = useAuth();
  const [dealerDetail, setDealerDetail] = useState(null);
  const [dealerWallet, setDealerWallet] = useState(null);
  const [fuelTypeOptions, setFuelTypeOptions] = useState(null);
  const [vehicleTypeDetail, setVehicleTypeDetail] = useState(null);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState(null);
  const [vehicleClassOptions, setVehicleClassOptions] = useState(null);
  const [plateDimOptions, setPlateDimOptions] = useState(null);
  const [plateColorOption, setPlateColorOption] = useState(null);
  const [rate, setRate] = useState(null);
  const [orderPayload, setOrderPayload] = useState(null);
  const [canMakeOrder, setCanMakeOrder] = useState(true);

  const [disableEngine, setDisableEngine] = useState(false);
  const [orderType, setOrderType] = useState(null);
  const [fuelType, setFuelType] = useState(null);
  const [plateColor, setPlateColor] = useState(null);
  const [vehicleClass, setVehicleClass] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showOrderConfimrationModal, setShowOrderConfimrationModal] =
    useState(false);
  const onSubmit = (data) => console.log(data);

  useEffect(() => {
    if (dealerDetail) {
      getOemVehicleTypes(dealerDetail["oem_id"]).then((response) => {
        let optionData = [];
        response.data.map((item) =>
          optionData.push({ value: item.id, label: item.vehicle_type })
        );
        setVehicleTypeOptions(optionData);
      });
      // getOemRate(dealerDetail["oem_id"]).then((response) => {
      //   setRate(response.data);
      // });
    }
  }, [dealerDetail]);

  useEffect(() => {
    if (refid) {
      getDealerDetails(refid).then((response) => {
        setDealerDetail(response.data);
      });
      getDealerWallet(refid).then((response) => {
        setDealerWallet(response.data.amount);
      });
    }

    getAllFuelTypes().then((response) => {
      let optionData = [];
      response.data.map((item) =>
        optionData.push({ value: item.id, label: item.fuel_type })
      );

      setFuelTypeOptions(optionData);
    });
    // getAllVehicleTypes().then((response) => {
    //   let optionData = [];
    //   response.data.map((item) =>
    //     optionData.push({ value: item.id, label: item.vehicle_type })
    //   );
    //   setVehicleTypeOptions(optionData);
    // });
    getAllVehicleClass().then((response) => {
      let optionData = [];
      response.data.map((item) =>
        optionData.push({ value: item.id, label: item.vehicle_class })
      );
      setVehicleClassOptions(optionData);
    });

    getAllPlateDimension().then((response) => {
      let optionData = [];
      response.data.map((item) =>
        optionData.push({ value: item.id, label: item.code })
      );
      setPlateDimOptions(optionData);
    });
    getAllPlateClass().then((response) => {
      let optionData = [];
      response.data.map((item) =>
        optionData.push({ value: item.id, label: item.color })
      );
      setPlateColorOption(optionData);
      setIsLoading(false);
    });

    return () => {
      let allsuspects = document.getElementsByTagName("script");
      for (let i = allsuspects.length; i >= 0; i--) {
        if (
          allsuspects[i] &&
          allsuspects[i].getAttribute("src") !== null &&
          allsuspects[i]
            .getAttribute("src")
            .indexOf("dist/js/formValidation.js") !== -1
        ) {
          allsuspects[i].parentNode.removeChild(allsuspects[i]);
        }
      }
    };
  }, [refid]);

  async function onVehicleTypeChange(type) {
    getOemVehicleTypeDetail(dealerDetail["oem_id"], type).then((response) => {
      if (response.data.vehicle_type.includes("Trailer")) {
        setDisableEngine(true);
      }

      console.log(dealerWallet);
      setVehicleTypeDetail(response.data);
      const bal =
        parseInt(dealerWallet) - parseInt(response.data.roundoff_amount);
      console.log(bal);

      if (dealerDetail["payment_type"] === "PREPAID" && bal < 0) {
        setCanMakeOrder(false);
        toast.error("You dont have enough balance to make an order");
        toast.error("Your wallet balance is INR:" + parseInt(dealerWallet));
      }
    });
  }

  function onOrderTypeChange(orderType) {
    console.log(orderType);
    setOrderType(orderType);
  }

  function onFuelTypeChange(fuelType) {
    setFuelType(fuelType);
  }

  function onPlateColorChange(color) {
    setPlateColor(color);
  }

  function onVehicleClassChange(vclass) {
    console.log(vclass);
    setVehicleClass(vclass);
  }

  function handleNewOrder(event) {
    event.preventDefault();
    const data = event.target.elements;
    if (vehicleClass === null) {
      toast.error("Select Vehicle Class");
    } else if (vehicleTypeDetail === null) {
      toast.error("Select Vehicle Type");
    } else if (orderType === null) {
      toast.error("Select Order Type");
    } else if (fuelType === null) {
      toast.error("Select Fuel Type");
    } else if (plateColor === null) {
      toast.error("Select Plate Color");
    } else {
      const vehicle = {
        reg_no: data.regNo.value,
        chassis_no: data.chasisNo.value,
        engine_no: data.engineNo.value,
        fuel_type: fuelType,
        vehicle_class: vehicleClass,
        vehicle_model: data.model.value,
        vehicle_type: vehicleTypeDetail.id,
        oem: dealerDetail["oem_id"],
        owner_name: data.ownerName.value,
        plate_color: plateColor,
        reg_date: data.registrationdate.value,
        man_date: data.manDate.value,
      };

      // const hsrp = {
      //   front_laser_code: "",
      //   rear_laser_code: "",
      //   fixation_date: "",
      //   vahan_status: null,
      // };

      // const payment = {
      //   basic_amount: vehicleTypeDetail.basic_amount,
      //   cgst: vehicleTypeDetail.cgst,
      //   cgst_amount: vehicleTypeDetail.cgst_amount,
      //   sgst: vehicleTypeDetail.sgst,
      //   sgst_amount: vehicleTypeDetail.sgst_amount,
      //   net_amount: vehicleTypeDetail.net_amount,
      //   roundoff_amount: vehicleTypeDetail.roundoff_amount,
      // };

      const payload = {
        order_type: orderType,
        status: "NEWORDER",
        vehicle: vehicle,
        // hsrp: hsrp,
        // payment: payment,
      };

      console.log(payload);
      setOrderPayload(payload);
      setShowOrderConfimrationModal(true);
    }
  }

  async function placeOrder() {
    setShowOrderConfimrationModal(false);
    setIsLoading(true);
    await addNewOrder(orderPayload)
      .then((response) => {
        console.log(response.status);
        if (response.status == 200) {
          toast.success("Order Added Successfully!");          
          navigate("/orders");
        } else {
          toast.error("Order Not placed!! Try again later");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong, try again later!!");
        setIsLoading(false);
      });
  }

  function handleClose() {
    setShowOrderConfimrationModal(false);
  }

  return (
    <>
      <ContentHeader title="New Orders" />
      <section className="content">
        <form
          id="registerOrderForm"
          // noValidate="novalidate"
          onSubmit={handleNewOrder}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  {isLoading && (
                    <div className="overlay">
                      <i className="fas fa-2x fa-sync fa-spin" />
                    </div>
                  )}

                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Vehicle Registration No</label>
                          <input
                            type="text"
                            name="veg_reg_no"
                            className="form-control"
                            id="regNo"
                            placeholder="Vehicle Registration No."
                            // onChange={this.onRegNoChange}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="">Chassis No</label>
                          <input
                            type="text"
                            name="chassis_no"
                            className="form-control"
                            id="chasisNo"
                            placeholder="Chassis No."
                            // onChange={this.onchasisNoChange}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="">Engine No</label>
                          <input
                            type="text"
                            name="engine_no"
                            className="form-control"
                            id="engineNo"
                            placeholder="Engine No."
                            disabled={disableEngine}
                            // onChange={this.onEngineNoChange}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="">Vehicle Model</label>
                          <input
                            type="text"
                            name="vehicle_model"
                            className="form-control"
                            id="model"
                            placeholder="Vehicle Model"
                            // disabled={disableOrder}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="">Vehicle Class</label>
                          <Select
                            options={vehicleClassOptions}
                            // isDisabled={disableOrder}
                            name="vehicle_class"
                            onChange={(e) => onVehicleClassChange(e.value)}
                            // value={vehicleClassOptions.filter(
                            //   ({ value }) =>
                            //     value === this.state.vehicleCategory
                            // )}
                          />
                          {/* )} */}
                        </div>
                        <div className="form-group">
                          <label htmlFor="">Vehicle Type</label>
                          {vehicleTypeOptions && (
                            <Select
                              name="vehicle_type"
                              options={vehicleTypeOptions}
                              onChange={(e) => onVehicleTypeChange(e.value)}
                            />
                          )}
                        </div>
                      </div>
                      {/* Middle */}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="fuelType">Order Type</label>
                          <Select
                            options={orderTypeOptions}
                            id="orderType"
                            name="order_type"
                            onChange={(e) => onOrderTypeChange(e.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="fuelType">Fuel Type</label>
                          <Select
                            options={fuelTypeOptions}
                            onChange={(e) => onFuelTypeChange(e.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="">OEM</label>
                          <input
                            type="text"
                            name="text"
                            className="form-control"
                            id="oem"
                            placeholder="Vehicle OEM"
                            disabled
                            value={dealerDetail && dealerDetail["oem"]}
                          />
                        </div>
                       
                        <div className="form-group">
                          <label>Registration Date</label>
                          <div
                            className="input-group date"
                            id="reservationdate"
                            data-target-input="nearest"
                          >                          
                            <input
                              type="date"
                              className="form-control datetimepicker-input"
                              data-target="#registrationdate"
                              placeholder="Registration Date"
                              id="registrationdate"
                              name="reservationdate"
                            />
                            {/*<div
                              className="input-group-append"
                              data-target="#registrationdate"
                              data-toggle="datetimepicker"
                            >
                              <div className="input-group-text">
                                <i className="fa fa-calendar" />
                              </div>
                          </div>*/}
                          </div>
                        </div>

                        <div className="form-group">
                          <label htmlFor="fuelType">Plate Color</label>
                          <Select
                            options={plateColorOption}
                            onChange={(e) => onPlateColorChange(e.value)}
                          />
                          {/* <span
                              className="error invalid-feedback"
                              style={{ display: "inline" }}
                            >
                              This field is required.
                            </span> */}
                        </div>

                        <div className="form-group">
                          <label>Manufacturing Date</label>
                          <div
                            className="input-group date"
                            id="manufacturingdate"
                            data-target-input="nearest"
                          >
                            <input
                              type="date"
                              className="form-control datetimepicker-input"
                              data-target="#manufacturingdate"
                              placeholder="Manufacturing Date"
                              name="manufacturingdate"
                              id="manDate"
                            />
                            {/*<div
                              className="input-group-append"
                              data-target="#manufacturingdate"
                              data-toggle="datetimepicker"
                            >
                              <div className="input-group-text">
                                <i className="fa fa-calendar" />
                              </div>
                            </div>*/}
                          </div>
                        </div>
                      </div>
                      {/* Right */}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Owner Name</label>
                          <input
                            type="text"
                            name="owner_name"
                            className="form-control"
                            id="ownerName"
                            placeholder="Vehicle Owner Name"
                            // disabled={disableOrder}
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="">Front Board Dimension</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="far fa-minus-square"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              name="text"
                              className="form-control"
                              id="frontBoardSize"
                              disabled
                              value={
                                vehicleTypeDetail &&
                                vehicleTypeDetail.frontboard_size
                              }
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                <strong>mm</strong>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="">Back Board Dimension</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="far fa-minus-square"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              name="text"
                              className="form-control"
                              id="backBoardSize"
                              disabled
                              value={
                                vehicleTypeDetail &&
                                vehicleTypeDetail.backboard_size
                              }
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                <strong>mm</strong>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="">Rate</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fas fa-rupee-sign" />
                              </span>
                            </div>
                            <input
                              type="text"
                              name="text"
                              className="form-control"
                              id="rate"
                              disabled
                              value={
                                vehicleTypeDetail &&
                                vehicleTypeDetail.basic_amount
                              }
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                <strong>+ GST</strong>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <button
                      type="submit"
                      className="btn btn-primary float-right"
                      disabled={!canMakeOrder}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
      {showOrderConfimrationModal && (
        <OrderConfirmation
          show={showOrderConfimrationModal}
          // payload={this.state.payload}
          onClose={handleClose}
          onConfirm={placeOrder}
        />
      )}
    </>
  );
}
