import React, { useState } from "react";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import ContentHeader from "../components/content-header/ContentHeader";
import { Container } from "react-bootstrap";

const TransactionDetails = () => {
  // Dummy transaction data
  const [transactionData, setTransactionData] = useState([
    { id: 1, date: "2024-04-01", dealerCode: "ABC123",amount:"500" , status: "successful" },
    { id: 2, date: "2024-04-02", dealerCode: "XYZ456",amount:"600" , status: "failed" },
    { id: 3, date: "2024-04-03", dealerCode: "DEF789",amount:"800" , status: "aborted" },
    { id: 4, date: "2024-04-04", dealerCode: "GHI012",amount:"400" , status: "successful" },
  ]);

  // Columns for the Material Table
  const columns = [
    { title: "#", field: "id" },
    { title: "Date", field: "date", filtering: true },
    { title: "Dealer Code", field: "dealerCode", filtering: true },
    { title: "Amount", field: "amount", filtering: true },
    { title: "Status", field: "status", filtering: true },
  ];

  const [isLoading] = useState(false); // You can set isLoading to true if needed

  return (
    <>
      <ContentHeader title="Transaction Details" />
      <section className="content">
        <Container fluid>
          <div className="card">
            <MaterialTable
              columns={columns}
              data={transactionData}
              title=""
              isLoading={isLoading}
              options={{
                exportAllData: true,
                filtering: true, // Enable filtering
                exportMenu: [
                  {
                    label: "Export PDF",
                    exportFunc: (cols, datas) =>
                      ExportPdf(cols, datas, "Transaction Details"),
                  },
                  {
                    label: "Export CSV",
                    exportFunc: (cols, datas) =>
                      ExportCsv(cols, datas, "Transaction Details"),
                  },
                ],
              }}
            />
          </div>
        </Container>
      </section>
    </>
  );
};

export default TransactionDetails;
