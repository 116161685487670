import ContentHeader from "../../components/content-header/ContentHeader";
import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { addOEM } from "../../services/data-service/oem-service";
import {
  getAllStates,
  getDistrictByState,
} from "../../services/data-service/master-service";
import { getRandom3 } from "../../services/common/utils";
import { toast } from "react-toastify";


const AddOEM = () => {
  const navigate = useNavigate();
  const [stateOption, setStateOption] = useState(null)
  const [selectedState, setSelectedState] = useState(null)
  const [districtOption, setDistrictOption] = useState(null)
  const [selectedDistrict, setSelectedDistrict] = useState(null)

  const onStateChanged = (stateId) => {
    setSelectedState(stateId)
    getDistrictByState(stateId)
      .then((response) => {
        let tempOption = [];
        response.data.map((item) =>
          tempOption.push({
            value: item.id,
            label: item.district,
          })
        );
        setDistrictOption(tempOption);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const onDistrictChanged = (districtId) => {
    setSelectedDistrict(districtId);
  }

  useEffect(() => {
    getAllStates()
      .then((response) => {
        let tempOption = [];
        response.data.map((item) =>
          tempOption.push({
            value: item.id,
            label: item.state,
          })
        );
        setStateOption(tempOption)
      })
      .catch((error) => {
        console.log(error);
      });
  }, [])

  function handleSubmit(event) {
    event.preventDefault();
    const data = event.target.elements;
    const payload = {
      oem_name: data.oemName.value,
      oem_mobile: data.contactNumber.value,
      oem_email: data.emailID.value,
      oem_prefix: data.prefix.value,
      oem_state: selectedState,
      oem_district: selectedDistrict,
      oem_city: data.city.value,
      oem_pincode: data.pincode.value,
      oem_address: data.address.value,
    };
    console.log(payload);
    addOEM(payload).then((res) => {
      toast.success("OEM Added Sucessfully!");
      // window.location.href = "/#/oem";
      navigate("/oems");
    });
  }
    return (
      <div>
        <ContentHeader title="Add OEM" />
        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Add OEM</h3>
                
              </div>
              <div className="card-body">
              <section className="content">
                <form
                    id="quickForm"
                    noValidate="novalidate"
                    onSubmit={handleSubmit}
                >
                    <div className="container-fluid">
                    {/* Main row */}
                    <div className="row">
                        <div className="col-md-12">
                        {/* jquery validation */}
                        <div className="card card-primary">
                            {/* /.card-header */}
                            <div className="card-body">
                            {/* form start */}
                            {/* <form id="quickForm" noValidate="novalidate"> */}
                            <div className="row">
                                {/* Left */}
                                <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="oemName">OEM Name</label>
                                    <input
                                    type="text"
                                    name="text"
                                    className="form-control"
                                    id="oemName"
                                    placeholder="OEM Name"
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="Prefix">Prefix</label>
                                    <input
                                    type="text"
                                    name="text"
                                    className="form-control"
                                    id="prefix"
                                    placeholder="Prefix for user login"
                                    />
                                </div>  

                                <div className="form-group">
                                    <label htmlFor="contactNumber">
                                    Contact Number
                                    </label>
                                    <input
                                    type="text"
                                    name="text"
                                    className="form-control"
                                    id="contactNumber"
                                    placeholder="Contact No"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="emailID">Email Address</label>
                                    <input
                                    type="text"
                                    name="text"
                                    className="form-control"
                                    id="emailID"
                                    placeholder="Email Id"
                                    />
                                </div>
                                {/* <div className="form-group">
                                    <label htmlFor="gst">GST</label>
                                    <input
                                    type="text"
                                    name="text"
                                    className="form-control"
                                    id="gst"
                                    placeholder="GST No"
                                    />
                                </div> */}
                                </div>
                                {/* Middle */}
                                <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="Payment Type">Payment Type</label>
                                    <Select
                                    className="select2bs4"
                                    style={{ width: "100%" }}
                                    options={[
                                        { label: "Prepaid", value: 1},
                                        { label: "Postpaid", value: 2}
                                    ]
                                    }
                                    ></Select>
                                </div>                         
                                <div className="form-group">
                                    <label htmlFor="emsState">State</label>
                                    {stateOption && (
                                    <Select
                                        className="select2bs4"
                                        style={{ width: "100%" }}
                                        options={stateOption}
                                        onChange={(e) => onStateChanged(e.value)}
                                    ></Select>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="district">District</label>
                                    {districtOption ? (<Select
                                    className="select2bs4"
                                    style={{ width: "100%" }}
                                    options={districtOption}
                                    onChange={(e) =>
                                        onDistrictChanged(e.value)
                                    }
                                    ></Select>) : (
                                    <input
                                    type="text"
                                    name="text"
                                    className="form-control"
                                    disabled
                                    />
                                    ) }
                                </div>
                                </div>
                                {/* Right */}
                                <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="city">City</label>
                                    <input
                                    type="text"
                                    name="text"
                                    className="form-control"
                                    id="city"
                                    placeholder="city"
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="pincode">PinCode</label>
                                    <input
                                    type="text"
                                    name="text"
                                    className="form-control"
                                    id="pincode"
                                    placeholder="Pincode"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="address">Address</label>
                                    <textarea
                                    className="form-control"
                                    id="address"
                                    rows="4"
                                    />
                                </div>
                                </div>
                            </div>
                            {/* </form> */}
                            </div>
                            {/* /.card-body */}
                            <div className="card-footer">
                            <button
                                type="submit"
                                className="btn btn-primary float-right"
                            >
                                Submit
                            </button>
                            </div>
                        </div>
                        {/* /.card */}
                        </div>
                    </div>
                    {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </form>
             </section>
                
              </div>
              {/* <div className="card-footer">Footer</div> */}
            </div>
          </div>
        </section>
      </div>
    );
  };
  
  export default AddOEM;