import axios from "axios";
import authHeader from "../common/auth-header";
import { API_URL } from "../common/globalvars";

export async function getDashboardOrders() {
  return await axios.get(`${API_URL}/b2b/dashboard/orders/yearwise`, {
    headers: authHeader(),
  });
}

export async function getDashboardOrdersStat() {
  return await axios.get(`${API_URL}/b2b/dashboard/orders/stastics`, {
    headers: authHeader(),
  });
}
