import ContentHeader from "../../components/content-header/ContentHeader";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { useAuth, zeroPad } from "../../services/common/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getofflinepaymentdetails } from "../../services/data-service/order-service";
import {
  approveDelaerOfflinePayment,
  rejectDelaerOfflinePayment,
  downloadOfflinePayment,
} from "../../services/data-service/dealer-service";
import { toast } from "react-toastify";
import { MdOutlineSystemUpdateAlt } from "react-icons/md";
import { LuMonitor } from "react-icons/lu";


const OfflinePaymentApproval = () => {    
    const navigate = useNavigate();
    const { userid, user, role, refid } = useAuth();
    const [orderData, setOrderData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
  
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    
  
    useEffect(() => {
      getofflinepaymentdetails().then((response) => {       
        if (response.status === 200) {
          setOrderData(response.data);
          setIsLoading(false);
        }
      });
    }, []);
     {/* <p  className="m-0 p-0"><MdOutlineSystemUpdateAlt/></p>
       <p className="m-0 p-0 text-left" >Download</p> */}
      
    async function downloadPayementDetails(userid){
      downloadOfflinePayment(userid).then((response)=>{
        console.log(response, 'ak')
        console.log(new Blob([response]));
        const url = window.URL.createObjectURL(new Blob([response]));       
        const tempLink = document.createElement("a");
        tempLink.href = url;
        tempLink.setAttribute("download", `offlinepayment.png`);
        document.body.appendChild(tempLink);        
        tempLink.click();
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(url);

      })
      .catch((err)=>{
        console.log(err)
      })
    }
  
    const columns = [
      { title: "Id", field: "id" },
      {
        title: "Reference",
        render: (rowData)=>{
          return(
            <div>
              <Link>
                <div>
                  <LuMonitor style={{fontSize:"25px"}}/>
                  <span >View</span>
                 </div>
              </Link>
              <Link  onClick={()=>downloadPayementDetails(rowData.id)}>
                <div>    
                  <MdOutlineSystemUpdateAlt style={{fontSize:"25px"}}/>
                  <span >Download</span>
                 </div>                           
              </Link>
            </div>
          )
        }
      },
      { title: "Dealer", field: "dealer" },
      { title: "Payment Type", field: "payment_type" },
      { title: "Payment Amount", field: "amount" },
      { title: "Payment Date", field: "payment_date" },
      { title: "Approved", field: "is_approved" },
      // { title: "Approved By", field: "approved_by" },
      // { title: "Approved On", field: "approved_by" },
      // { title: "reference", field: "reference" },
    ];
  
    async function approvePayment(id) {
      approveDelaerOfflinePayment(id).then((response) => {
        toast.success("Payment Approved");
      });
    }
    async function rejectPayment(id) {
      rejectDelaerOfflinePayment(id).then((response) => {
        toast.info("Payment Rejected");
      });
    }

  return (
    <div>
      <ContentHeader title="Offline Payment Approval" />
        <section className="content">
          <div className="container-fluid">
            <div className="card">
              {role === "admin" ? (
                <MaterialTable
                  columns={columns}
                  data={orderData}                  
                  isLoading={isLoading}
                  title=""
                  options={{
                    headerStyle: { fontWeight: "bold" },
                    pageSizeOptions: [5, 20, 50, 100],
                    exportAllData: true,
                    exportMenu: [
                      {
                        label: "Export CSV",
                        exportFunc: (cols, datas) =>
                          ExportCsv(cols, datas, "oems"),
                      },
                    ],
                  }}
                  actions={[
                    (rowData) => ({
                      icon: () => <i className="fas fa-check-circle" />,
                      tooltip: "Approve Payment",
                      onClick: (event, rowData) => {
                        approvePayment(rowData.id);
                      },
                      disabled: rowData.is_approved !== null,
                    }),
                    (rowData) => ({
                      icon: () => <i className="fas fa-times-circle" />,
                      tooltip: "Reject Payment",
                      onClick: (event, rowData) => {
                        rejectPayment(rowData.id);
                      },
                      disabled: rowData.is_approved !== null,
                    }),
                  ]}
                />
              ):(
                <MaterialTable
                  columns={columns}
                  data={orderData}
                  isLoading={isLoading}
                  title=""
                  options={{
                    headerStyle: { fontWeight: "bold" },
                    pageSizeOptions: [5, 20, 50, 100],
                    exportAllData: true,
                    exportMenu: [
                      {
                        label: "Export CSV",
                        exportFunc: (cols, datas) =>
                          ExportCsv(cols, datas, "oems"),
                      },
                    ],
                  }}
                />
              )}
            </div>
          </div>
        </section>
    </div>
  );
};

export default OfflinePaymentApproval;
