import React from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

const ChangePassword = ({ show, loginId, onClose, onSave, onNewPasswordChange, onOldPasswordChange }) => {          
    return (
        <div
          className={`modal fade ${show ? "show" : "hide"}`}
          style={{ display: show ? "block" : "none" }}
          id="modal-lg"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Change Password</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" onClick={() => onClose()}>
                    ×
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="col-sm-12">
                  {/* select */}
                  <div className="form-group">
                    <label>Login Id</label>
                    <input
                      type="text"
                      name="text"
                      className="form-control"
                      id="loginId"
                      disabled
                      defaultValue={loginId}
                    />
                  </div>
                  <div className="form-group">
                    <label>Old Password</label>
                    <input
                      type="password"
                      name="OldPassword"
                      className="form-control"
                      id="oldPassword"
                      onChange={(e) => onOldPasswordChange(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>New Password</label>
                    <input
                      type="password"
                      name="NewPassword"
                      className="form-control"
                      id="newPassword"
                      onChange={(e) => onNewPasswordChange(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                  onClick={() => onClose()}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => onSave()}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      );
};

export default ChangePassword;
