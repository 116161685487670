import axios from "axios";
import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import {
  updateOrderDetails,
  uploadOrderImagesApi,
} from "../../services/data-service/order-service";
import http from "../../services/common/http-common";
import { toast } from "react-toastify";
import { API_URL } from "../../services/common/globalvars";
import {
  getAllPlateClass,
  getAllVehicleClass,
} from "../../services/data-service/master-service";

export default function VehicleDetailsModal(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [vehicleClassOptions, setVehicleClassOptions] = useState(null);
  const [vehicleClass, setVehicleClass] = useState(null);
  const [plateColorOption, setPlateColorOption] = useState(null);
  const [plateColor, setPlateColor] = useState(null);

  function onVehicleClassChange(vclass) {
    setVehicleClass(vclass);
  }

  function onPlateColorChange(color) {
    setPlateColor(color);
  }

  function updateVehicleDetails(event) {
    event.preventDefault();
    setIsLoading(true);
    const data = event.target.elements;

    const payload = {
      reg_no: data.reg_no.value,
      chassis_no: data.chassis_no.value,
      engine_no: data.engine_no.value,
      vehicle_model: data.model.value,
    };

    if (vehicleClass !== null) payload["vehicle_class"] = vehicleClass;
    if (plateColor !== null) payload["plate_color"] = plateColor;
    console.log(payload);
    updateOrderDetails(props.orderId, { vehicle: payload }).then((response) => {
      if (response.status === 200) {
        toast.success("Vehicle Details Updated!");

        props.refreshVehicleDetails();
        setIsLoading(false);
        props.onClose();
      }
    });
  }

  useEffect(() => {
    getAllVehicleClass().then((response) => {
      let optionData = [];
      response.data.map((item) =>
        optionData.push({ value: item.id, label: item.vehicle_class })
      );
      setVehicleClassOptions(optionData);
    });
    getAllPlateClass().then((response) => {
      let optionData = [];
      response.data.map((item) =>
        optionData.push({ value: item.id, label: item.color })
      );
      setPlateColorOption(optionData);
    });
  }, []);

  return (
    <div
      className={`modal fade ${props.show ? "show" : "hide"}`}
      style={{ display: props.show ? "block" : "none" }}
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          {isLoading && (
            <div className="overlay">
              <i className="fas fa-2x fa-sync fa-spin"></i>
            </div>
          )}
          <div className="modal-header">
            <h4 className="modal-title">
              <i className="fas fa-edit"></i> Edit Vehicle Details
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => props.onClose()}>
                ×
              </span>
            </button>
          </div>
          <form id="updateVehicleForm" onSubmit={updateVehicleDetails}>
            <div className="modal-body">
              <div className="col-sm-12">                
                <div className="form-group">
                  <label htmlFor="">Registration No</label>
                  <input
                    type="text"
                    name="reg_no"
                    className="form-control"
                    id="regNo"
                    placeholder="Vehicle Registration No."
                    defaultValue={props.vehicleDetail.reg_no}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="">Chassis No</label>
                  <input
                    type="text"
                    name="chassis_no"
                    className="form-control"
                    id="chasisNo"
                    placeholder="Chassis No."
                    defaultValue={props.vehicleDetail.chassis_no}
                    // onChange={this.onchasisNoChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="">Engine No</label>
                  <input
                    type="text"
                    name="engine_no"
                    className="form-control"
                    id="engineNo"
                    placeholder="Engine No."
                    defaultValue={props.vehicleDetail.engine_no}
                    // onChange={this.onEngineNoChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="">Model</label>
                  <input
                    type="text"
                    name="model"
                    className="form-control"
                    id="model"
                    placeholder="Engine No."
                    defaultValue={props.vehicleDetail.vehicle_model}
                    // onChange={this.onEngineNoChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="">Vehicle Class</label>
                  {vehicleClassOptions && (
                    <Select
                      options={vehicleClassOptions}
                      name="vehicle_class"
                      onChange={(e) => onVehicleClassChange(e.value)}
                      defaultValue={
                        vehicleClassOptions[
                          vehicleClassOptions.findIndex(
                            (obj) =>
                              obj.label === props.vehicleDetail.vehicle_class
                          )
                        ]
                      }
                    />
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="fuelType">Plate Color</label>
                  {plateColorOption && (
                    <Select
                      options={plateColorOption}
                      onChange={(e) => onPlateColorChange(e.value)}
                      defaultValue={
                        plateColorOption[
                          plateColorOption.findIndex(
                            (obj) =>
                              obj.label === props.vehicleDetail.plate_color
                          )
                        ]
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={() => props.onClose()}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
