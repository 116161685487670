import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addOrUpdateFixactionDate } from "../../services/data-service/order-service";

export default function FixactionDateModal(props) {
  const [startDate, setStartDate] = useState("");
  function onSubmit() {
    console.log(startDate);
    let formattedDate = `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`;
    const payload = {
      fixationDate: formattedDate,
    };

    addOrUpdateFixactionDate(props.orderId, payload).then((response) => {
      props.refreshHSRP()
      props.onClose();
    });
  }

  return (
    <div
      className={`modal fade ${props.show ? "show" : "hide"}`}
      style={{ display: props.show ? "block" : "none" }}
      id="modal-lg"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Laser Code</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => props.onClose()}>
                ×
              </span>
            </button>
          </div>
          <div className="modal-body">
            <div className="col-sm-12">
              <div className="form-group">
                <label>Fixation Date</label>

                <div className="form-group">
                  <div className="input-group date" data-target-input="nearest">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className="form-control"
                      dateFormat="dd-MM-yyyy"
                      showMonthDropdown
                      maxDate={new Date()}
                    />
                    <div
                      className="input-group-append"
                      data-target="#fixationdate"
                      data-toggle="datetimepicker"
                    >
                      <div className="input-group-text">
                        <i className="fa fa-calendar" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-between">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              onClick={() => props.onClose()}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => onSubmit()}
            >
              Save changes
            </button>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}
