import ContentHeader from "../../components/content-header/ContentHeader";
import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  getAllOEM,
  getOEMDetails,
} from "../../services/data-service/oem-service";
import {
  getAllStates,
  getDistrictByState,
} from "../../services/data-service/master-service";
import { addNewDealer } from "../../services/data-service/dealer-service";
import { getAuth, getRandom3 } from "../../services/common/utils";
import { toast } from "react-toastify";

import {
  Container,
  Row,
  Col,
  Form as BootstrapForm,
  Button,
} from "react-bootstrap";
import { FaRegPlusSquare } from "react-icons/fa";

const cityOptions = [
  { value: "new", label: "new" },
  { value: "inprogress", label: "inprogress" },
  { value: "delivered", label: "delivered" },
  { value: "aborted", label: "aborted" },
];

const AddDealer = () => {
  const navigate = useNavigate();
  const [addDealerDetails, setAddDealerDetails] = useState({
    registredDealerId: "",
    states: [],
    selectedState: "",
    districts: [],
    selectedDistrict: "",
    selectedOEM: "",
    loginid: "",
    oemListOptions: [],
    paymentOption: 1,
    paymentType: "",
    is_prepaid: true,
    creditLimit: 0,
  });

  console.log(addDealerDetails.selectedOEM, "ak");

  useEffect(() => {
    getAllOEM().then((response) => {
      let optionData = [];
      response.data.map((item) =>
        optionData.push({ value: item.id, label: item.oem_name })
      );
      // console.log(optionData)
      setAddDealerDetails({ ...addDealerDetails, oemListOptions: optionData });
      // if (localStorage.getItem("role") === "oem") {
      //   this.setState({ selectedOEM: localStorage.getItem("userid") });
      // }
    });

    getAllStates()
      .then((response) => {
        let tempOption = [];
        const { states } = addDealerDetails;
        response.data.map(
          (item) =>
            states.push({
              value: item.id,
              label: item.state,
            })
          // tempOption.push({
          //   value: item.id,
          //   label: item.state,
          // })
        );
        // setAddDealerDetails({ ...addDealerDetails, states: tempOption });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function onOEMChanged(oemId) {
    addDealerDetails.selectedOEM = oemId;
    // setAddDealerDetails({...addDealerDetails, selectedOEM: oemId });
    getOEMDetails(oemId).then((response) => {
      setAddDealerDetails({
        ...addDealerDetails,
        is_prepaid: response.data.is_prepaid,
      });
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    const data = event.target.elements;
    console.log("Form data:", data);
    const payload = {
      dealer_name: data.dealerName.value,
      dealer_mobile: data.contactNumber.value,
      dealer_email: data.emailID.value,
      oem: addDealerDetails.selectedOEM,
      dealer_gstno: data.gstNO.value,
      dealer_pincode: data.pincode.value,
      dealer_state: addDealerDetails.selectedState,
      dealer_district: addDealerDetails.selectedDistrict,
      dealer_city: data.city.value,
      dealer_address: data.address.value,
      is_prepaid: addDealerDetails.is_prepaid,
      //addresses: addresses.map((address) => address.value), 
    };
    console.log(payload);
    addNewDealer(payload)
      .then((response) => {
        setAddDealerDetails({
          ...addDealerDetails,
          registredDealerId: response.data.dealerId,
        });
        toast.success("Dealer Added Successfully!");
        navigate("/dealers");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function onStateChanged(stateId) {
    addDealerDetails.selectedState = stateId;
    // setAddDealerDetails({...addDealerDetails, selectedState: stateId });
    getDistrictByState(stateId)
      .then((response) => {
        let tempOption = [];
        response.data.map((item) =>
          tempOption.push({
            value: item.id,
            label: item.district,
          })
        );
        setAddDealerDetails({ ...addDealerDetails, districts: tempOption });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function onCreditLimitChange(creditLimit) {
    console.log(creditLimit);
    setAddDealerDetails({ ...addDealerDetails, creditLimit: creditLimit });
  }

  function onDistrictChanged(districtId) {
    setAddDealerDetails({ ...addDealerDetails, selectedDistrict: districtId });
  }

  const { userid, user, role } = getAuth();

  const [addresses, setAddresses] = useState([{ id: 0, value: "" }]);
  const [nextId, setNextId] = useState(1);

  const handleAddressChange = (id, value) => {
    const newAddresses = addresses.map((address) =>
      address.id === id ? { ...address, value: value } : {...address}
    );
    setAddresses(newAddresses);
  };

  const addAddressField = () => {
    setAddresses([...addresses, { id: nextId, value: "" }]);
    setNextId(nextId + 1);
  };

  return (
    <div>
      <ContentHeader title="Add Dealer" />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">New Dealer</h3>
            </div>
            <div className="card-body">
              <section className="content">
                <form
                  id="quickForm"
                  noValidate="novalidate"
                  onSubmit={handleSubmit}
                >
                  <Container fluid>
                  
                    <div className="card card-primary">
                      {/* /.card-header */}
                      <div className="card-body">
                        {/* form start */}
                        {/* <form id="quickForm" noValidate="novalidate"> */}
                        <Row>
                        
                          <Col md={4}>
                            <BootstrapForm.Group>
                              <label htmlFor="dealerName">Dealer Name</label>
                              <input
                                type="text"
                                name="text"
                                className="form-control"
                                id="dealerName"
                                placeholder="Dealer Name"
                              />
                            </BootstrapForm.Group>
                          </Col>
                          <Col md={4}>
                            <BootstrapForm.Group>
                              {role === "admin" && (
                                <>
                                  <label htmlFor="embosingCenter">
                                    OEM (
                                    <Link to="/new-oem">
                                      <i className="fas fa-plus"></i> Create New
                                    </Link>
                                    )
                                  </label>
                                  {addDealerDetails.oemListOptions.length >
                                  0 ? (
                                    <Select
                                      className="select2bs4"
                                      style={{ width: "100%" }}
                                      options={
                                        addDealerDetails.oemListOptions.length >
                                        0
                                          ? addDealerDetails.oemListOptions
                                          : []
                                      }
                                      onChange={(e) => onOEMChanged(e.value)}
                                    ></Select>
                                  ) : (
                                    <div className="overlay">
                                      <i className="fas fa-2x fa-sync fa-spin"></i>
                                    </div>
                                  )}
                                </>
                              )}
                            </BootstrapForm.Group>
                          </Col>
                          <Col md={4}>
                            <BootstrapForm.Group>
                              <label htmlFor="city">City</label>
                              <input
                                type="text"
                                name="text"
                                className="form-control"
                                id="city"
                                placeholder="City"
                              />
                            </BootstrapForm.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <BootstrapForm.Group>
                              <label htmlFor="contactNumber">
                                Contact Number
                              </label>
                              <input
                                type="text"
                                name="text"
                                className="form-control"
                                id="contactNumber"
                                placeholder="Contact No"
                              />
                            </BootstrapForm.Group>
                          </Col>

                        

                          <Col md={4}>
                            <BootstrapForm.Group>
                              <label htmlFor="paymentOption">
                                Payment Type
                              </label>
                              <input
                                type="text"
                                name="text"
                                className="form-control"
                                id="paymentType"
                                value={
                                  addDealerDetails.is_prepaid
                                    ? "Prepaid"
                                    : "Postpaid"
                                }
                                disabled
                              />
                            </BootstrapForm.Group>
                          </Col>

                          <Col md={4}>
                            <BootstrapForm.Group>
                              <label htmlFor="pincode">PinCode</label>
                              <input
                                type="text"
                                name="text"
                                className="form-control"
                                id="pincode"
                                placeholder="Pincode"
                              />
                            </BootstrapForm.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={8}>
                            <Row>
                              <Col md={6}>
                                <BootstrapForm.Group>
                                  <label htmlFor="emailID">Email Address</label>
                                  <input
                                    type="text"
                                    name="text"
                                    className="form-control"
                                    id="emailID"
                                    placeholder="Email ID"
                                  />
                                </BootstrapForm.Group>
                              </Col>
                              <Col md={6}>
                                <BootstrapForm.Group>
                                  <label htmlFor="inState">State</label>
                                  {addDealerDetails.states.length > 0 ? (
                                    <Select
                                      className="select2bs4"
                                      style={{ width: "100%" }}
                                      options={
                                        addDealerDetails.states.length > 0
                                          ? addDealerDetails.states
                                          : []
                                      }
                                      onChange={(e) => onStateChanged(e.value)}
                                    ></Select>
                                  ) : (
                                    <div className="overlay">
                                      <i className="fas fa-2x fa-sync fa-spin"></i>
                                    </div>
                                  )}
                                </BootstrapForm.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <BootstrapForm.Group>
                                  <label htmlFor="gstNO">GST Number</label>
                                  <input
                                    type="text"
                                    name="text"
                                    className="form-control"
                                    id="gstNO"
                                    placeholder="GST"
                                  />
                                </BootstrapForm.Group>
                              </Col>
                              <Col md={6}>
                                <BootstrapForm.Group>
                                  <label htmlFor="district">District</label>
                                  <Select
                                    className="select2bs4"
                                    style={{ width: "100%" }}
                                    options={addDealerDetails.districts}
                                    onChange={(e) => onDistrictChanged(e.value)}
                                  ></Select>
                                </BootstrapForm.Group>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={4}>
                            <BootstrapForm.Group>
                              <BootstrapForm.Label>Address</BootstrapForm.Label>
                              <textarea
                                className="form-control"
                                id="address"
                                rows="4"
                              />
                              </BootstrapForm.Group>
                          </Col>
                          {/* {addresses.map((address, index) => (
                            <Col md={4} key={address.id}>
                              <BootstrapForm.Group>
                                <BootstrapForm.Label>
                                  Address:{" "}
                                  {index === 0 ? (
                                    <Button
                                      type="button"
                                      variant="primary"
                                      onClick={addAddressField}
                                    >
                                      <FaRegPlusSquare
                                        size={16}
                                        style={{ marginRight: "5px" }}
                                      />{" "}
                                      Address
                                    </Button>
                                  ) : null}
                                </BootstrapForm.Label>
                                <BootstrapForm.Control
                                  as="textarea"
                                  rows={4}
                                  placeholder="Enter address"
                                  value={address.value}
                                  onChange={(e) =>
                                    handleAddressChange(
                                      address.id,
                                      e.target.value
                                    )
                                  }
                                />
                              </BootstrapForm.Group>
                            </Col>
                          ))} */}
                        </Row>

                        {/* {this.state.paymentOption === "1" && (
                                <div className="form-group">
                                  <label htmlFor="loginID">Credit Limit</label>
                                  <input
                                    type="text"
                                    name="text"
                                    className="form-control"
                                    id="creditLimit"
                                    onChange={(e) =>
                                      this.onCreditLimitChange(e.target.value)
                                    }
                                  />
                                </div>
                              )} */}
                        {/* </div> */}
                        {/* Right */}
                        {/* <div className="col-md-4"> */}
                        {/* <div className="form-group">
                                <label htmlFor="embosingCenter">
                                  Embossing Center (<Link to="/add-embosing-station"> <i className="fas fa-plus"></i> Create New </Link>)
                                </label>
                                <input
                                  type="text"
                                  name="text"
                                  className="form-control"
                                  id="embosingCenter"
                                  placeholder="Embosing Center"
                                />
                              </div> */}

                        {/* </div> */}

                        {/* </form> */}
                      </div>
                      {/* /.card-body */}
                      <div className="card-footer">
                        <button
                          type="submit"
                          className="btn btn-primary float-right"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                    {/* /.card */}
                   
                    
                  </Container>

                  {/* /.container-fluid */}
                </form>
              </section>
            </div>
            {/* <div className="card-footer">Footer</div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default AddDealer;
