import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
// import "./page-a4.css";
import InvoiceTitle from "./InvoiceTitle";
import BillTo from "./BillTo";
import InvoiceNo from "./InvoiceNo";
import InvoiceItemsTable from "./InvoiceItemsTable";
import logo from "./logo_full.png";

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 10,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
    // flexDirection: "row",
    // backgroundColor: "#FFF",
  },
  section: {
    margin: 20,
    padding: 20,
    flexGrow: 1,
  },
  logo: {
    width: 180,
    height: 30,
    marginLeft: "auto",
    marginRight: "auto",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});
// const invoice = {
//   invoice_no: "KL/2021_2022/INV/1",
//   dealer: "Reep TamilNadu",
//   email: "reep@tn.com",
//   phone: "+1 (872) 588-3809",
//   address: "922 Campus Road, Drytown, Wisconsin, 1986",
//   invoice_date: "2019-09-12",
//   items: [
//     {
//       orderid: 1,
//       regno: "TN54TH1234",
//       basic: 500,
//       sgst: 9,
//       cgst: 9,
//       sgst_amount: 10,
//       cgst_amount: 10,
//       total: 520,
//     },
//     {
//       orderid: 2,
//       regno: "TN54TH1234",
//       basic: 500,
//       sgst: 9,
//       cgst: 9,
//       sgst_amount: 10,
//       cgst_amount: 10,
//       total: 520,
//     },
//   ],
//   invoice_bill: {
//     basic: 500,
//     sgst: 9,
//     cgst: 9,
//     sgst_amount: 10,
//     cgst_amount: 10,
//     netamount: 520.4,
//     rountoff: 520.00
//   }
// };

// export default invoice;

const DownloadInvoice = ({ invoice }) => (
  // console.log(invoice);
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Image style={styles.logo} src={logo} />
        <InvoiceTitle title="Invoice" />
        <InvoiceNo invoice={invoice} />
        <BillTo invoice={invoice} />
        <InvoiceItemsTable invoice={invoice} />
      </View>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  </Document>
);

export default DownloadInvoice;
