import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#000',
        backgroundColor: '#ffc107',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    description: {
        width: '60%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    amount: {
        width: '15%'
    },
    orderidheader:{
        width: '20%',
    	borderRightColor: '#000',
        borderRightWidth: 1,
    },
    regnoheader:{
        width: '20%',
      borderRightColor: '#000',
        borderRightWidth: 1,
    },
    basicamtheader:{
        width: '15%',
      borderRightColor: '#000',
        borderRightWidth: 1,
    },
    sgstheader:{
        width: '15%',
      borderRightColor: '#000',
        borderRightWidth: 1,
    },
    cgstheader:{
        width: '15%',
      borderRightColor: '#000',
        borderRightWidth: 1,
    },
    netamtheader:{
        width: '15%',
      // borderRightColor: '#000',
        // borderRightWidth: 1,
    },
  });

  const InvoiceTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.orderidheader}>OrderId</Text>
        <Text style={styles.regnoheader}>RegNo</Text>
        <Text style={styles.basicamtheader}>Basic</Text>
        <Text style={styles.sgstheader}>CGST</Text>
        <Text style={styles.cgstheader}>SGST</Text>        
        <Text style={styles.netamtheader}>Amount</Text>
    </View>
  );
  
  export default InvoiceTableHeader