import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { PostDealerOfflinePayment } from "../../services/data-service/dealer-service";
import axios from "axios";
import { API_URL } from "../../services/common/globalvars";
import { toast } from "react-toastify";

const SubmitOfflinePayment = (props) => {
  // const { show, onClose, onSave, onTypeChange, onMessageChange } = props;
  const {
    show,
    onClose
  } = props;

  const [image, setImage] = useState(null);
  const [imageType, setImageType] = useState("offline_payment");
  
  const options = [
    { value: "cash", label: "Cash" },
    { value: "cheque", label: "Cheque" },
    { value: "online", label: "Online" },
  ];

  const payOptions = [
    { value: "neft", label: "NEFT" },
    { value: "rtgs", label: "RTGS" },
    { value: "upi", label: "UPI" },
  ]

  const [paymentType, setPaymentType] = React.useState("");
  const [bankPayType, setBankPayType] = useState('')
  
 
  const formPayload = new FormData()

  function onPaymentTypeChange(type) {
    setPaymentType(type);
  }

  function onPayOPtions(banktype) {
    setBankPayType(banktype);
  }

  const selectFile = (event) => {
    event.preventDefault();
    setImage(event.target.files[0]);
  };

  async function submitOfflinePaymentForm(event) {
    event.preventDefault();
    const data = event.target.elements;

    const config = {
        Authorization: "Bearer " + localStorage.getItem("token"),
        'content-type': 'multipart/form-data' 
    }
    
    formPayload.append("payment_type", paymentType)
    formPayload.append("payment_file", image)
    formPayload.append("amount", data.payment_amount.value)
    formPayload.append("payment_date", data.payment_date.value)
    
    if(paymentType === "cash") {
      formPayload.append("branch_name", data.branch_name.value)
    }else if(paymentType === "cheque") {
      formPayload.append("cheque_no", data.cheque_no.value)
      formPayload.append("check_issued_bank", data.cheque_issues_bank.value)
      formPayload.append("check_accepted_bank", data.cheque_accepted_bank.value)
    } else if(paymentType === "online") {
      formPayload.append("online_transaction_type", bankPayType)
      // formPayload.append("online_transaction_type", data.online_transaction_type.value)
      formPayload.append("online_transaction_id", data.online_transaction_id.value)
    }

    console.log(formPayload, config)
    axios.post(`${API_URL}/payment/transaction/offline`, formPayload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        'content-type': 'multipart/form-data' 
      }
  }).then((response) => {
      console.log(response)
      toast.success("Added Payment Details!!");
       onClose()
    });
  }

  return (
    <>
      <div
        className={`modal fade ${show ? "show" : "hide"}`}
        style={{ display: show ? "block" : "none" }}
        id="modal-lg"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <form
              id="submitOffilinePayment"
              onSubmit={submitOfflinePaymentForm}
            >
              <div className="modal-header">
                <h4 className="modal-title">Payment Type</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" onClick={() => onClose()}>
                    ×
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-6">
                    {/* select */}
                    <div className="form-group">
                      <label>Payment Type</label>
                      <Select
                        options={options}
                        onChange={(e) => onPaymentTypeChange(e.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label>Payment File</label>
                      <input
                        accept="image/*"
                        onChange={(e) => selectFile(e)}
                        type="file"
                      />
                    </div>
                    <div className="form-group">
                      <label>Payment Amount</label>
                      <input
                        className="form-control"
                        type="number"
                        name="payment_amount"
                        placeholder="Payment Amount"
                        style={{ fontSize: "14px" }}
                      />
                    </div>
                    <div className="form-group">
                      <label>Payment Date</label>
                      <input
                        className="form-control"
                        type="date"
                        placeholder="Payment Date"
                        name="payment_date"
                        style={{ fontSize: "14px" }}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    {paymentType === "cash" && (
                      <>
                        <div className="form-group">
                          <label>Branch Name</label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Branch Name"
                            name="branch_name"
                            style={{ fontSize: "14px" }}
                          />
                        </div>
                      </>
                    )}
                    {paymentType === "cheque" && (
                      <>
                        <div className="form-group">
                          <label>Cheque No</label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Cheque No"
                            name="cheque_no"
                            style={{ fontSize: "14px" }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Check Issued Bank</label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Check Issued Bank"
                            name="cheque_issues_bank"
                            style={{ fontSize: "14px" }}
                          />
                        </div>
                        <div className="form-group">
                          <label>Check Accepted Bank</label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Check Accepted Bank"
                            name="cheque_accepted_bank"
                            style={{ fontSize: "14px" }}
                          />
                        </div>
                      </>
                    )}
                    {paymentType === "online" && (
                      <>
                        <div className="form-group">
                          <label>Online Transaction Type</label>
                          <Select
                            options={payOptions}
                            onChange={(e) => onPayOPtions(e.value)}
                          />
                          {/* <input
                            className="form-control"
                            type="text"
                            placeholder="Online Transaction Type"
                            name="online_transaction_type"
                            style={{ fontSize: "14px" }}
                          /> */}
                        </div>
                        <div className="form-group">
                          <label>Online Transaction Id</label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Online Transaction Id"
                            name="online_transaction_id"
                            style={{ fontSize: "14px" }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                  onClick={() => onClose()}
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
    </>
  );
};

export default SubmitOfflinePayment;
