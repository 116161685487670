import React, { Component } from "react";

export default class HSRPConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placeOrderDisable: true,
    };
    this.onChangeAgree = this.onChangeAgree.bind(this);
  }
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "dist/js/formValidation.js";
    script.async = true;
    document.body.appendChild(script);
  }
  onChangeAgree(e) {
    console.log(e.target.checked);
    this.setState({ placeOrderDisable: !e.target.checked });
  }
  render() {
    const { show, onClose, payload, onFixationDateChanged, onHsrpSubmit } =
      this.props;
    console.log(payload)
    return (
      <div
        className={`modal fade ${show ? "show" : "hide"}`}
        style={{ display: show ? "block" : "none" }}
        id="modal-lg"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-lg">
          <form
            id="quickForm"
            noValidate="novalidate"
            onSubmit={onHsrpSubmit}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">HSRP Entry</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" onClick={() => onClose()}>
                    ×
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Registration Number</label>
                        <input
                          type="text"
                          name="text"
                          className="form-control"
                          disabled
                          value={payload.regnNo}
                        />
                      </div>
                      <div className="form-group">
                        <label>Front Laser Code</label>
                        <input
                          type="text"
                          name="text"
                          className="form-control"
                          disabled
                          value={payload.frontLaserCode}
                        />
                      </div>
                      <div className="form-group">
                        <label>Rear Laser Code</label>
                        <input
                          type="text"
                          name="text"
                          className="form-control"
                          disabled
                          value={payload.rearLaserCode}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Chasis No</label>
                        <input
                          type="text"
                          name="text"
                          className="form-control"
                          disabled
                          value={payload.chasisNo}
                        />
                      </div>
                      <div className="form-group">
                        <label>Flag</label>
                        <input
                          type="text"
                          name="text"
                          className="form-control"
                          disabled
                          value={payload.flag}
                        />
                      </div>
                      <div className="form-group">
                        <label>Fixation Date</label>
                        <div className="form-group">
                          <div
                            className="input-group date"
                            data-target-input="nearest"
                          >
                            <input
                              type="text"
                              className="form-control datetimepicker-input"
                              data-target="#fixationdate"
                              placeholder="Registration Date"
                              id="fixationdate"
                              value={payload.fixationDate}
                            />
                            <div
                              className="input-group-append"
                              data-target="#fixationdate"
                              data-toggle="datetimepicker"
                            >
                              <div className="input-group-text">
                                <i className="fa fa-calendar" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="form-group mb-0 float-right">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      name="terms"
                      className="custom-control-input"
                      id="exampleCheck1"
                      onChange={(e) => this.onChangeAgree(e)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="exampleCheck1"
                    >
                      I agree to the <a href="#">terms of service</a>.
                    </label>
                  </div>
                </div>
                {/* /.card-body */}
                {/* </div> */}
              </div>
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                  onClick={() => onClose()}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={this.state.placeOrderDisable}
                >
                  Submit To HSRP
                </button>
              </div>
            </div>
          </form>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
    );
  }
}
