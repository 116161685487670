import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { getAllOrderStatus } from "../../services/data-service/order-service";

export default class OrderUpdatePayment extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     statusOption: [],
  //     selectedIndex: -1,
  //     status: props.status,
  //   };
  // }
  // async componentDidMount() {
  //   let optionData = [];
  //   await getAllOrderStatus().then((response) => {
  //     this.setState({ statusData: response.data });
  //     response.data.map((item) =>
  //       optionData.push({ value: item.status, label: item.status })
  //     );
  //     this.setState({ statusOption: optionData });
  //   });
  // }
  render() {
    const { show, onClose, id, status, onSave, onStatusChange, statusOption } = this.props;
    // const { statusOption } = this.state;
    const options = [
      { value: "0", label: "-" },
      { value: "0", label: "UnPaid" },
      { value: "1", label: "Paid" },
    ];
    // let index = -1;
    // const filteredOptions = statusOption.find(function (item, i) {
    //   console.log(item.value, status)
    //   if (item.value === status) {
    //     index = i;
    //     return i;
    //   }
    // });

    return (
      <div
        className={`modal fade ${show ? "show" : "hide"}`}
        style={{ display: show ? "block" : "none" }}
        id="modal-lg"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Update Status</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={() => onClose()}>
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-sm-6">
                {/* select */}
                <div className="form-group">
                  <label>Status</label>

                    <Select
                      // defaultValue={statusOption[index]}
                      options={options}
                      onChange={(e) => onStatusChange(e.value)}
                    />

                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => onSave()}
              >
                Save changes
              </button>
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
    );
  }
}
