import ContentHeader from "../../components/content-header/ContentHeader";

import React,{useState, useEffect} from "react";
import { Link } from "react-router-dom";
import VehicleTypeModal from "../../components/modals/master/VehicleTypeModal";
import {
  getAllVehicleTypes,
  addNewVehicleTypes,
  updateVehicleTypes,
  deleteVehicleTypes,
} from "../../services/data-service/master-service";
import { toast } from "react-toastify";
import MaterialTable from "@material-table/core";
import { getAuth } from "../../services/common/utils";



const VechicleType = () => {
    const[isLoading, setIsLoading] = useState(true)
    const[showVehicleTypeModal, setShowVehicleTypeModal] = useState(false)
    const[vehicleType, setVehicleType] = useState(
    {
      types: [],
      vehicleType: "",
      showVehicleTypeModal: false,
      currentValue: false,
      currentId: "",
      currentType: "",
    })

    const 
    { 
      types, 
      currentType, 
      currentValue 
    } = vehicleType


    function onShowVehicleModal() {
      setShowVehicleTypeModal(true)
    }
  
    function onVehicleTypeChange(type) {
      setVehicleType({...vehicleType, vehicleType: type });
    }
  
   const handleEdit = (id, type) => {
    setVehicleType({
        ...vehicleType,
        currentId: id,
        currentType: type,
        currentValue: true,        
      });
      setShowVehicleTypeModal(true)
    };
  
    async function handleSave() {
      console.log(vehicleType.vehicleType);
      const payload = {
        type: vehicleType.vehicleType,
      };
  
      await addNewVehicleTypes(payload).then((res) => {
        toast.success("New vehicle type added sucessfully!");
      });
      handleClose();
      await init();
    }
  
    async function handleDelete(id) {
      await deleteVehicleTypes(id).then((res) => {
        toast.info("Vehicle type deleted!");
      });
      init();
    }
  
    async function handleUpdate() {
      const payload = {
        type: vehicleType.vehicleType,
      };
  
      await updateVehicleTypes(vehicleType.currentId, payload).then((res) => {
        toast.success("New vehicle type added sucessfully!");
      });
      handleClose();
      init();
    }
  
    function handleClose() {
      setVehicleType({
        ...vehicleType,
        showVehicleTypeModal: false,
        currentValue: false,
        currentType: "",
      });
    }
  
    async function init() {
      await getAllVehicleTypes().then((response) => {
        setVehicleType({...vehicleType, types: response.data });
  
      });
    }
    
    useEffect(()=>{
      init()
      .then((res)=>{
        console.log(res.data)
      })
      .catch((err)=>{
        console.log(err)
      })
      setIsLoading(false)
    },[])

    const columns = [
      {title:'No',field:'id'},
      {title:'Type',field:'vehicle_type'},
      {title:'OEM',field:'oem_id'},
      {title:'FrontBoardSize',field:'frontboard_size'},
      {title:'BackBoardSize',field:'backboard_size'},
      {
        title:'Action',
        render:(rowData)=>{
          return(
            <div className="btn-group btn-group-sm">
              <button
                className="btn btn-info"
                onClick={() =>
                  handleEdit(rowData.id, rowData.vehicle_type)
                }
              >
                <i className="fas fa-edit"></i>
              </button>
              {/* <button
                className="btn btn-danger"
                onClick={() => handleDelete(rowData.id)}
              >
                <i className="fas fa-trash"></i>
              </button> */}
            </div>
          )
        }
      },

    ]

    const { role } = getAuth()
   
    return (
      <div>
        <ContentHeader title="Vehicle Types" />
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Vehicle Types</h3>
                    <div className="card-tools">
                      { role === "admin" && (
                        <button
                          className="btn btn-outline-primary"
                          onClick={onShowVehicleModal}
                        >
                          <i className="fas fa-user-plus"></i> Add
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <MaterialTable
                    data={vehicleType.types}
                      columns={columns}
                      title=""
                      isLoading={isLoading}
                      />                       
                  </div>
                </div>
              </div>
            </div>
          </div>
          </section>
          {showVehicleTypeModal && (
          <VehicleTypeModal
            onTypeChange={onVehicleTypeChange}
            currentType={currentValue ? currentType : "sdf"}
            show={showVehicleTypeModal}
            currentValue={currentValue}
            onClose={handleClose}
            onSave={handleSave}
            onUpdate={handleUpdate}
          />
        )}
      </div>
    );
  };
  
  export default VechicleType;
  