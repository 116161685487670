import http from "../common/http-common";

export async function getAllOEM() {
  return await http.get("/b2b/oem");
}

export async function addOEM(data) {
  return await http.post("/b2b/oem/create", data);
}

export async function getOEMDetails(id) {
  return await http.get(`/b2b/oem/${id}`);
}

export async function getOemVehicleTypes(id){
  return await http.get(`/b2b/oem/${id}/vehicle-type`);
}

export async function getOemVehicleTypeDetail(id, type_id){
  return await http.get(`/b2b/oem/${id}/vehicle-type/${type_id}`);
}

export async function getOemRate(id){
  return await http.get(`/b2b/oem/${id}/rate`);
}