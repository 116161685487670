import ContentHeader from "../../components/content-header/ContentHeader";

import React, { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { useAuth, zeroPad } from "../../services/common/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getAllOrdersByTimeRange } from "../../services/data-service/order-service";



const OrdersReport = () => {
    const navigate = useNavigate();
    const { userid, user, role, refid } = useAuth();
    const [orderData, setOrderData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
  
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
  
    const columns = [
      { title: "#", render: (rowData) => rowData.tableData.id + 1, width: 50 },
      {
        title: "Order Id",
        field: "orderId",
  
        render: (rowData) => {
          return (
            <Link
              to="/order-detail"
              state={{ orderId: rowData.orderId }}
              className="nav-link"
            >
              {"#" + zeroPad(rowData.orderId, 5)}
            </Link>
          );
        },
      },
      { title: "Registration No", field: "regNo" },
      { title: "Chassis No", field: "chassisNo" },
      { title: "Engine No", field: "engineNo" },
      { title: "Vehicle Class", field: "vehicle_class" },
      { title: "Vehicle Type", field: "vehicle_type" },
      { title: "Front Laser Code", field: "f_laser_code" },
      { title: "Rear Laser Code", field: "r_laser_code" },
      { title: "Order Type", field: "orderType" },
      { title: "Registration Date", field: "reg_date" },
      { title: "Vehicle Model", field: "vehicle_model" },
      { title: "Dealer Name", field: "dealerName" },
      { title: "Oem Name", field: "oemName" },
      {
        title: "HSRP",
        field: "vahan_status",
        render: (rowData) => {
          if (rowData.vahan_status == "Fitted")
            return <span className="badge bg-success">{rowData.vahan_status}</span>;
          else {
            return <span className="badge bg-danger">{rowData.vahan_status}</span>;
          }
        },
      },
      { title: "Order Status", field: "orderStatus" },
      { title: "Created", field: "created_at" },
    ];
  
    function onSearch() {
      setOrderData([]);
      setIsLoading(true);
  
      let fDate = `${fromDate.getFullYear()}-${
        fromDate.getMonth() + 1
      }-${fromDate.getDate()}`;
      let tDate = `${toDate.getFullYear()}-${
        toDate.getMonth() + 1
      }-${toDate.getDate()}`;
  
      getAllOrdersByTimeRange(fDate, tDate).then((response) => {
        if (response.status === 200) {
          setOrderData(response.data);
        }
      });
  
      setIsLoading(false);
    }




  return (
    <div>
      <ContentHeader title="Orders - Report" />
        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>From Date &nbsp; </label>
                          <DatePicker
                            selected={fromDate}
                            onChange={(date) => setFromDate(date)}
                            className="form-control"
                            dateFormat="dd-MM-yyyy"
                            showMonthDropdown
                            maxDate={new Date()}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>To Date &nbsp;</label>
                          <DatePicker
                            selected={toDate}
                            onChange={(date) => setToDate(date)}
                            className="form-control"
                            dateFormat="dd-MM-yyyy"
                            showMonthDropdown
                            maxDate={new Date()}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <button
                            type="button"
                            className="btn btn-lg btn-default"
                            onClick={() => onSearch()}
                          >
                            <i className="fa fa-search" /> Get Orders
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <MaterialTable
                columns={columns}
                data={orderData}
                isLoading={isLoading}
                title=""
                options={{
                  headerStyle: { fontWeight: "bold" },
                  pageSizeOptions: [5, 20, 50, 100],
                  exportAllData: true,
                  exportMenu: [
                    // {
                    //   label: "Export PDF",
                    //   exportFunc: (cols, datas) =>
                    //     ExportPdf(cols, datas, "oems"),
                    // },
                    {
                      label: "Export CSV",
                      exportFunc: (cols, datas) =>
                        ExportCsv(cols, datas, "oems"),
                    },
                  ],
                }}
              />
            </div>
          </div>
        </section>
    </div>
  );
};

export default OrdersReport;
