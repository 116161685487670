import ContentHeader from "../../components/content-header/ContentHeader";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FuelTypeModal from "../../components/modals/master/FuelTypeModal";
import {
  getAllFuelTypes,
  addNewFuelTypes,
  updateFuelTypes,
  deleteFuelTypes,
} from "../../services/data-service/master-service";
import { getAuth } from "../../services/common/utils";
import { toast } from "react-toastify";
import MaterialTable from "@material-table/core";

const FuelType = () => {
  const [fuelType, setFuelType] = useState({
    types: [],
    FuelType: "",
    currentValue: false,
    currentId: "",
    currentType: "",
  });
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [showFuelTypeModal, setShowFuelTypeModal] = useState(false);

  function onShowFuelModal() {
    setShowFuelTypeModal(true)
  }

  function onFuelTypeChange(type) {
    setFuelType({ ...fuelType, FuelType: type });
  }

  const handleEdit = (id, type) => {
    setFuelType({
      ...fuelType,
      currentId: id,
      currentType: type,
      currentValue: true,
    });
    setShowFuelTypeModal(true)
  };

  function handleClose() {
    setFuelType({
      ...fuelType,      
      currentValue: false,
      currentType: "",
    });
    setShowFuelTypeModal(false)
  }

  async function handleSave() {
    const payload = {
      type: fuelType.FuelType,
    };

    await addNewFuelTypes(payload).then((res) => {
      console.log(res);
    });
    handleClose();
    toast.success("New Fuel type added sucessfully!");
    await init();
  }

  async function handleDelete(id) {
    await deleteFuelTypes(id).then((res) => {
      toast.info("Fuel type deleted!");
    });
    init();
  }

  async function handleUpdate() {
    const payload = {
      type: fuelType.FuelType,
    };

    await updateFuelTypes(fuelType.currentId, payload).then((res) => {
      toast.success("Fuel type udpated sucessfully!");
      handleClose();
      init();
    });
  }

  async function init() {
    await getAllFuelTypes().then((response) => {
      console.log(response.data);
      setFuelType({ ...fuelType, types: response.data });
    });
  }

  //   useEffect(()=>{
  //    const fetch = async()=>{
  //       await init();
  //       const script = document.createElement("script");
  //       script.src = "dist/js/content.js";
  //       script.async = true;
  //       document.body.appendChild(script);
  //    };
  //    fetch()
  //  },[])

  useEffect(() => {
    init()
      .then((response) => {
        console.log(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
    setIsPageLoading(false);
  }, []);

  const columns = [
    { title: "No", field: "id" },
    { title: "Type", field: "fuel_type" },
    {
      title: "Action",
      render: (rowData) => {
        return (
          <div className="btn-group btn-group-sm">
            <button
              className="btn btn-info"
              onClick={() => handleEdit(rowData.id, rowData.fuel_type)}
            >
              <i className="fas fa-edit"></i>
            </button>
            {/* <button
                        className="btn btn-danger"
                        onClick={() => handleDelete(rowData.id)}
                        >
                        <i className="fas fa-trash"></i>
                    </button> */}
          </div>
        );
      },
    },
  ];

  const { role } = getAuth();
  const { types, currentType, currentValue } = fuelType;

  return (
    <div>
      <ContentHeader title="Fuel Types" />
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Fuel Types</h3>
                  <div className="card-tools">
                    {role === "admin" && (
                      <button
                        className="btn btn-outline-primary"
                        onClick={onShowFuelModal}
                      >
                        <i className="fas fa-user-plus"></i> Add
                      </button>
                    )}
                  </div>
                </div>
                <div className="card-body p-0">
                  <MaterialTable
                    title=""
                    data={fuelType.types}
                    columns={columns}
                    isLoading={isPageLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {showFuelTypeModal && (
        <FuelTypeModal
          onTypeChange={onFuelTypeChange}
          currentType={fuelType.currentValue ? fuelType.currentType : "sdf"}
          show={showFuelTypeModal}
          currentValue={currentValue}
          onClose={handleClose}
          onSave={handleSave}
          onUpdate={handleUpdate}
        />
      )}
    </div>
  );
};

export default FuelType;
