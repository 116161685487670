import http from "../common/http-common";

// All Orders API
export async function getAllOrders(type) {
  return await http.get(`/b2b/orders/${type}`);
}

export async function getAllOrdersByTimeRange(fDate, tDate) {
  return await http.get(`/b2b/orders?fdate=${fDate}&tdate=${tDate}`);
}

export async function getOrdersByStatus(status) {
  return await http.get(`/b2b/orders?order_status=${status}`);
}

export async function updateOrderDetails(id, data) {
  console.log(data)
  return await http.put(`/b2b/orders/${id}`, data);
}

export async function addNewOrder(data) {  
  return await http.post("/b2b/orders", data);
  // return { "status": "success"}, 200
}

export function updateOrderPayment(id, data) {
  return http.put(`/b2b/orders/${id}/payment`, data);
}

// Order Details API
export async function getOrderDetailsApi(id) {
  return await http.get(`/b2b/orders/${id}`);
}

export async function getVehicleDetailsByOrder(id) {
  return await http.get(`/b2b/orders/${id}/vehicle`);
}


export async function getVehicleHSRPDetailsByOrder(id) {
  return await http.get(`/b2b/orders/${id}/hsrp`);
}

export async function getOrderPaymentDetailsByOrder(id) {
  return await http.get(`/b2b/orders/${id}/payment`);
}

export async function getOrderTimelineByOrder(id) {
  return await http.get(`/b2b/orders/${id}/timeline`);
}

export function setOrderStatusAPI(id, data) {
  return http.put(`/b2b/orders/${id}/status`, data);
}



export function postOrderTimelineAPI(id, data) {
  return http.post(`/b2b/orders/${id}/timeline`, data);
}

export function updateOrderEmbossingAPI(id, data) {
  return http.put(`/b2b/orders/${id}/embossing-station`, data);
}



// Order Status Options API
export async function getAllOrderStatus(){
  return await http.get("/b2b/master/order-status");
}

export function addNewOrderStatus(data){
  return http.post("/b2b/master/order-status", data);
}

export function updateOrderStatus(id, data){
  return http.put(`/b2b/master/order-status/${id}`, data);
}

export function deleteOrderStatus(id){
  return http.delete(`/b2b/master/order-status/${id}`);
}

// HSRP
export async function hsrpSubmitAPI(id, data){
  return await http.put(`/b2b/orders/${id}/hsrp`, data);
}

export async function addOrUpdateFixactionDate(id, data){
  return await http.put(`/b2b/orders/${id}/hsrp`, data);
}

//Order Delete
export async function deleteOrderDetail(id){
  return await http.delete(`/b2b/orders/${id}`);
}

// Images

export async function getOrderImagesApi(id, type){
  return await http.get(`/b2b/orders/${id}/image/${type}`,  {responseType: 'blob'})
}

export async function uploadOrderImagesApi(id, image){
  console.log(image)
  return await http.post(`/b2b/orders/${id}/image/upload`, image, {'Content-Type': 'multipart/form-data'})
}


//  offline Payment data details
export async function getofflinepaymentdetails() {
  return await http.get("/payment/transaction/offline");
}