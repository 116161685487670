import axios from "axios";
import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { uploadOrderImagesApi } from "../../services/data-service/order-service";
import http from "../../services/common/http-common";
import { toast } from "react-toastify";
import { API_URL } from "../../services/common/globalvars";

export default function UploadImageModal(props) {
  const [image, setImage] = useState(null);
  const [imageType, setImageType] = useState("f_image");
  const [currentFile, setCurrentFile] = useState(undefined);
  const [previewImage, setPreviewImage] = useState(undefined);
  const [uploading, setUploading] = useState(false);

  const selectFile = (event) => {
    event.preventDefault();
    setCurrentFile(event.target.files[0]);
    setPreviewImage(URL.createObjectURL(event.target.files[0]));
    // setProgress(0);
    // setMessage("");
    const formData = new FormData();
    // formData.append('my-image-file', event.target.files[0], event.target.files[0].name);
    formData.append(imageType, event.target.files[0]);
    // formData.append("filename", imageType + "_" + props.orderId + ".jpg");
    setImage(formData);
  };

  function onChangeImageType(e) {
    console.log(e.value);
    setImageType(e.value);
  }

  async function onClickUpload() {
    setUploading(true);
    // console.log(image)
    // uploadOrderImagesApi(props.orderId, image)
    axios
      .post(`${API_URL}/b2b/orders/${props.orderId}/image/upload`, image, {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": image.type,
      })
      .then((res) => {
        console.log("Axios response: ", res);
        toast.success("Image Uploaded!!");
        setUploading(false);
        props.refreshImage()
      })
      .catch((error) => {
        toast.error("Image uploaded failed, Try Again later!!");
        setUploading(false);
      });
  }

  return (
    <div
      className={`modal fade ${props.show ? "show" : "hide"}`}
      style={{ display: props.show ? "block" : "none" }}
      // id="modal-xl"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              <i className="fas fa-images"></i> Upload Image
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => props.onClose()}>
                ×
              </span>
            </button>
          </div>
          <div className="modal-body">
            <div className="col-sm-12">
              {uploading && (
                <div className="overlay">
                  <i className="fas fa-2x fa-sync fa-spin"></i>
                </div>
              )}
              <div className="card-body">
                <div className="form-group row">
                  <label
                    htmlFor="inputPassword3"
                    className="col-sm-2 col-form-label"
                  >
                    Type
                  </label>
                  <div className="col-sm-10">
                    <Select
                      className="select2bs4"
                      style={{ width: "100%" }}
                      options={[
                        { label: "Front Image", value: "f_image" },
                        { label: "Rear Image", value: "r_image" },
                        { label: "Chasis Image", value: "c_image" },
                      ]}
                      onChange={(e) => onChangeImageType(e)}
                    ></Select>
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-2 col-form-label"
                  >
                    Image
                  </label>
                  <div className="col-sm-10">
                    <input
                      accept="image/*"
                      onChange={(e) => selectFile(e)}
                      type="file"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="offset-sm-2 col-sm-10">
                    <div className="form-check">
                      {previewImage && (
                        <div>
                          <Zoom
                            zoomImg={{
                              src: { previewImage },
                            }}
                          >
                            <img
                              className="preview"
                              src={previewImage}
                              alt=""
                              width={"100%"}
                              height={"100%"}
                            />
                          </Zoom>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="inputPassword3"
                    className="col-sm-2 col-form-label"
                  ></label>
                  <div className="col-sm-10 float-right">
                    <button
                      className="btn btn-app float-right bg-primary"
                      onClick={() => onClickUpload()}
                    >
                      <i className="fas fa-upload" /> Upload
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              onClick={() => props.onClose()}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
