import ContentHeader from "../../components/content-header/ContentHeader";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FuelTypeModal from "../../components/modals/master/FuelTypeModal";
import {
  getAllFuelTypes,
  addNewFuelTypes,
  updateFuelTypes,
  deleteFuelTypes,
  getOemRate,
} from "../../services/data-service/master-service";
import { toast } from "react-toastify";
import MaterialTable from "@material-table/core";

 
const OemRate = () => {
  const[isLoading, setIsLoading] = useState(true)
  const [oemRate, setOemRate] = useState({
    oemrate: [],
  })
 
  async function init() {
    await getOemRate().then((response) => {
      setOemRate({...oemRate, oemrate: response.data });
    });
  }
 
  useEffect(()=>{
    init()
    .then((res)=>{
      console.log(res.data)
    })
    .catch((err)=>{
      console.log(err)
    })
    setIsLoading(false)

  },[])

  const {oemrate} = oemRate;

  const columns= [
    {title:'Id',field:'id'},
    {title:'OEM',field:'oem'},
    {title:'Vehicle Type',field:'vehicle_type'},
    {title:'FrontBoard',field:'front_plate_size'},
    {title:'RearBoard',field:'rear_plate_size'},
    {
      title :'BasicAmount',
      field :'id',
      render : (rowData)=>{
        return(
          <span>                                    
            <i className="fas fa-rupee-sign" />{rowData.basic_amount}
          </span>
        )
      },
    },

    {title:'CGST',field:'cgst'},
    {
    title:'CGST Amount',
    field:'id',
    render:(rowData)=>{
      return(
        <span>
          <i className="fas fa-rupee-sign" />{rowData.cgst_amount}
        </span>
      )
    },
    },
    {title:'SGST',field:'sgst'},
    {
    title:'SGST Amount',
    field:'id',
    render:(rowData)=>{
      return(
        <span>
          <i className="fas fa-rupee-sign" />{rowData.sgst_amount}
        </span>
      )
    },
  
   },
    {
      title:'Net Amount',
      field:'id',
      render:(rowData)=>{
        return(
          <span>
            <i className="fas fa-rupee-sign" />{rowData.roundoff_amount}
          </span>
        )
      },
    },
  ]

    return (
      <div>
        <ContentHeader title="Vehicle Type" />
        <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Rate based on OEM</h3>
                      <div className="card-tools">
                        
                      </div>
                    </div>
                    <div className="card-body p-0">
                      <MaterialTable
                        title= ''
                        data={oemRate.oemrate}
                        columns={columns}
                        isLoading= {isLoading}
                       />
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </section>
      </div>
    );
  };
  
  export default OemRate;
  