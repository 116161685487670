import React  from "react";

export default function VehicleClassModal({
    show,
    currentValue,
    currentValueFlag,
    onClose,
    onClassChange,
    onSave,
    onUpdate,}) { 
    return (
      <div
        className={`modal fade ${show ? "show" : "hide"}`}
        style={{ display: show ? "block" : "none" }}
        id="modal-lg"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Vehicle Class</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={() => onClose()}>
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Type</label>
                  <input
                    type="text"
                    name="text"
                    className="form-control"
                    id="vehicleType"
                    onChange={(e) => onClassChange(e.target.value)}
                    placeholder="Vehicle Type"
                    defaultValue={currentValueFlag ? currentValue : ""}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={() => onClose()}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => (currentValueFlag ? onUpdate() : onSave())}
              >
                Save changes
              </button>
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
    );
 
}
