import ContentHeader from "../../components/content-header/ContentHeader";

import React, { Component, useState, useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  getAllStates,
  getDistrictByState,
} from "../../services/data-service/master-service";
import { setEmbossingStationAPI } from "../../services/data-service/embossing-service";
import { getRandom3 } from "../../services/common/utils";
import { toast } from "react-toastify";

const cityOptions = [
  { value: "new", label: "new" },
  { value: "inprogress", label: "inprogress" },
  { value: "delivered", label: "delivered" },
  { value: "aborted", label: "aborted" },
];

const AddEmposer = () => {
    const navigate = useNavigate();
    const[addEmposer, setAddEmposer] = useState({
        registredDealerId: "",
        states: [],
        selectedState: "",
        districts: [],
        selectedDistrict: "",
        loginid: "",
    })
    
    useEffect(()=>{
        getAllStates()
      .then((response) => {
        let tempOption = [];
        response.data.map((item) =>
          tempOption.push({
            value: item.id,
            label: item.state,
          })
        );
        setAddEmposer({...addEmposer, states: tempOption });
      })
      .catch((error) => {
        console.log(error);
      });
    },[])

    function onEmbosingStationNameChange(e) {
        setAddEmposer({...addEmposer,
          loginid: "emc-" + e.target.value.substring(0, 4).toLowerCase() + getRandom3(),
        });
      }

    function handleSubmit(event) {        
        event.preventDefault();
        const data = event.target.elements;
        const payload = {
          emsName: data.embosingStationName.value,
          emsMobile: data.contactNumber.value,
          emsEmail: data.emailID.value,
          emsState: addEmposer.selectedState,
          emsDistict: addEmposer.selectedDistrict,
          emsPlace: data.place.value,
          emsPincode: data.pincode.value,
          emsAddress: data.address.value,
          loginID: addEmposer.loginid,
        };
        console.log(payload);
        setEmbossingStationAPI(payload)
          .then((response) => {           
            toast.success("Embosing Station Added Sucessfully!");
            navigate("/emc")
          })
          .catch((error) => {
            console.log(error);
          });
        // await fetch("http://localhost:5000/embosing-stations", {
        //   method: "POST",
        //   headers: { "Content-Type": "application/json" },
        //   body: JSON.stringify(payload),
        // }).then((res) =>
        //   res.json().then((data) => {
        //     toastMessage("success", "Embosing Station Added Sucessfully!");
        //     window.location.href = "/#/embosing-stations";
        //   })
        // );
      }
    
     function onStateChanged(stateId) {
        setAddEmposer({...addEmposer, selectedState: stateId });
        getDistrictByState(stateId)
          .then((response) => {
            let tempOption = [];
            response.data.map((item) =>
              tempOption.push({
                value: item.id,
                label: item.district,
              })
            );
            setAddEmposer({...addEmposer, districts: tempOption });
          })
          .catch((error) => {
            console.log(error);
          });
    
        // await fetch(`http://localhost:5000/api/states/${stateId}/district`, {
        //   method: "GET",
        //   headers: { "Content-Type": "application/json" },
        // }).then((res) =>
        //   res.json().then((data) => {
        //     let tempOption = [];
        //     data.map((item) =>
        //       tempOption.push({
        //         value: item.id,
        //         label: item.districtName,
        //       })
        //     );
        //     console.log(tempOption);
        //     this.setState({ districts: tempOption });
        //   })
        // );
      }
    
      function onDistrictChanged(districtId) {
        setAddEmposer({...addEmposer, selectedDistrict: districtId });
      }

    return (
      <div>
        <ContentHeader title="Add Embosing Station" />
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Add Embosing Station</h3>
                            
                        </div>
                        <div className="card-body">
                            <section className="content">
                                <form
                                id="quickForm"
                                noValidate="novalidate"
                                onSubmit={handleSubmit}
                                >
                                <div className="container-fluid">
                                    {/* Main row */}
                                    <div className="row">
                                    <div className="col-md-12">
                                        {/* jquery validation */}
                                        <div className="card card-primary">
                                        {/* /.card-header */}
                                        <div className="card-body">
                                            {/* form start */}
                                            {/* <form id="quickForm" noValidate="novalidate"> */}
                                            <div className="row">
                                            {/* Left */}
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                <label htmlFor="embosingStationName">
                                                    Embosing Station Name
                                                </label>
                                                <input
                                                    type="text"
                                                    name="text"
                                                    className="form-control"
                                                    id="embosingStationName"
                                                    placeholder="Embosing Station Name"
                                                    onChange={onEmbosingStationNameChange}
                                                />
                                                </div>

                                                <div className="form-group">
                                                <label htmlFor="contactNumber">
                                                    Contact Number
                                                </label>
                                                <input
                                                    type="text"
                                                    name="text"
                                                    className="form-control"
                                                    id="contactNumber"
                                                    placeholder="Contact No"
                                                />
                                                </div>
                                                <div className="form-group">
                                                <label htmlFor="emailID">Email Address</label>
                                                <input
                                                    type="text"
                                                    name="text"
                                                    className="form-control"
                                                    id="emailID"
                                                    placeholder="Email ID"
                                                />
                                                </div>
                                            </div>
                                            {/* Middle */}
                                            <div className="col-md-4">
                                                
                                                <div className="form-group">
                                                <label htmlFor="emsState">State *</label>
                                                <Select
                                                    className="select2bs4"
                                                    style={{ width: "100%" }}
                                                    options={addEmposer.states.length >0 ?addEmposer.states: [] }
                                                    onChange={(e) => onStateChanged(e.value)}
                                                ></Select>
                                                </div>
                                                <div className="form-group">
                                                <label htmlFor="district">District</label>
                                                <Select
                                                    className="select2bs4"
                                                    style={{ width: "100%" }}
                                                    options={addEmposer.districts}
                                                    onChange={(e) =>
                                                    onDistrictChanged(e.value)
                                                    }
                                                ></Select>
                                                </div>
                                            </div>
                                            {/* Right */}
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                <label htmlFor="city">City</label>
                                                <input
                                                    type="text"
                                                    name="text"
                                                    className="form-control"
                                                    id="place"
                                                    placeholder="City"
                                                />
                                                </div>

                                                <div className="form-group">
                                                <label htmlFor="pincode">PinCode</label>
                                                <input
                                                    type="text"
                                                    name="text"
                                                    className="form-control"
                                                    id="pincode"
                                                    placeholder="Pincode"
                                                />
                                                </div>
                                                <div className="form-group">
                                                <label htmlFor="address">Address</label>
                                                <textarea
                                                    className="form-control"
                                                    id="address"
                                                    rows="3"
                                                />
                                                </div>
                                            </div>
                                            </div>
                                            {/* </form> */}
                                        </div>
                                        {/* /.card-body */}
                                        <div className="card-footer">
                                            <button
                                            type="submit"
                                            className="btn btn-primary float-right"
                                            >
                                            Submit
                                            </button>
                                        </div>
                                        </div>
                                        {/* /.card */}
                                    </div>
                                    </div>
                                    {/* /.row (main row) */}
                                </div>
                                {/* /.container-fluid */}
                                </form>
                            </section>
                        </div>
                    {/* <div className="card-footer">Footer</div> */}
                    </div>
                </div>
            </section>
      </div>
    );
  };
  
  export default AddEmposer;