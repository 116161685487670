import ContentHeader from "../../components/content-header/ContentHeader";
import React, { Component } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getEmbossingStationDetails, getEmbossingStationOrders } from "../../services/data-service/embossing-service";
import { getDistrictName, getStateName } from "../../services/data-service/master-service";

async function fetchOrderData(embossingStationId) {
  return await getEmbossingStationOrders(embossingStationId)
  // return await fetch(
  //   `http://localhost:5000/dealers/${embossingStationId}/orders`
  // );
}

async function fetchEmbossingStationData(embosserId) {
  return await getEmbossingStationDetails(embosserId);
  // return await fetch(`http://localhost:5000/dealers/${dealerId}`, {
  //   method: "GET",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: "Bearer " + localStorage.getItem("token"),
  //   },
  // });
}


const EmbossingStationDetails = () => {
    const location = useLocation();
    const embossingStationId = location.state?.embossingStationId;
    const [orderData, setOrderData] = React.useState(null);
    const [embossingStation, setEmbossingStationData] = React.useState({});
  
    const [stateName, setStateName] = React.useState(null);
    const [districtName, setDistrictName] = React.useState(null);
  
    useEffect(() => {
      fetchEmbossingStationData(embossingStationId)
        .then((response) => {
          setEmbossingStationData(response.data);
          getStateName(response.data.state).then((response) => {
            setStateName(response.data.stateName)
          })
          getDistrictName(response.data.district).then((response) => {
            setDistrictName(response.data.districtName)
          })
        })
        .catch((error) => {
          console.log(error);
        });
      // .then((results) => results.json())
      // .then(
      //   (data) => {
      //       console.log(data)
      //     setEmbossingStationData(data);
      //   },
      //   (error) => {
      //     console.log(error);
      //   }
      // );
    }, [embossingStationId]);
    useEffect(() => {
      if (embossingStationId == null || embossingStationId === "") {
        return;
      }
      // console.log("this.state.embossingStationId")
      // console.log(this.props.location)
      fetchOrderData(embossingStationId)
      .then((response) => {
        setOrderData(response.data);
            const script = document.createElement("script");
            script.src = "dist/js/content.js";
            script.async = true;
            document.body.appendChild(script);
      })
      .catch((error) => {
        console.log(error);
      });
        // .then((results) => results.json())
        // .then(
        //   (data) => {
        //     setOrderData(data);
        //     const script = document.createElement("script");
        //     script.src = "dist/js/content.js";
        //     script.async = true;
        //     document.body.appendChild(script);
        //   },
        //   (error) => {
        //     console.log(error);
        //   }
        // );
    }, [embossingStationId]);

  return (
    <div>
      <ContentHeader title="Embossing Station Details" />
      <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                <div>
                  {/* Profile Image */}
                  <div className="card card-primary card-outline">
                    <div className="card-body box-profile">
                      <div className="text-center">
                        <img
                          className="profile-user-img img-fluid img-circle"
                          src="/img/avatar.png"
                          alt="User profile picture"
                        />
                      </div>
                      <h3 className="profile-username text-center">
                        {embossingStation.name}
                      </h3>
                      <p className="text-muted text-center">
                        {embossingStation.oem}
                      </p>
                      <ul className="list-group list-group-unbordered mb-3">
                        <li className="list-group-item">
                          <b>Login Id</b>
                          <a className="float-right">
                            {embossingStation.loginId}
                          </a>
                        </li>
                        <li className="list-group-item">
                          <b>Mobile</b>
                          <a className="float-right">
                            {embossingStation.mobile}
                          </a>
                        </li>
                        <li className="list-group-item">
                          <b>Email</b>
                          <a className="float-right">
                            {embossingStation.email}
                          </a>
                        </li>
                        <li className="list-group-item">
                          <b>Place</b>
                          <a className="float-right">{embossingStation.place}</a>
                        </li>
                        <li className="list-group-item">
                          <b>district</b>
                          <a className="float-right">
                            {districtName}
                          </a>
                        </li>
                        <li className="list-group-item">
                          <b>State</b>
                          <a className="float-right">
                            {stateName}
                          </a>
                        </li>
                        <li className="list-group-item">
                          <b>Pincode</b>
                          <a className="float-right">
                            {embossingStation.pincode}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-9">
                <div className="card card-primary card-outline">
                  <div className="card-header">
                    <h3 className="card-title">Your Orders</h3>
                    <div className="card-tools"></div>
                  </div>
                  <div className="card-body">
                    <table id="orders-table" className="table table-hover">
                      <thead>
                        <tr>
                          <th>Order Id</th>
                          <th>Reg No</th>
                          <th>Chassis No</th>
                          <th>Engine No</th>
                          <th>Owner Name</th>
                          <th>Owner Mobile</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderData &&
                          orderData.map((item) => (
                            <tr>
                              <td>{item.id}</td>
                              <td>{item.regNo}</td>
                              <td>{item.chassisNo}</td>
                              <td>{item.engineNo}</td>
                              <td>{item.ownerName}</td>
                              <td>{item.ownerPhone}</td>
                              {/* {item.status === "aborted" ? ( */}
                              <td>
                                {/* className={this.statusColor(item.status)}> */}
                                {item.status}
                              </td>
                              {/* ) : (
                              <td className="bg-info">{item.status}</td>
                            )} */}
                              <td className="no-print">
                                <>
                                  <div className="btn-group">
                                    <button
                                      type="button"
                                      className="btn btn-default"
                                    >
                                      Action
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-default dropdown-toggle dropdown-icon"
                                      data-toggle="dropdown"
                                    >
                                      <span className="sr-only">
                                        Toggle Dropdown
                                      </span>
                                    </button>
                                    <div className="dropdown-menu" role="menu">
                                      <Link
                                        to="/order-detail"
                                        state={{ orderId: item.id }}
                                        className="dropdown-item"
                                      >
                                        View
                                      </Link>
                                      <div className="dropdown-divider" />
                                    </div>
                                  </div>

                                  {/* <Link
                                  to="/order-detail"
                                  state={{ data: "1231231232" }}
                                  className="nav-link"
                                >
                                  <i className="nav-icon fas fa-eye"></i>
                                </Link>
                                <span
                                  onClick={() =>
                                    this.handleOrderUpdateStatus(item)
                                  }
                                >
                                  <i className="nav-icon fas fa-pen"></i>
                                </span> */}
                                </>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  {/* /.card-body */}
                </div>
              </div>
            </div>
          </div>
      </section>
    </div>
  );
};

export default EmbossingStationDetails;
