import http from "../common/http-common";

export async function doLogin(data) {
  return await http.post("/b2b/login", data);
}

export async function doAuthorize(data) {
  return await http.get("/b2b/authorize", data);
}

export async function changePwd(data) {
  return await http.post("/b2b/changepwd", data);
}
