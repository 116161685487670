import { useState, useEffect, useCallback } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebarMenu } from "../../store/reducers/ui";
import { addWindowClass, removeWindowClass } from "../../utils/helpers";
import Header from "./header/Header";
import MenuSidebar from "./menu-sidebar/MenuSidebar";
import Footer from "./footer/Footer";
import { doAuthorize } from "../../services/data-service/auth-service";

const Main = () => {
  const dispatch = useDispatch();
  const menuSidebarCollapsed = useSelector(
    (state) => state.ui.menuSidebarCollapsed
  );
  const screenSize = useSelector((state) => state.ui.screenSize);
  // const authentication = useSelector((state) => state.auth.authentication);
  const token = localStorage.getItem("token");
  const [isAppLoaded, setIsAppLoaded] = useState(true);

  const handleToggleMenuSidebar = () => {
    dispatch(toggleSidebarMenu());
  };

  useEffect(() => {
    addWindowClass("text-sm");
    if (token) {
      doAuthorize()
        .then((res) => {
          if (res.status !== 200) {
            localStorage.removeItem("token");
            localStorage.removeItem("authentication");
            window.location = "/";
          }
        })
        .catch((err) => {
          localStorage.removeItem("token");
          localStorage.removeItem("authentication");
        });
    }
    setIsAppLoaded(Boolean(token));
  }, [token]);

  useEffect(() => {
    removeWindowClass("register-page");
    removeWindowClass("login-page");
    removeWindowClass("hold-transition");

    addWindowClass("sidebar-mini");

    // fetchProfile();
    return () => {
      removeWindowClass("sidebar-mini");
    };
  }, []);

  useEffect(() => {
    removeWindowClass("sidebar-closed");
    removeWindowClass("sidebar-collapse");
    removeWindowClass("sidebar-open");
    if (menuSidebarCollapsed && screenSize === "lg") {
      addWindowClass("sidebar-collapse");
    } else if (menuSidebarCollapsed && screenSize === "xs") {
      addWindowClass("sidebar-open");
    } else if (!menuSidebarCollapsed && screenSize !== "lg") {
      addWindowClass("sidebar-closed");
      addWindowClass("sidebar-collapse");
    }
  }, [screenSize, menuSidebarCollapsed]);

  const getAppTemplate = useCallback(() => {
    if (!isAppLoaded) {
      return (
        <div className="preloader flex-column justify-content-center align-items-center">
          <i className="fas fa-truck"></i>
          Loading
          {/* <Image
            className="animation__shake"
            src="/img/logo.png"
            alt="AdminLTELogo"
            height={60}
            width={60}
          /> */}
        </div>
      );
    }
    return (
      <>
        <Header />
        <MenuSidebar />
        <div className="content-wrapper">
          <div className="pt-3" />
          <section className="content">
            <Outlet />
          </section>
        </div>
        <Footer />
        <div
          id="sidebar-overlay"
          role="presentation"
          onClick={handleToggleMenuSidebar}
          onKeyDown={() => {}}
        />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAppLoaded]);

  return <div className="wrapper">{getAppTemplate()}</div>;
};

export default Main;
