import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MenuItem from "../../../components/menu-item/MenuItem";
import { hasPermission } from "../../../utils/permissions";
import { useAuth } from "../../../services/common/utils";



export const MENU = [
  {
    name: "Dashboard",
    icon: "fas fa-tachometer-alt nav-icon",
    path: "/",
  },
  {
    name: "Orders",
    icon: "fas fa-th nav-icon",
    path: "/orders",
    children: [
      {
        name: "New Order",
        icon: "fas fa-plus nav-icon",
        path: "/register-order",
        roles: ["dealer"]
      },
      {
        name: "View Orders",
        icon: "fas fa-th nav-icon",
        path: "/orders",
      },
    ],
  },
  {
    name: "OEMs",
    icon: "fas fa-user-friends nav-icon",
    roles: ["admin"],
    children: [
      {
        name: "New OEM",
        icon: "fas fa-plus nav-icon",
        path: "/new-oem",
        roles: ["admin"],
      },
      {
        name: "View OEM",
        icon: "fas fa-user nav-icon",
        path: "/oems",
        roles: ["admin"]
      },
    ],
  },
  {
    name: "Dealers",
    icon: "fas fa-users nav-icon",
    path: "/dealers",
    roles: ["admin", "oem"],
    children: [
      {
        name: "New Dealer",
        icon: "fas fa-plus nav-icon",
        path: "/new-dealer",
        roles: ["admin", "oem"]
      },
      {
        name: "View Dealer",
        icon: "fas fa-users nav-icon",
        path: "/dealers",
        roles: ["admin", "oem"]
      },
    ],
  },
  {
    name: "Embosing Station",
    icon: "fas fa-users-cog nav-icon",
    roles: ["admin"],
    children: [
      // {
      //   name: "New Emposer",
      //   icon: "fas fa-plus nav-icon",
      //   path: "/new-emposer",
      //   roles: ["admin"],
      // },
      {
        name: "View Emboser",
        icon: "fas fa-user-cog nav-icon",
        path: "/emc",
        roles: ["admin"],
      },
    ],
  },
  {
    name: "Vahan Check",
    icon: "fas fa-clipboard-check nav-icon",
    path: "/vahan-check",
    roles: ["admin"],
  },
  {
    name: "Masters",
    icon: "fas fa-file-invoice nav-icon",    
    roles: ["admin"],
    children: [
      {
        name: "Vehicle Type",
        icon: "fas fa-user nav-icon",
        path: "/master-oem-rate",
      },
      {
        name: " Fuel Type",
        icon: "fas fa-user nav-icon",
        path: "/master-fuel-type",
      },
      // {
      //   name: "Vehicle Type",
      //   icon: "fas fa-users nav-icon",
      //   path: "/master-vehicle-type",
      // },
      {
        name: "Vehicle Class",
        icon: "fas fa-users nav-icon",
        path: "/master-vehicle-class",
      },     
      // {
      //   name: "Vehicle Category",
      //   icon: "fas fa-users nav-icon",
      //   path: "/master-vehicle-category",
      // },
      {
        name: "Order Status",
        icon: "fas fa-users nav-icon",
        path: "/master-order-status",
      },
      {
        name: "Plate Class",
        icon: "fas fa-users nav-icon",
        path: "/master-plate-class",
      },      
    ],
  },
  {
    name: "Support",
    icon: "fas fa-question-circle nav-icon",
    path: "/support",
  },
  // {
  //   name: "Transaction Details",
  //   icon: "fas fa-th nav-icon",
  //   path: "/transaction_details",
  // },
  {
    name: "Reports",
    icon: "fas fa-bahai nav-icon",    
    children: [
      {
        name: "Order Report",
        icon: "far fa-circle nav-icon",
        path: "/orders-report",
        roles: ["admin","dealer"],
      },     
      {
        name: "Offline Transaction",
        icon: "far fa-circle nav-icon",
        path: "/Offline-transaction",
        roles: ["admin","dealer"],
      },     
    ],
  },
];

const MenuSidebar = () => {
  // const authentication = useSelector((state) => state.auth.authentication);
  const authentication = JSON.parse(localStorage.getItem("authentication"));
  const { userid, user, role, refid } = useAuth();
  const menuItemFlat = useSelector((state) => state.ui.menuItemFlat);
  const menuChildIndent = useSelector((state) => state.ui.menuChildIndent);

  return (
    <aside
      className={`main-sidebar sidebar-light-info elevation-4 sidebar-light-primary`}
    >
      <Link to="/" className="brand-link bg-white">
        <img src="/img/logo.png" alt="YS" className="brand-image-xl logo-xs" />
        <img
          src="/img/logo_full.png"
          alt="YS"
          className="brand-image-xs logo-xl"
          style={{ left: "12px" }}
        />
        <span className="brand-text font-weight-light">&nbsp;</span>
      </Link>
      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              className="img-circle elevation-2"
              src="/img/avatar.png"
              alt="User"
            />
          </div>
          <div className="info">
            <Link to="#" className="d-block">
              {user}
            </Link>
          </div>
        </div>

        <nav className="mt-2" style={{ overflowY: "hidden" }}>
          <ul
            className={`nav nav-pills nav-sidebar flex-column${
              menuItemFlat ? " nav-flat" : ""
            }${menuChildIndent ? " nav-child-indent" : ""}`}
            role="menu"
          >
            {MENU.map((menuItem) => {
              if (menuItem.roles && !hasPermission(menuItem.roles)) {
                return null;
              }
              return (
                <MenuItem
                  key={menuItem.name + menuItem.path}
                  menuItem={menuItem}
                />
              );
            })}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default MenuSidebar;
