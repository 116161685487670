import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { deleteOrderDetail } from "../../services/data-service/order-service";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

function DeleteModal(props) {
  const navigate = useNavigate()

  function deleteOrderDetails(){         
      deleteOrderDetail(props.orderId).then((response)=>{
        toast.success(response.data.message)
        props.onClose()
        navigate('/orders')
      })
  }
  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      size="md"
      aria-labelledby="modal-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="modal-lg">Confirmation for Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body className='text-center' >
        Are you sure you want to delete?
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button variant="secondary" onClick={props.onClose}>
          Close
        </Button>
        <Button variant="danger" onClick={deleteOrderDetails}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteModal;
