import http from "../common/http-common";

export function getOrderInvoiceDetails(orderId) {
  return http.get(`/orders/${orderId}/invoice`);
}


export async function getInvoiceDetails(invoiceId) {
  return await http.get(`/payment/invoice/${invoiceId}`);
}
