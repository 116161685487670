import http from "../common/http-common";


// Vehicle Category
export function getAllVehicleCategory(){  
  return http.get("/api/vehicle-category");
}

export async function getCategoryDimension(category) {
  return await http.get(`/api/vehicle-category/${category}/dimension`)
}

export function addNewVehicleCategory(data){
  return http.post("/api/vehicle-category", data);
}

export function updateVehicleCategory(id, data){
  return http.put(`/api/vehicle-category/${id}`, data);
}

export function deleteVehicleCategory(id){
  return http.delete(`/api/vehicle-category/${id}`);
}

// Vehicle Type
export function getAllVehicleTypes(){
  return http.get("/b2b/master/vehicle-type");
}

export function addNewVehicleTypes(data){
  return http.post("/b2b/master/vehicle-type", data);
}

export function updateVehicleTypes(id, data){
  return http.put(`/b2b/master/vehicle-type/${id}`, data);
}

export function deleteVehicleTypes(id){
  return http.delete(`/b2b/master/vehicle-type/${id}`);
}

// Vehicle Class
export function getAllVehicleClass(){
  return http.get("/b2b/master/vehicle-class");
}

export function addNewVehicleClass(data){    
  return http.post("/b2b/master/vehicle-class", data);
}

export function updateVehicleClass(id, data){
  return http.put(`/b2b/master/vehicle-class/${id}`, data);
}

export function deleteVehicleClass(id){
  return http.delete(`/b2b/master/vehicle-class/${id}`);
}


// Fuel Type
export function getAllFuelTypes(){
  return http.get("/b2b/master/fuel-type");
}

export function addNewFuelTypes(data){
  return http.post("/b2b/master/fuel-type", data);
}

export function updateFuelTypes(id, data){
  return http.put(`/b2b/master/fuel-type/${id}`, data);
}

export function deleteFuelTypes(id){
  return http.delete(`/b2b/master/fuel-type/${id}`);
}

// Plate Class
export function getAllPlateClass(){
  return http.get("/b2b/master/plate-color");
}
export function getAllPlateDimension(){
  return http.get("/b2b/master/plate-dimension");
}

export function addNewPlateClass(data){
  return http.post("/b2b/master/plate-color", data);
}

export function updatePlateClass(id, data){
  return http.put(`/b2b/master/plate-color/${id}`, data);
}

export function deletePlateClass(id){
  return http.delete(`/b2b/master/plate-color/${id}`);
}


// State & District
export async function getAllStates(){
  return await http.get("/b2b/master/states")
}

export async function getStateName(id){
  return await http.get(`/api/states/${id}`)
}

export async function getDistrictName(id){
  return await http.get(`/api/district/${id}`)
}

export async function getDistrictByState(stateId){
  return await http.get(`/b2b/master/states/${stateId}/district`)
}

// Rate
export function getRate(){
  return http.get("/api/rate")
}

export function getOemRate() {
  return http.get("/b2b/master/oemrate")
}