import { useCallback } from "react";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toggleSidebarMenu } from "../../../store/reducers/ui";
// import UserDropdown from "./user-dropdown/UserDropdown";
import { MdSupportAgent } from "react-icons/md";
import {
  changePwd,
  doAuthorize,
} from "../../../services/data-service/auth-service";
import { toast } from "react-toastify";
import { NavDropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../services/common/utils";
import ChangePassword from "../../../components/modals/ChangePassword";

const UserDropDown = () => {
  return (
    <>
      <i className="fas fa-user" />
    </>
  );
};

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userid, user, role, refid } = useAuth();
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [oldPasswordChange, setOldPasswordChange] = useState("");
  const [newPasswordChange, setNewPasswordChange] = useState("");

  useEffect(() => {
    const interval = setInterval(async () => {
      doAuthorize()
        .then((response) => {
          if (response.status !== 200) {
            logOut();
          }
        })
        .catch((error) => {
          logOut();
        });
    }, 30000); // Every 1 Min
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      toast.warn("Your are going to logout in few min...");
    }, 900000); //18 Min
    return () => clearInterval(timer);
  }, []);

  const handleToggleMenuSidebar = () => {
    dispatch(toggleSidebarMenu());
  };

  //password
  function setChangePasswordModal() {
    setShowChangePassword(true);
  }

  function onOldPasswordChange(pwd) {
    setOldPasswordChange(pwd);
  }

  function onNewPasswordChange(pwd) {
    setNewPasswordChange(pwd);
  }
  function handleClose() {
    setShowChangePassword(false);
  }

  function onSave() {
    const payload = {
      oldPwd: oldPasswordChange,
      newPwd: newPasswordChange,
      userid: user,
    };
    console.log(payload);
    setShowChangePassword(false);
    changePwd(payload).then(
      (response) => {
        console.log(response);
        toast.success("response.data.message");
      },
      (error) => {
        console.log(error);
        toast.error("error.response.data.message");
      }
    );
  }

  const logOut = async (event) => {
    event.preventDefault();
    console.log("Logging out");

    localStorage.removeItem("token");
    localStorage.removeItem("authentication");
    navigate("/login");
  };

  const getContainerClasses = useCallback(() => {
    let classes = `main-header navbar navbar-expand navbar-light bg-orange`;
    return classes;
  }, []);

  return (
    <nav className={getContainerClasses()}>
      <ul className="navbar-nav">
        <li className="nav-item">
          <button
            onClick={handleToggleMenuSidebar}
            type="button"
            className="nav-link"
          >
            <i className="fas fa-bars" />
          </button>
        </li>
      </ul>
      <ul className="navbar-nav ml-auto">
        <Link className="nav-link" to="/support" role="button">
          <MdSupportAgent style={{ fontSize: "20px" }} />
        </Link>
        <NavDropdown
          title={<UserDropDown />}
          id="basic-nav-dropdown"
          expand="lg"
        >
          <span className="dropdown-item dropdown-header">{user}</span>
          <NavDropdown.Divider />
          {role === "dealer" && (
            <Link to={`/dealer-details/${refid}`} className="dropdown-item">
              <i className="far fa-address-card mr-2" /> Profile
            </Link>
          )}
          <NavDropdown.Divider />
          <button className="dropdown-item" onClick={setChangePasswordModal}>
            <i className="fas fa-envelope mr-2" />
            Change Password
          </button>
          <NavDropdown.Divider />
          <button className="dropdown-item" onClick={(e) => logOut(e)}>
            <i className="fas fa-sign-out-alt mr-2" /> Logout
          </button>
        </NavDropdown>
      </ul>
      {showChangePassword && (
        <ChangePassword
          loginId={user}
          show={showChangePassword}
          onClose={handleClose}
          onSave={onSave}
          onNewPasswordChange={onNewPasswordChange}
          onOldPasswordChange={onOldPasswordChange}
        />
      )}
    </nav>
  );
};

export default Header;
