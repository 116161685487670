import ContentHeader from "../../components/content-header/ContentHeader";
import { toast } from "react-toastify";
import MaterialTable from "@material-table/core";
import { getAuth } from "../../services/common/utils";
import React,{useState, useEffect} from "react";
import { Link } from "react-router-dom";
import VehicleCategoryModal from "../../components/modals/master/VehicleCategoryModal";
import {
  getAllVehicleCategory,
  addNewVehicleCategory,
  updateVehicleCategory,
  deleteVehicleCategory,
} from "../../services/data-service/master-service";


const VechicleCategory = () => {

    const[isLoading, setIsLoading] = useState(true)
    const[showVehicleCategoryModal, setShowVehicleCategoryModal] = useState(false)
    const[vechicleCategory, setVechicleCategory] = useState({
      category: [],
      vehicleCategory: "",
      vehicleCategoryFbDimension: "",
      vehicleCategoryBbDimension: "",
      currentValue: false,
      currentId: "",
      currentCategory: "",
      currentFbDimension: "",
      currentBbDimension: "",
    })        

    const { 
      category, 
      currentCategory, 
      currentFbDimension, 
      currentBbDimension, 
      currentValue 
    } =  vechicleCategory
        
    function onShowVehicleCategoryModal() {
      setShowVehicleCategoryModal(true)
    }
  
    function onVehicleCategoryChange(category) {
      setVechicleCategory({...vechicleCategory, vehicleCategory: category });
    }
  
    function onVehicleCategoryFbDimensionChange(dimension) {
      setVechicleCategory({...vechicleCategory, vehicleCategoryFbDimension: dimension });
    }
  
    function onVehicleCategoryBbDimensionChange(dimension) {
      setVechicleCategory({...vechicleCategory, vehicleCategoryBbDimension: dimension });
    }
  
    const handleEdit = (id, category, fb_dimension, bb_dimension) => {
      setVechicleCategory({
        ...vechicleCategory,
        currentId: id,
        currentCategory: category,
        currentFbDimension: fb_dimension,
        vehicleCategoryFbDimension: fb_dimension,
        currentBbDimension: bb_dimension,
        vehicleCategoryBbDimension: bb_dimension,
        currentValue: true,
      });
      setShowVehicleCategoryModal(true)
    };
  
    async function handleSave() {
      const payload = {
        category: vechicleCategory.vehicleCategory,
        fbDimension: vechicleCategory.vehicleCategoryFbDimension,
        bbDimension: vechicleCategory.vehicleCategoryBbDimension
      };
  
      await addNewVehicleCategory(payload).then((res) => {
        toast.success("New vehicle category added sucessfully!");
      });
      handleClose();
      await init();
    }
  
    async function handleDelete(id) {
      await deleteVehicleCategory(id).then((res) => {
        toast.info("Vehicle category deleted!");
      });
      init();
    }
  
    async function handleUpdate() {
      const payload = {
        category: vechicleCategory.vehicleCategory,
        fbDimension: vechicleCategory.vehicleCategoryFbDimension,
        bbDimension: vechicleCategory.vehicleCategoryBbDimension
      };
  
      await updateVehicleCategory(vechicleCategory.currentId, payload).then((res) => {
        toast.success("Vehicle category updated!");
      });
      handleClose();
      init();
    }
  
    function handleClose() {
      setVechicleCategory({
        ...vechicleCategory,        
        currentValue: false,
        currentCategory: "",
      });
      setShowVehicleCategoryModal(false)
    }
  
    async function init() {
      await getAllVehicleCategory().then((response) => {        
        setVechicleCategory({...vechicleCategory, category: response.data });  
      });
    }
  
    useEffect(()=>{
      init()
      .then((res)=>{
        console.log(res.data)
      })
      .catch((err)=>{
        console.log(err)
      })
      setIsLoading(false)
    },[])
  
    const columns = [
      {title:'No',field:'id'},
      {title:'Category',field:'category'},
      {title:'FrontBoard Dimension',field:'frontboard_dimension'},
      {title:'BackBoard Dimension',field:'backboard_dimension'},
      {
        title:'Action',
        render:(rowData)=>{
          return(
            <div className="btn-group btn-group-sm">
              <button
                className="btn btn-info"
                onClick={() =>
                  handleEdit(rowData.id, rowData.category, rowData.frontboard_dimension, rowData.backboard_dimension)
                }
              >
                <i className="fas fa-edit"></i>
              </button>
              {/* <button
                className="btn btn-danger"
                onClick={() => handleDelete(rowData.id)}
              >
                <i className="fas fa-trash"></i>
              </button> */}
            </div>
          )
        }
    
      },
            
    ]
    
    const { role } = getAuth()
    return (
      <div>
        <ContentHeader title="Blank Page" />
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Vehicle category</h3>
                    <div className="card-tools">
                      {role === "admin" && (
                        <button
                          className="btn btn-outline-primary"
                          onClick={onShowVehicleCategoryModal}
                        >
                          <i className="fas fa-user-plus"></i> Add
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="card-body p-0">
                      <MaterialTable
                      title=''
                      data={vechicleCategory.category}
                      columns={columns} 
                      isLoading={isLoading}                                            
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {showVehicleCategoryModal && (
          <VehicleCategoryModal
            onCategoryChange={onVehicleCategoryChange}
            onFbDimensionChange={onVehicleCategoryFbDimensionChange}
            onBbDimensionChange={onVehicleCategoryBbDimensionChange}
            currentCategory={currentValue ? currentCategory : ""}
            currentFbDimension={currentValue ? currentFbDimension : ""}
            currentBbDimension={currentValue ? currentBbDimension : ""}
            show={showVehicleCategoryModal}
            currentValue={currentValue}
            onClose={handleClose}
            onSave={handleSave}
            onUpdate={handleUpdate}
          />
        )}
      </div>
    );
  };
  
  export default VechicleCategory;
  